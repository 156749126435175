import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const countProviderPastSessionsPOST = (
  Constants,
  { provider_uuid },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/count_provider_past_sessions`,
    {
      body: JSON.stringify({ author_uuid: provider_uuid }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCountProviderPastSessionsPOST = ({ provider_uuid }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/count_provider_past_sessions`,
    {
      body: JSON.stringify({ author_uuid: provider_uuid }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchCountProviderPastSessionsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  provider_uuid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/count_provider_past_sessions`,
    {
      body: JSON.stringify({ author_uuid: provider_uuid }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchCountProviderPastSessions: refetch,
  });
};

export const directMessageATeacherPOST = (
  Constants,
  { content, teacher_id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/direct_message_teacher`,
    {
      body: JSON.stringify({ teacher_id_in: teacher_id, content: content }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useDirectMessageATeacherPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  const fetcher = directMessageATeacherPOST;
  return useMutation(
    args => fetcher(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('message', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('message');
        queryClient.invalidateQueries('messages');
      },
    }
  );
};

export const FetchDirectMessageATeacherPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content,
  teacher_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useDirectMessageATeacherPOST(
    { content, teacher_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchDirectMessageATeacher: refetch,
  });
};

export const getAuthLearnerScheduledSessionsGET = (
  Constants,
  _args,
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_learner_get_scheduled_sessions`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAuthLearnerScheduledSessionsGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_learner_get_scheduled_sessions`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );
};

export const FetchGetAuthLearnerScheduledSessionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_learner_get_scheduled_sessions`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchGetAuthLearnerScheduledSessions: refetch,
  });
};

export const getCityNamesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_city_names`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
  }).then(res => handleResponse(res, handlers));

export const useGetCityNamesGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_city_names`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );
};

export const FetchGetCityNamesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_city_names`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchGetCityNames: refetch });
};

export const getEvidencesForPersonalFeedGET = (
  Constants,
  { id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/posts?order=created_at.asc&user_id=eq.${
      id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetEvidencesForPersonalFeedGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getEvidencesForPersonalFeedGET;
  return useQuery(['posts', args], () => fetcher(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetEvidencesForPersonalFeedGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEvidencesForPersonalFeedGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchGetEvidencesForPersonalFeed: refetch,
  });
};

export const getExtendedLXPPOST = (Constants, { lxp_id }, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_ext_lxp`, {
    body: JSON.stringify({ lxp_id_in: lxp_id }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useGetExtendedLXPPOST = ({ lxp_id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_ext_lxp`,
    {
      body: JSON.stringify({ lxp_id_in: lxp_id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchGetExtendedLXPPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  lxp_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_ext_lxp`,
    {
      body: JSON.stringify({ lxp_id_in: lxp_id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchGetExtendedLXP: refetch });
};

export const getLXPSGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_public_lxps`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLXPSGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getLXPSGET;
  return useQuery(['lxps', args], () => fetcher(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetLXPSGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLXPSGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetLXPS: refetch });
};

export const getLearner$sEnrolledSessionIdsPOST = (
  Constants,
  _args,
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_user_get_enrolled_session_ids`,
    {
      body: JSON.stringify({}),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLearner$sEnrolledSessionIdsPOST = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_user_get_enrolled_session_ids`,
    {
      body: JSON.stringify({}),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchGetLearner$sEnrolledSessionIdsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_user_get_enrolled_session_ids`,
    {
      body: JSON.stringify({}),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchGetLearner$sEnrolledSessionIds: refetch,
  });
};

export const getLearningCenterDetailsGET = (Constants, { id }, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center?id=eq.${
      id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLearningCenterDetailsGET = ({ id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center?id=eq.${
      id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );
};

export const FetchGetLearningCenterDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center?id=eq.${
      id ?? ''
    }`,
    {
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchGetLearningCenterDetails: refetch,
  });
};

export const getLearningCentersByCityGET = (
  Constants,
  { city },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/learning_center?City=eq.${
      city ?? ''
    }&limit=400&select=*`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLearningCentersByCityGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getLearningCentersByCityGET;
  return useQuery(
    ['learning_centers', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetLearningCentersByCityGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  city,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLearningCentersByCityGET(
    { city },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchGetLearningCentersByCity: refetch,
  });
};

export const getLearningExperiencesForLearningCenterPOST = (
  Constants,
  { id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxp_by_center_id`,
    {
      body: JSON.stringify({ center_id_in: id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLearningExperiencesForLearningCenterPOST = ({ id }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxp_by_center_id`,
    {
      body: JSON.stringify({ center_id_in: id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchGetLearningExperiencesForLearningCenterPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_lxp_by_center_id`,
    {
      body: JSON.stringify({ center_id_in: id }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchGetLearningExperiencesForLearningCenter: refetch,
  });
};

export const getLxpsForProviderPOST = (
  Constants,
  { provider_id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_public_lxps_by_provider`,
    {
      body: JSON.stringify({ provider_uuid_in: provider_id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLxpsForProviderPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getLxpsForProviderPOST;
  return useQuery(['lxps', args], () => fetcher(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetLxpsForProviderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  provider_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLxpsForProviderPOST(
    { provider_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetLxpsForProvider: refetch });
};

export const getMessageSummaryGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/auth_user_get_last_teacher_student_messages`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetMessageSummaryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getMessageSummaryGET;
  return useQuery(
    ['messages', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetMessageSummaryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMessageSummaryGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetMessageSummary: refetch });
};

export const getSubjectsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/subjects`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
  }).then(res => handleResponse(res, handlers));

export const useGetSubjectsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getSubjectsGET;
  return useQuery(
    ['subjects', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSubjectsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSubjectsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetSubjects: refetch });
};

export const getTeacherDetailsGET = (
  Constants,
  { teacher_id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?select=*&user_id=eq.${
      teacher_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetTeacherDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  const fetcher = getTeacherDetailsGET;
  return useQuery(
    ['teacher_profile', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['teacher_profiles']),
    }
  );
};

export const FetchGetTeacherDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  teacher_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTeacherDetailsGET(
    { teacher_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetTeacherDetails: refetch });
};

export const getUpcomingClassesForAProviderPOST = (
  Constants,
  { curr_lxp_id, limit, provider_uuid },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_upcoming_lxps_for_provider`,
    {
      body: JSON.stringify({
        provider_uuid: provider_uuid,
        current_lxp_id: curr_lxp_id,
        res_count: limit,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUpcomingClassesForAProviderPOST = ({
  curr_lxp_id,
  limit,
  provider_uuid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_upcoming_lxps_for_provider`,
    {
      body: JSON.stringify({
        provider_uuid: provider_uuid,
        current_lxp_id: curr_lxp_id,
        res_count: limit,
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchGetUpcomingClassesForAProviderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  curr_lxp_id,
  limit,
  provider_uuid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/get_upcoming_lxps_for_provider`,
    {
      body: JSON.stringify({
        provider_uuid: provider_uuid,
        current_lxp_id: curr_lxp_id,
        res_count: limit,
      }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({
    loading,
    data,
    error,
    refetchGetUpcomingClassesForAProvider: refetch,
  });
};

export const getUserProfileByIdGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/profiles?select=*&user_id=eq.${
      user_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserProfileByIdGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  const fetcher = getUserProfileByIdGET;
  return useQuery(
    ['learner_profile', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['learner_profiles']),
    }
  );
};

export const FetchGetUserProfileByIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserProfileByIdGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({ loading, data, error, refetchGetUserProfileById: refetch });
};

export const getTeacherStudentChatsGET = (
  Constants,
  { channel_id },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/messages_teacher_student?channel_id=eq.${
      channel_id ?? ''
    }&order=created_at.desc.nullslast`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetTeacherStudentChatsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getTeacherStudentChatsGET;
  return useQuery(
    ['messages', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetTeacherStudentChatsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  channel_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTeacherStudentChatsGET(
    { channel_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchGetTeacherStudentChats: refetch,
  });
};

export const getTeacher$sProfileByUsernameGET = (
  Constants,
  { username },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/provider_profile?handle=eq.${
      username ?? ''
    }&select=*`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetTeacher$sProfileByUsernameGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  const fetcher = getTeacher$sProfileByUsernameGET;
  return useQuery(
    ['teacher_profile', args],
    () => fetcher(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['teacher_profiles']),
    }
  );
};

export const FetchGetTeacher$sProfileByUsernameGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTeacher$sProfileByUsernameGET(
    { username },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchGetTeacher$sProfileByUsername: refetch,
  });
};

export const updateLearnerProfilePATCH = (
  Constants,
  {
    avatar_url,
    birthday,
    city,
    context,
    first_name,
    gender,
    goal,
    last_name,
    personal_website,
    username,
    uuid,
  },
  handlers = {}
) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/profiles?"user_id"=eq.${
      uuid ?? ''
    }`,
    {
      body: JSON.stringify({
        firstName: first_name,
        lastName: last_name,
        username: username,
        gender: gender,
        birthday: birthday,
        profile_picture: avatar_url,
        city: city,
        personal_website: personal_website,
        learning_context: context,
        learning_goal: goal,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_HEADER_CAAS'],
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateLearnerProfilePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  const fetcher = updateLearnerProfilePATCH;
  return useMutation(
    args => fetcher(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('learner_profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('learner_profile');
        queryClient.invalidateQueries('learner_profiles');
      },
    }
  );
};

export const upsertParentPOST = (Constants, { parentEmail }, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/upsert_parent`, {
    body: JSON.stringify({ email_in: parentEmail }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useUpsertParentPOST = ({ parentEmail }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/upsert_parent`,
    {
      body: JSON.stringify({ email_in: parentEmail }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchUpsertParentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  parentEmail,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/rpc/upsert_parent`,
    {
      body: JSON.stringify({ email_in: parentEmail }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchUpsertParent: refetch });
};

export const getEvidencesForPublicWallGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/rest/v1/posts?is_public=eq.true&select=*,author:user_id(*)`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetEvidencesForPublicWallGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const fetcher = getEvidencesForPublicWallGET;
  return useQuery(['posts', args], () => fetcher(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetEvidencesForPublicWallGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEvidencesForPublicWallGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);

  return children({
    loading,
    data,
    error,
    refetchGetEvidencesForPublicWall: refetch,
  });
};
