import React from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as PublicSchemaApi from "../apis/PublicSchemaApi.js";
import * as SupabaseAuthApi from "../apis/SupabaseAuthApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import Images from "../config/Images";
import * as CustomPackages from "../custom-files/CustomPackages";
import dateFormatting from "../global-functions/dateFormatting";
import getDayDiff from "../global-functions/getDayDiff";
import getFinalPrice from "../global-functions/getFinalPrice";
import getLXPType from "../global-functions/getLXPType";
import getLearningTypeLabel from "../global-functions/getLearningTypeLabel";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import { MapCallout, MapMarker, MapView } from "@draftbit/maps";
import {
  Button,
  Checkbox,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Link,
  Picker,
  ScreenContainer,
  Slider,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from "@draftbit/ui";
import { useIsFocused } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Fetch } from "react-request";

const ExploreScreen = (props) => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const applyCityFilter = (centers) => {
    if (pickerValue2 === "all") return centers;

    return centers.length
      ? centers.filter((item) => item.City === pickerValue2)
      : [];
  };

  const applyDateFilter = (newRange) => {
    // console.log(newDate)
    if (newRange > -1)
      setSearchableLxps(
        allLxps.filter(({ upcoming_session }) => {
          // console.log(CustomPackages.moment(start_time), CustomPackages.moment(newDate))
          // console.log(CustomPackages.moment(start_time) < CustomPackages.moment(newDate))
          if (upcoming_session) {
            console.log(
              "diff",
              CustomPackages.moment(upcoming_session?.start_time).diff(
                CustomPackages.moment(),
                "days"
              )
            );
            return (
              CustomPackages.moment(upcoming_session?.start_time).diff(
                CustomPackages.moment(),
                "days"
              ) <= newRange
            );
          }
          return false;
          // console.log(CustomPackages.moment(start_time).isBefore(CustomPackages.moment(newDate)))
          // return upcoming_session ? CustomPackages.moment(upcoming_session?.start_time).diff(CustomPackages.moment(), 'days' ) < 7 : false
        })
      );
    // else if (newRage === 0) setSearchableLxps(allLxps.filter(({upcoming_session})=> upcoming_session))
    else setSearchableLxps(allLxps);
  };

  const applyStartTimeFilter = (pickerTime) => {
    setSearchableLxps(
      allLxps.filter(({ upcoming_session }) =>
        upcoming_session
          ? CustomPackages.moment(upcoming_session.start_time).format(
              "HH:mm"
            ) === CustomPackages.moment(pickerTime).format("HH:mm")
          : false
      )
    );
  };

  const applyEndTimeFilter = (pickerTime) => {
    const getFormatedEndTime = (str, dur, format) =>
      CustomPackages.moment(str).add(dur, "minutes").format(format);

    setSearchableLxps(
      allLxps.filter(({ upcoming_session }) =>
        upcoming_session
          ? getFormatedEndTime(
              upcoming_session.start_time,
              upcoming_session.duration,
              "HH:mm"
            ) === CustomPackages.moment(pickerTime).format("HH:mm")
          : false
      )
    );
  };

  const createSubjectList = (res) => {
    return res.map(({ name }) => name);
  };

  const applyTopicFilter = (topic) => {
    if (topic !== "All")
      setSearchableLxps(allLxps.filter(({ subject }) => subject === topic));
    else setSearchableLxps(allLxps);
  };

  const applySearchFilter = (query) => {
    // console.log(query, allLxps)
    if (!query || query.length < 3) setSearchableLxps(allLxps);
    // console.log(query)
    setSearchableLxps(() => {
      return allLxps.filter((item) => {
        return (
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.description.toLowerCase().includes(query.toLowerCase()) ||
          item.author.firstName.toLowerCase().includes(query.toLowerCase()) ||
          item.author.lastName.toLowerCase().includes(query.toLowerCase())
        );
      });
    });
  };

  const applyClassTypeFilter = (classType) => {
    // console.log(classType)
    if (classType !== "All")
      setSearchableLxps(
        allLxps.filter(({ lxp_location_type }) =>
          classType === "Online"
            ? lxp_location_type === "zoom"
            : lxp_location_type !== "zoom"
        )
      );
    else setSearchableLxps(allLxps);
  };

  const showMapDiv = (tabOrLarger) => {
    return tabOrLarger && sliderValue !== 1;
  };

  const findCityLatLong = (selectedCity, learningCenters) => {
    let { latitude, longitude } = learningCenters.find(
      ({ City }) => selectedCity === City
    );

    if (latitude && longitude) return { latitude, longitude };
    return {};
    // return {latitude: 23.777176, longitude: 90.399452}
  };

  const getCityOptions = (centers) => {
    return centers.map(({ City }) => ({ label: City, value: City }));
    // console.log(options)
    // return options
  };
  // assuming the url path https://learn.cityasaschool.com/?lxp_id=some_id&screen=LXPDetails

  // linking config:

  /**
* 
* const linking = {
 config: {
   screens:{
   ExploreScreen: "",
   LoginScreen: "login/:redirect",
   LXPDetailsScreen: "lxp_details/:lxp_id",
   UpdatePasswordScreen: "update_password/:accessToken"
 }
 },
};
*/

  let requiredAuth = false;
  let redirectPath = "";
  let redirectObj = {};
  switch (props?.route?.params?.screen) {
    case "LXPDetails": {
      redirectPath = "LXPDetailsScreen";
      redirectObj = { lxp_id: props?.route?.params?.lxp_id };
      break;
    }

    case "UpdatePassword": {
      requiredAuth = false;
      redirectPath = "UpdatePasswordScreen";
      redirectObj = { accessToken: props?.route?.params?.accessToken };
      break;
    }

    // case 'LocationListing':
    // {
    //     // redirectPath = 'LoginScreen'
    //     requiredAuth = true
    //     break;
    // }

    // case 'PublicProfile':
    // {
    //     requiredAuth = false
    //     redirectPath = 'PublicProfileScreen'
    //     redirectObj = {handel: props.route.params?.handel}
    //     break;
    // }
  }

  if (redirectPath) {
    props.navigation.navigate(redirectPath, redirectObj);
  }
  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const learning_centers = (
          await PublicSchemaApi.getLearningCentersByCityGET(Constants, {
            city: pickerValue2,
          })
        )?.json;
        setLearningCenters(learning_centers);
        const res = (await PublicSchemaApi.getSubjectsGET(Constants))?.json;
        const subjects = createSubjectList(res);
        setGlobalVariableValue({
          key: "SUBJECTS",
          value: ["All"].concat(subjects),
        });
        const cityRes = (await PublicSchemaApi.getCityNamesGET(Constants))
          ?.json;
        const options = (() => {
          if (cityRes?.length) {
            return getCityOptions(cityRes);
          }
        })();
        setCityOptions(options);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [allLxps, setAllLxps] = React.useState([]);
  const [centerLat, setCenterLat] = React.useState(51.50853);
  const [centerLong, setCenterLong] = React.useState(-0.12574);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [classType, setClassType] = React.useState("All");
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [dateRange, setDateRange] = React.useState(-1);
  const [endTime, setEndTime] = React.useState(new Date());
  const [learningCenters, setLearningCenters] = React.useState([]);
  const [mediaOptions, setMediaOptions] = React.useState([
    "All",
    "Online",
    "In-person",
  ]);
  const [pickerValue, setPickerValue] = React.useState("");
  const [pickerValue2, setPickerValue2] = React.useState("London");
  const [searchHours, setSearchHours] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchableLxps, setSearchableLxps] = React.useState([]);
  const [showDateFilter, setShowDateFilter] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [startTime, setStartTime] = React.useState(new Date());
  const [textInputValue, setTextInputValue] = React.useState("");
  const [topic, setTopic] = React.useState("All");
  const [topicOptions, setTopicOptions] = React.useState([
    "All",
    "Arts",
    "Coding & Tech",
    "English",
    "Health & Wellness",
    "Life skills",
    "Math",
    "Music",
    "Sicence & Nature",
    "Social Studies",
    "World Languages",
  ]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Tablet) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: "center",
                  borderWidth: 0,
                  flexDirection: "row",
                  height: 72,
                },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    borderBottomWidth: 0,
                    borderColor: theme.colors["Inactive Gray Text"],
                    borderRightWidth: 1,
                    flexDirection: "row",
                    height: 72,
                    maxHeight: 72,
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors["Primary"],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate("ExploreScreen");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={"cover"}
                      source={Images.CASNavLogo}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    borderBottomWidth: 1,
                    borderColor: theme.colors["Inactive Gray Text"],
                    flex: 1,
                    flexDirection: "row",
                    height: "100%",
                    justifyContent: "space-between",
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)["Work Sans Regular"],
                      {
                        fontFamily: "Questrial_400Regular",
                        fontSize: 24,
                        letterSpacing: 0.2,
                        lineHeight: 20,
                        textTransform: "uppercase",
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {"city as a school"}
                </Text>
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)["Nav 2"],
                    dimensions.width
                  )}
                >
                  {/* AI Copilot */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            "https://ark.cityasaschool.com/"
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      { marginRight: 16 },
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "center",
                          flexDirection: "row",
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={"MaterialIcons/auto-awesome"}
                        size={18}
                        color={theme.colors["Strong"]}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"AI Copilot"}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate("DiscoverScreen");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginRight: 16 },
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "center",
                          flexDirection: "row",
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors["Strong"]}
                        name={"Feather/grid"}
                        size={18}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Discover"}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Experiences */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate("ExploreScreen");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 0 },
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Experiences */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: "center", flexDirection: "row" },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors["Strong"]}
                        name={"Feather/search"}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Experiences"}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} right={16} left={0} />
                  {/* Join */}
                  <>
                    {Constants["AUTH_UUID_CAAS"] ? null : (
                      <Link
                        onPress={() => {
                          try {
                            navigation.navigate("LoginScreen");
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)["Link"],
                            {
                              fontFamily: "Inter_500Medium",
                              fontSize: 16,
                              lineHeight: 19,
                              marginLeft: 12,
                            }
                          ),
                          dimensions.width
                        )}
                        title={"Join"}
                      />
                    )}
                  </>
                  {/* Avatar */}
                  <>
                    {!Constants["AUTH_UUID_CAAS"] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: "center", flexDirection: "row" },
                          dimensions.width
                        )}
                      >
                        {/* Touchable (go to setting) */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate("ProfileScreen");
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {!Constants["AUTH_PROFILE_CAAS"]
                              ?.profile_picture ? null : (
                              <Circle
                                size={24}
                                bgColor={theme.colors["Primary"]}
                              >
                                <>
                                  {!Constants["AUTH_PROFILE_CAAS"]
                                    ?.profile_picture ? null : (
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            "Image"
                                          ],
                                          { height: "100%", width: "100%" }
                                        ),
                                        dimensions.width
                                      )}
                                      resizeMode={"cover"}
                                      source={{
                                        uri: `${Constants["AUTH_PROFILE_CAAS"]?.profile_picture}`,
                                      }}
                                    />
                                  )}
                                </>
                                <>
                                  {Constants["AUTH_PROFILE_CAAS"]
                                    ?.profile_picture ? null : (
                                    <Icon name={"Ionicons/person"} size={24} />
                                  )}
                                </>
                              </Circle>
                            )}
                          </>
                        </Touchable>
                        {/* Logout */}
                        <IconButton
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (await SupabaseAuthApi.logoutPOST(Constants))
                                  ?.json;
                                setGlobalVariableValue({
                                  key: "AUTH_HEADER_CAAS",
                                  value: "",
                                });
                                setGlobalVariableValue({
                                  key: "AUTH_UUID_CAAS",
                                  value: "",
                                });
                                setGlobalVariableValue({
                                  key: "AUTH_PROFILE_CAAS",
                                  value: {},
                                });
                                navigation.navigate("LoginScreen", {
                                  redirectPath: "",
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 16 },
                            dimensions.width
                          )}
                          icon={"MaterialCommunityIcons/login-variant"}
                          size={24}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: "row" },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Tablet) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors["Inactive Gray Text"],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 12,
                    paddingRight: 0,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Brilliant */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          "https://brilliant.org/classroom/join/2bx4jr/"
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        backgroundColor: theme.colors["Primary"],
                        borderRadius: 8,
                        height: 46,
                        justifyContent: "center",
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)["Image"],
                          { height: 32, width: 32 }
                        ),
                        dimensions.width
                      )}
                      resizeMode={"cover"}
                      source={Images.Image89}
                    />
                  </View>
                </Touchable>
                <Spacer top={8} right={8} bottom={8} left={8} />
                {/* Envisioning */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          "https://techdetector.de/radar?"
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.colors["Custom Color_4"],
                        borderRadius: 8,
                        height: 46,
                        justifyContent: "center",
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 18, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={"stretch"}
                      source={Images.Frame6191}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* Civics */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          "https://airtable.com/invite/l?inviteId=inveHqpcC2Ju2xK4L&inviteToken=f99686deb40d26b5fe3cb8ad9a65e600761061c35dd90c4c8d86765fe2345ffa&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts"
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: "center",
                        alignItems: "center",
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: "rgb(242, 183, 44)",
                        },
                        borderRadius: 8,
                        height: 46,
                        justifyContent: "center",
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: 32 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                        },
                        dimensions.width
                      )}
                      resizeMode={"stretch"}
                      source={Images.AbstractShape1}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* Unity */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          "https://airtable.com/invite/l?inviteId=invxg2G3nF32VEtJv&inviteToken=92a5cbd73084deec82dd88ad7469fc2a2db894fd95f960baa743a46ecef4db55&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts"
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: "center",
                        alignItems: "center",
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors["Strong"],
                        },
                        borderRadius: 8,
                        height: 46,
                        justifyContent: "center",
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: 32 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                        },
                        dimensions.width
                      )}
                      resizeMode={"stretch"}
                      source={Images.Unity1}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* More Guilds */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          "https://www.cityasaschool.com/guilds"
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        borderColor: theme.colors["Primary"],
                        borderRadius: 8,
                        borderWidth: 1,
                        height: 46,
                        justifyContent: "center",
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      name={"AntDesign/plus"}
                      color={theme.colors["Primary"]}
                    />
                  </View>
                </Touchable>
              </View>
            )}
          </>
          {/* Body */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
              },
              dimensions.width
            )}
          >
            {/* Title & Filter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            >
              {/* Filter */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexWrap: "wrap",
                    maxWidth: [
                      { minWidth: Breakpoints.Mobile, value: "50%" },
                      { minWidth: Breakpoints.Tablet, value: "90%" },
                      { minWidth: Breakpoints.Desktop, value: "100%" },
                      { minWidth: Breakpoints.Laptop, value: "100%" },
                    ],
                    minWidth: 350,
                    width: "100%",
                  },
                  dimensions.width
                )}
              >
                {/* Searchbar & Link */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flexDirection: "row",
                      flexWrap: { minWidth: Breakpoints.Tablet, value: "wrap" },
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: "space-between",
                      },
                      marginLeft: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Mobile, value: 300 },
                        { minWidth: Breakpoints.Laptop, value: "100%" },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        flexDirection: [
                          { minWidth: Breakpoints.Tablet, value: "row" },
                          { minWidth: Breakpoints.Mobile, value: "row" },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Searchbar Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "center",
                          borderColor: theme.colors["gray line"],
                          borderRadius: 8,
                          borderWidth: 1,
                          flexDirection: "row",
                          paddingBottom: 10,
                          paddingLeft: 14,
                          paddingTop: 10,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: "85%" },
                            { minWidth: Breakpoints.Desktop, value: "100%" },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <IconButton
                        icon={"EvilIcons/search"}
                        size={32}
                        color={theme.colors["Strong"]}
                      />
                      <TextInput
                        onChangeText={(newTextInputValue) => {
                          try {
                            setSearchQuery(newTextInputValue);
                            applySearchFilter(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors["Strong"],
                            fontFamily: "System",
                            fontWeight: "400",
                            paddingBottom: 8,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 8,
                            width: "90%",
                          },
                          dimensions.width
                        )}
                        value={searchQuery}
                        placeholder={"What do you want to learn?"}
                        autoCapitalize={"none"}
                      />
                    </View>
                    <Link
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              "https://airtable.com/shrJ11dtDID1YjZCl"
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinkStyles(theme)["Link"],
                          { marginLeft: 16 }
                        ),
                        dimensions.width
                      )}
                      title={"Request Classes"}
                    />
                  </View>
                  {/* City Filter */}
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Tablet,
                              value: "center",
                            },
                            flexDirection: {
                              minWidth: Breakpoints.Tablet,
                              value: "row",
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              "Inter Bold 20 Title"
                            ],
                            dimensions.width
                          )}
                        >
                          {"Discover Learning in "}
                        </Text>
                        <Picker
                          onValueChange={(newPickerValue) => {
                            const handler = async () => {
                              const pickerValue = newPickerValue;
                              try {
                                const res = (
                                  await PublicSchemaApi.getLearningCentersByCityGET(
                                    Constants,
                                    { city: newPickerValue }
                                  )
                                )?.json;
                                setPickerValue2(newPickerValue);
                                const newLocObj = findCityLatLong(
                                  newPickerValue,
                                  res
                                );
                                const lat = newLocObj?.latitude;
                                const long = newLocObj?.longitude;
                                if (lat) {
                                  setCenterLat(lat);
                                }
                                if (long) {
                                  setCenterLong(long);
                                }
                                setLearningCenters(res);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: {
                                minWidth: Breakpoints.Tablet,
                                value: '"rgba(0, 0, 0, 0)"',
                              },
                              color: {
                                minWidth: Breakpoints.Tablet,
                                value: theme.colors["Primary"],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Tablet,
                                value: "Inter_500Medium",
                              },
                              fontSize: {
                                minWidth: Breakpoints.Tablet,
                                value: 18,
                              },
                              width: {
                                minWidth: Breakpoints.Tablet,
                                value: 150,
                              },
                            },
                            dimensions.width
                          )}
                          options={cityOptions}
                          value={pickerValue2}
                          leftIconMode={"inset"}
                          type={"solid"}
                          autoDismissKeyboard={true}
                          iconSize={12}
                          rightIconName={"AntDesign/down"}
                          iconColor={theme.colors["Primary"]}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Options */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flexDirection: "row",
                      marginTop: 16,
                      maxWidth: { minWidth: Breakpoints.Tablet, value: "50%" },
                      width: "100%",
                    },
                    dimensions.width
                  )}
                >
                  {/* topic */}
                  <Picker
                    onValueChange={(newTopicValue) => {
                      try {
                        setTopic(newTopicValue);
                        applyTopicFilter(newTopicValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.PickerStyles(theme)["picker work sans"],
                        {
                          borderColor: theme.colors["gray line"],
                          borderRadius: 8,
                          fontFamily: "System",
                          fontWeight: "400",
                          height: 42,
                          maxWidth: "35%",
                          minWidth: "23%",
                        }
                      ),
                      dimensions.width
                    )}
                    options={Constants["SUBJECTS"]}
                    value={topic}
                    leftIconMode={"inset"}
                    type={"solid"}
                    iconSize={32}
                    iconColor={theme.colors["dark gray text"]}
                    placeholderTextColor={theme.colors["dark gray text"]}
                    rightIconName={"MaterialIcons/keyboard-arrow-down"}
                  />
                  <Spacer top={8} bottom={8} left={10} right={10} />
                  {/* type */}
                  <Picker
                    onValueChange={(newTypeValue) => {
                      try {
                        setClassType(newTypeValue);
                        applyClassTypeFilter(newTypeValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.PickerStyles(theme)["picker work sans"],
                        {
                          borderColor: theme.colors["gray line"],
                          borderRadius: 8,
                          borderWidth: 1,
                          height: 42,
                          maxWidth: "32%",
                          width: "26%",
                        }
                      ),
                      dimensions.width
                    )}
                    options={mediaOptions}
                    value={classType}
                    leftIconMode={"inset"}
                    type={"solid"}
                    placeholderTextColor={theme.colors["dark gray text"]}
                    iconSize={32}
                    iconColor={theme.colors["dark gray text"]}
                    rightIconName={"MaterialIcons/keyboard-arrow-down"}
                  />
                  <Spacer top={8} bottom={8} left={10} right={10} />
                  {/* Custom Date Filter */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        borderColor: theme.colors["gray line"],
                        borderRadius: 6,
                        borderWidth: 1,
                        flexDirection: "row",
                        height: 42,
                        width: "26%",
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowDateFilter(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { borderRadius: 8, height: "100%", width: "100%" },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: "center",
                            borderRadius: 8,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: 16,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Date Filter */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors["Light"],
                              fontFamily: "System",
                              fontWeight: "400",
                            },
                            dimensions.width
                          )}
                        >
                          {"Date"}
                        </Text>
                        <Icon
                          size={32}
                          name={"MaterialIcons/keyboard-arrow-down"}
                          color={theme.colors["dark gray text"]}
                        />
                      </View>
                    </Touchable>
                  </View>
                  {/* Toggle Map */}
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: "center",
                            backgroundColor: theme.colors["Not Black"],
                            borderRadius: 6,
                            flexDirection: "row",
                            height: 42,
                            marginLeft: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors["Background"],
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                            },
                            dimensions.width
                          )}
                        >
                          {"Show Map"}
                        </Text>
                        <Slider
                          onValueChange={(newSliderValue) => {
                            try {
                              setSliderValue(newSliderValue);
                              console.log(newSliderValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              height: 25,
                              marginLeft: 12,
                              marginRight: 12,
                              width: 70,
                            },
                            dimensions.width
                          )}
                          value={sliderValue}
                          minimumValue={0}
                          maximumTrackTintColor={theme.colors["Primary"]}
                          minimumTrackTintColor={theme.colors["Light"]}
                          maximumValue={1}
                          step={1}
                          thumbTintColor={theme.colors["Medium"]}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
            {/* Map & LXPs */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: "row" },
                dimensions.width
              )}
            >
              {/* LXPs */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    maxWidth: { minWidth: Breakpoints.Laptop, value: "100%" },
                    minWidth: { minWidth: Breakpoints.Laptop, value: "50%" },
                  },
                  dimensions.width
                )}
              >
                {/* LXPS */}
                <PublicSchemaApi.FetchGetLXPSGET
                  handlers={{
                    onData: (lXPSData) => {
                      try {
                        setAllLxps(lXPSData);
                        setSearchableLxps(lXPSData);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                >
                  {({ loading, error, data, refetchGetLXPS }) => {
                    const lXPSData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Empty */}
                        <>
                          {!!searchableLxps?.length ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    "Inter Regular"
                                  ],
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: "Inter_700Bold",
                                    fontSize: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"No Learning Experience Found!"}
                            </Text>
                          )}
                        </>
                        {/* LXP List */}
                        <>
                          {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                            <FlatList
                              renderItem={({ item }) => {
                                const lXPListData = item;
                                return (
                                  <>
                                    {/* Other */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Tablet
                                      ) ? null : (
                                        <View>
                                          {/* Full */}
                                          <>
                                            {!sliderValue ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 800,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 650,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 420,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 350,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Touchable
                                                  onPress={() => {
                                                    try {
                                                      navigation.navigate(
                                                        "LXPDetailsScreen",
                                                        {
                                                          lxp_id:
                                                            lXPListData?.id,
                                                        }
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    { marginBottom: 16 },
                                                    dimensions.width
                                                  )}
                                                  activeOpacity={0.8}
                                                  disabledOpacity={0.8}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignContent: "center",
                                                        borderColor:
                                                          theme.colors[
                                                            "gray line"
                                                          ],
                                                        borderRadius: 8,
                                                        borderWidth: 1,
                                                        height: {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 240,
                                                        },
                                                        paddingBottom: 8,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderBottomLeftRadius: 0,
                                                          borderBottomRightRadius: 0,
                                                          borderColor: {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value:
                                                              theme.colors[
                                                                "Error"
                                                              ],
                                                          },
                                                          borderRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          borderTopRightRadius: 8,
                                                          height: 94,
                                                          overflow: "hidden",
                                                          width: "100%",
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Image
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 0,
                                                            borderTopLeftRadius: 8,
                                                            borderTopRightRadius: 8,
                                                            height: "100%",
                                                            width: "100%",
                                                          },
                                                          dimensions.width
                                                        )}
                                                        source={{
                                                          uri: `${lXPListData?.coverimage}`,
                                                        }}
                                                        resizeMode={"cover"}
                                                      />
                                                      {/* starting card */}
                                                      <>
                                                        {!lXPListData?.upcoming_session ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  "flex-end",
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    "Background"
                                                                  ],
                                                                borderRadius: 8,
                                                                bottom: 8,
                                                                flexDirection:
                                                                  "row",
                                                                left: 4,
                                                                padding: 8,
                                                                position:
                                                                  "absolute",
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Icon
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  marginBottom: 2,
                                                                },
                                                                dimensions.width
                                                              )}
                                                              name={
                                                                "MaterialCommunityIcons/timer-sand-empty"
                                                              }
                                                              size={12}
                                                            />
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    "Work Sans Regular"
                                                                  ],
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                    fontFamily:
                                                                      "WorkSans_600SemiBold",
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {"Starts "}
                                                              {getDayDiff(
                                                                lXPListData
                                                                  ?.upcoming_session
                                                                  ?.start_time
                                                              )}
                                                            </Text>
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                    <Spacer
                                                      right={8}
                                                      bottom={8}
                                                      left={8}
                                                      top={0}
                                                    />
                                                    {/* Details */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* title */}
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )["Inter Regular"],
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  "Strong"
                                                                ],
                                                              fontFamily:
                                                                "Inter_700Bold",
                                                              fontSize: 16,
                                                              lineHeight: 15,
                                                              paddingBottom: 4,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                        ellipsizeMode={"tail"}
                                                        numberOfLines={1}
                                                      >
                                                        {lXPListData?.title}
                                                      </Text>
                                                      <Spacer
                                                        right={8}
                                                        bottom={8}
                                                        left={8}
                                                        top={0}
                                                      />
                                                      {/* Meta */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "row",
                                                            justifyContent:
                                                              "space-between",
                                                            marginBottom: {
                                                              minWidth:
                                                                Breakpoints.Laptop,
                                                              value: 8,
                                                            },
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* author */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                "center",
                                                              flex: 1,
                                                              flexDirection:
                                                                "row",
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Circle
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                overflow: {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value:
                                                                    "hidden",
                                                                },
                                                              },
                                                              dimensions.width
                                                            )}
                                                            bgColor={
                                                              theme.colors.light
                                                            }
                                                            size={24}
                                                          >
                                                            <>
                                                              {!lXPListData
                                                                ?.author
                                                                ?.profile_picture ? null : (
                                                                <Image
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      height:
                                                                        "100%",
                                                                      width:
                                                                        "100%",
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                  resizeMode={
                                                                    "contain"
                                                                  }
                                                                  source={{
                                                                    uri: `${lXPListData?.author?.profile_picture}`,
                                                                  }}
                                                                />
                                                              )}
                                                            </>
                                                            <>
                                                              {lXPListData
                                                                ?.author
                                                                ?.profile_picture ? null : (
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    "Ionicons/person"
                                                                  }
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      "dark gray text"
                                                                    ]
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          </Circle>
                                                          {/* full name */}
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors
                                                                    .strong,
                                                                marginLeft: 10,
                                                              },
                                                              dimensions.width
                                                            )}
                                                            adjustsFontSizeToFit={
                                                              true
                                                            }
                                                            numberOfLines={1}
                                                            ellipsizeMode={
                                                              "tail"
                                                            }
                                                          >
                                                            {
                                                              lXPListData
                                                                ?.author
                                                                ?.firstName
                                                            }{" "}
                                                            {
                                                              lXPListData
                                                                ?.author
                                                                ?.lastName
                                                            }
                                                          </Text>
                                                        </View>
                                                        {/* Starting */}
                                                        <>
                                                          {!lXPListData?.upcoming_session ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      "flex-end",
                                                                  },
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                                adjustsFontSizeToFit={
                                                                  true
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {dateFormatting(
                                                                  lXPListData
                                                                    ?.upcoming_session
                                                                    ?.start_time,
                                                                  "Do MMM HH:mm"
                                                                )}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                      </View>
                                                      {/* description */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            marginTop: 8,
                                                            overflow: "hidden",
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* description */}
                                                        <Text
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  "dark gray text"
                                                                ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                          ellipsizeMode={"tail"}
                                                          numberOfLines={1}
                                                        >
                                                          {
                                                            lXPListData?.description
                                                          }
                                                        </Text>
                                                      </View>
                                                      <Spacer
                                                        right={8}
                                                        left={8}
                                                        top={0}
                                                        bottom={14}
                                                      />
                                                      {/* Quick Info */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              "center",
                                                            flex: {
                                                              minWidth:
                                                                Breakpoints.Laptop,
                                                              value: 1,
                                                            },
                                                            flexDirection: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: "row",
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: "row",
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: "row",
                                                              },
                                                            ],
                                                            justifyContent: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value:
                                                                  "space-between",
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value:
                                                                  "flex-start",
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Desktop,
                                                                value:
                                                                  "flex-start",
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value:
                                                                  "flex-start",
                                                              },
                                                            ],
                                                            paddingBottom: {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value: 0,
                                                            },
                                                            width: "100%",
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* sessions sm */}
                                                        <>
                                                          {sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flex: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 1,
                                                                  },
                                                                  flexDirection:
                                                                    "row",
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                name={
                                                                  "AntDesign/calendar"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                                size={14}
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={5}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {
                                                                  lXPListData?.up_session_count
                                                                }
                                                                {" sessions"}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* sessions */}
                                                        <>
                                                          {!sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                name={
                                                                  "AntDesign/calendar"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                                size={14}
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={5}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {
                                                                  lXPListData?.up_session_count
                                                                }
                                                                {" sessions"}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* type sm */}
                                                        <>
                                                          {sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flex: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 1,
                                                                  },
                                                                  flexDirection:
                                                                    "row",
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                size={14}
                                                                name={
                                                                  "Ionicons/ios-location-outline"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={4}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {getLearningTypeLabel(
                                                                  lXPListData?.lxp_location_type
                                                                )}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* type */}
                                                        <>
                                                          {!sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                size={14}
                                                                name={
                                                                  "Ionicons/ios-location-outline"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={4}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {getLXPType(
                                                                  lXPListData?.isvirtual
                                                                )}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* ages sm */}
                                                        <>
                                                          {sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flex: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 1,
                                                                  },
                                                                  flexDirection:
                                                                    "row",
                                                                  marginRight: {
                                                                    minWidth:
                                                                      Breakpoints.Desktop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {"Ages "}
                                                                {
                                                                  lXPListData?.min_age
                                                                }
                                                                {"-"}
                                                                {
                                                                  lXPListData?.max_age
                                                                }{" "}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* ages */}
                                                        <>
                                                          {!sliderValue ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                  marginRight: {
                                                                    minWidth:
                                                                      Breakpoints.Desktop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {"Ages "}
                                                                {
                                                                  lXPListData?.min_age
                                                                }
                                                                {"-"}
                                                                {
                                                                  lXPListData?.max_age
                                                                }{" "}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                        {/* price */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "row",
                                                              marginLeft: {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: 4,
                                                              },
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Icon
                                                            size={14}
                                                            name={
                                                              "Feather/dollar-sign"
                                                            }
                                                            color={
                                                              theme.colors[
                                                                "dark gray text"
                                                              ]
                                                            }
                                                          />
                                                          <Spacer
                                                            top={8}
                                                            bottom={8}
                                                            right={0}
                                                            left={4}
                                                          />
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  "Work Sans Regular"
                                                                ],
                                                                {
                                                                  color:
                                                                    theme
                                                                      .colors[
                                                                      "dark gray text"
                                                                    ],
                                                                  fontFamily:
                                                                    "System",
                                                                  fontSize: 14,
                                                                  fontWeight:
                                                                    "400",
                                                                  lineHeight: 13,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                            numberOfLines={1}
                                                            ellipsizeMode={
                                                              "tail"
                                                            }
                                                          >
                                                            {getFinalPrice(
                                                              Variables,
                                                              lXPListData?.pricing_per_session
                                                            )}
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </Touchable>
                                              </View>
                                            )}
                                          </>
                                          {/* Half */}
                                          <>
                                            {sliderValue ? null : (
                                              <View>
                                                <Touchable
                                                  onPress={() => {
                                                    try {
                                                      navigation.navigate(
                                                        "LXPDetailsScreen",
                                                        {
                                                          lxp_id:
                                                            lXPListData?.id,
                                                        }
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      marginBottom: 16,
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value: 160,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 300,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 720,
                                                        },
                                                      ],
                                                    },
                                                    dimensions.width
                                                  )}
                                                  activeOpacity={0.8}
                                                  disabledOpacity={0.8}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignContent: "center",
                                                        borderColor:
                                                          theme.colors[
                                                            "gray line"
                                                          ],
                                                        borderRadius: 8,
                                                        borderWidth: 1,
                                                        height: {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 240,
                                                        },
                                                        paddingBottom: 8,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderBottomLeftRadius: 0,
                                                          borderBottomRightRadius: 0,
                                                          borderRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          borderTopRightRadius: 8,
                                                          height: 94,
                                                          overflow: "hidden",
                                                          width: "100%",
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Image
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 0,
                                                            borderTopLeftRadius: 8,
                                                            borderTopRightRadius: 8,
                                                            height: "100%",
                                                            width: "100%",
                                                          },
                                                          dimensions.width
                                                        )}
                                                        source={{
                                                          uri: `${lXPListData?.coverimage}`,
                                                        }}
                                                        resizeMode={"cover"}
                                                      />
                                                      {/* starting card */}
                                                      <>
                                                        {!lXPListData?.upcoming_session ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  "flex-end",
                                                                backgroundColor:
                                                                  theme.colors[
                                                                    "Background"
                                                                  ],
                                                                borderRadius: 8,
                                                                bottom: 8,
                                                                flexDirection:
                                                                  "row",
                                                                left: 4,
                                                                padding: 8,
                                                                position:
                                                                  "absolute",
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Icon
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  marginBottom: 2,
                                                                },
                                                                dimensions.width
                                                              )}
                                                              name={
                                                                "MaterialCommunityIcons/timer-sand-empty"
                                                              }
                                                              size={12}
                                                            />
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    "Work Sans Regular"
                                                                  ],
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                    fontFamily:
                                                                      "WorkSans_600SemiBold",
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {"Starts "}
                                                              {getDayDiff(
                                                                lXPListData
                                                                  ?.upcoming_session
                                                                  ?.start_time
                                                              )}
                                                            </Text>
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                    <Spacer
                                                      right={8}
                                                      bottom={8}
                                                      left={8}
                                                      top={0}
                                                    />
                                                    {/* Details */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* title */}
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )["Inter Regular"],
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  "Strong"
                                                                ],
                                                              fontFamily:
                                                                "Inter_700Bold",
                                                              fontSize: 16,
                                                              lineHeight: 15,
                                                              paddingBottom: 4,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                        ellipsizeMode={"tail"}
                                                        numberOfLines={1}
                                                      >
                                                        {lXPListData?.title}
                                                      </Text>
                                                      <Spacer
                                                        right={8}
                                                        bottom={8}
                                                        left={8}
                                                        top={0}
                                                      />
                                                      {/* Meta */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "row",
                                                            justifyContent:
                                                              "space-between",
                                                            marginBottom: {
                                                              minWidth:
                                                                Breakpoints.Laptop,
                                                              value: 8,
                                                            },
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* author */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                "center",
                                                              flex: 1,
                                                              flexDirection:
                                                                "row",
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Circle
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                overflow: {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value:
                                                                    "hidden",
                                                                },
                                                              },
                                                              dimensions.width
                                                            )}
                                                            bgColor={
                                                              theme.colors.light
                                                            }
                                                            size={24}
                                                          >
                                                            <>
                                                              {!lXPListData
                                                                ?.author
                                                                ?.profile_picture ? null : (
                                                                <Image
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      height:
                                                                        "100%",
                                                                      width:
                                                                        "100%",
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                  resizeMode={
                                                                    "contain"
                                                                  }
                                                                  source={{
                                                                    uri: `${lXPListData?.author?.profile_picture}`,
                                                                  }}
                                                                />
                                                              )}
                                                            </>
                                                            <>
                                                              {lXPListData
                                                                ?.author
                                                                ?.profile_picture ? null : (
                                                                <Icon
                                                                  size={24}
                                                                  name={
                                                                    "Ionicons/person"
                                                                  }
                                                                  color={
                                                                    theme
                                                                      .colors[
                                                                      "dark gray text"
                                                                    ]
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          </Circle>
                                                          {/* full name */}
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                color:
                                                                  theme.colors
                                                                    .strong,
                                                                marginLeft: 10,
                                                              },
                                                              dimensions.width
                                                            )}
                                                            adjustsFontSizeToFit={
                                                              true
                                                            }
                                                            numberOfLines={1}
                                                            ellipsizeMode={
                                                              "tail"
                                                            }
                                                          >
                                                            {
                                                              lXPListData
                                                                ?.author
                                                                ?.firstName
                                                            }{" "}
                                                            {
                                                              lXPListData
                                                                ?.author
                                                                ?.lastName
                                                            }
                                                          </Text>
                                                        </View>
                                                        {/* Starting */}
                                                        <>
                                                          {!lXPListData?.upcoming_session ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      "flex-end",
                                                                  },
                                                                  flex: 1,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .strong,
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                                adjustsFontSizeToFit={
                                                                  true
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {dateFormatting(
                                                                  lXPListData
                                                                    ?.upcoming_session
                                                                    ?.start_time,
                                                                  "Do MMM HH:mm"
                                                                )}
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                      </View>
                                                      {/* description */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            marginTop: 8,
                                                            overflow: "hidden",
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* description */}
                                                        <Text
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  "dark gray text"
                                                                ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                          numberOfLines={2}
                                                          ellipsizeMode={"tail"}
                                                        >
                                                          {
                                                            lXPListData?.description
                                                          }
                                                        </Text>
                                                      </View>
                                                      <Spacer
                                                        right={8}
                                                        left={8}
                                                        top={0}
                                                        bottom={14}
                                                      />
                                                      {/* Quick Info */}
                                                      <>
                                                        {!(
                                                          dimensions.width >=
                                                          Breakpoints.Laptop
                                                        ) ? null : (
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  "center",
                                                                flex: {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: 1,
                                                                },
                                                                flexDirection: [
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.BigScreen,
                                                                    value:
                                                                      "row",
                                                                  },
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      "row",
                                                                  },
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Mobile,
                                                                    value:
                                                                      "row",
                                                                  },
                                                                ],
                                                                flexWrap: {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: "wrap",
                                                                },
                                                                justifyContent:
                                                                  [
                                                                    {
                                                                      minWidth:
                                                                        Breakpoints.Mobile,
                                                                      value:
                                                                        "space-between",
                                                                    },
                                                                    {
                                                                      minWidth:
                                                                        Breakpoints.BigScreen,
                                                                      value:
                                                                        "flex-start",
                                                                    },
                                                                    {
                                                                      minWidth:
                                                                        Breakpoints.Desktop,
                                                                      value:
                                                                        "flex-start",
                                                                    },
                                                                    {
                                                                      minWidth:
                                                                        Breakpoints.Laptop,
                                                                      value:
                                                                        "flex-start",
                                                                    },
                                                                  ],
                                                                paddingBottom: {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 0,
                                                                },
                                                                width: "100%",
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* sessions */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                name={
                                                                  "AntDesign/calendar"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                                size={14}
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={5}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {
                                                                  lXPListData?.up_session_count
                                                                }
                                                                {" sessions"}
                                                              </Text>
                                                            </View>
                                                            {/* type */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                size={14}
                                                                name={
                                                                  "Ionicons/ios-location-outline"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={4}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {getLXPType(
                                                                  lXPListData?.isvirtual
                                                                )}
                                                              </Text>
                                                            </View>
                                                            {/* ages */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                  marginRight: {
                                                                    minWidth:
                                                                      Breakpoints.Desktop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {"Ages "}
                                                                {
                                                                  lXPListData?.min_age
                                                                }
                                                                {"-"}
                                                                {
                                                                  lXPListData?.max_age
                                                                }{" "}
                                                              </Text>
                                                            </View>
                                                            {/* price */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value: 4,
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Icon
                                                                size={14}
                                                                name={
                                                                  "Feather/dollar-sign"
                                                                }
                                                                color={
                                                                  theme.colors[
                                                                    "dark gray text"
                                                                  ]
                                                                }
                                                              />
                                                              <Spacer
                                                                top={8}
                                                                bottom={8}
                                                                right={0}
                                                                left={4}
                                                              />
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      "Work Sans Regular"
                                                                    ],
                                                                    {
                                                                      color:
                                                                        theme
                                                                          .colors[
                                                                          "dark gray text"
                                                                        ],
                                                                      fontFamily:
                                                                        "System",
                                                                      fontSize: 14,
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight: 13,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                ellipsizeMode={
                                                                  "tail"
                                                                }
                                                              >
                                                                {getFinalPrice(
                                                                  Variables,
                                                                  lXPListData?.pricing_per_session
                                                                )}
                                                              </Text>
                                                            </View>
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                  </View>
                                                </Touchable>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                  </>
                                );
                              }}
                              data={searchableLxps}
                              listKey={"AJrSZm7v"}
                              keyExtractor={(lXPListData) => lXPListData?.id}
                              contentContainerStyle={StyleSheet.applyWidth(
                                {
                                  alignContent: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: "space-between",
                                  },
                                  flex: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 1,
                                  },
                                  flexShrink: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0,
                                  },
                                  maxWidth: {
                                    minWidth: Breakpoints.Laptop,
                                    value: "100%",
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  paddingRight: 0,
                                },
                                dimensions.width
                              )}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              horizontal={false}
                              numColumns={2}
                            />
                          )}
                        </>
                        {/* LXP List Mobile */}
                        <>
                          {dimensions.width >= Breakpoints.Tablet ? null : (
                            <FlatList
                              renderItem={({ item }) => {
                                const lXPListMobileData = item;
                                return (
                                  <>
                                    {/* Mobile */}
                                    <>
                                      {dimensions.width >=
                                      Breakpoints.Tablet ? null : (
                                        <View>
                                          <Touchable
                                            onPress={() => {
                                              try {
                                                navigation.navigate(
                                                  "LXPDetailsScreen",
                                                  {
                                                    lxp_id:
                                                      lXPListMobileData?.id,
                                                  }
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            style={StyleSheet.applyWidth(
                                              { marginBottom: 16 },
                                              dimensions.width
                                            )}
                                            activeOpacity={0.8}
                                            disabledOpacity={0.8}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: "center",
                                                  borderColor:
                                                    theme.colors["gray line"],
                                                  borderRadius: 8,
                                                  borderWidth: 1,
                                                  height: {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 240,
                                                  },
                                                  paddingBottom: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderRadius: 8,
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    height: 94,
                                                    overflow: "hidden",
                                                    width: "100%",
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      borderBottomLeftRadius: 0,
                                                      borderBottomRightRadius: 0,
                                                      borderTopLeftRadius: 8,
                                                      borderTopRightRadius: 8,
                                                      height: "100%",
                                                      width: "100%",
                                                    },
                                                    dimensions.width
                                                  )}
                                                  source={{
                                                    uri: `${lXPListMobileData?.coverimage}`,
                                                  }}
                                                  resizeMode={"cover"}
                                                />
                                                {/* starting card */}
                                                <>
                                                  {!lXPListMobileData?.upcoming_session ? null : (
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems:
                                                            "flex-end",
                                                          backgroundColor:
                                                            theme.colors[
                                                              "Background"
                                                            ],
                                                          borderRadius: 8,
                                                          bottom: 8,
                                                          flexDirection: "row",
                                                          left: 4,
                                                          padding: 8,
                                                          position: "absolute",
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        style={StyleSheet.applyWidth(
                                                          { marginBottom: 2 },
                                                          dimensions.width
                                                        )}
                                                        name={
                                                          "MaterialCommunityIcons/timer-sand-empty"
                                                        }
                                                        size={12}
                                                      />
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              "Work Sans Regular"
                                                            ],
                                                            {
                                                              color:
                                                                theme.colors
                                                                  .strong,
                                                              fontFamily:
                                                                "WorkSans_600SemiBold",
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {"Starts "}
                                                        {getDayDiff(
                                                          lXPListMobileData
                                                            ?.upcoming_session
                                                            ?.start_time
                                                        )}
                                                      </Text>
                                                    </View>
                                                  )}
                                                </>
                                              </View>
                                              <Spacer
                                                right={8}
                                                bottom={8}
                                                left={8}
                                                top={0}
                                              />
                                              {/* Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* title */}
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )["Inter Regular"],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            "Strong"
                                                          ],
                                                        fontFamily:
                                                          "Inter_700Bold",
                                                        fontSize: 16,
                                                        lineHeight: 15,
                                                        paddingBottom: 4,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  ellipsizeMode={"tail"}
                                                  numberOfLines={1}
                                                >
                                                  {lXPListMobileData?.title}
                                                </Text>
                                                <Spacer
                                                  right={8}
                                                  bottom={8}
                                                  left={8}
                                                  top={0}
                                                />
                                                {/* Meta */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: "center",
                                                      flexDirection: "row",
                                                      justifyContent:
                                                        "space-between",
                                                      marginBottom: {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 8,
                                                      },
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* author */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: "center",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Circle
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          overflow: {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: "hidden",
                                                          },
                                                        },
                                                        dimensions.width
                                                      )}
                                                      bgColor={
                                                        theme.colors.light
                                                      }
                                                      size={24}
                                                    >
                                                      <>
                                                        {!lXPListMobileData
                                                          ?.author
                                                          ?.profile_picture ? null : (
                                                          <Image
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                height: "100%",
                                                                width: "100%",
                                                              },
                                                              dimensions.width
                                                            )}
                                                            resizeMode={
                                                              "contain"
                                                            }
                                                            source={{
                                                              uri: `${lXPListMobileData?.author?.profile_picture}`,
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                      <>
                                                        {lXPListMobileData
                                                          ?.author
                                                          ?.profile_picture ? null : (
                                                          <Icon
                                                            size={24}
                                                            name={
                                                              "Ionicons/person"
                                                            }
                                                            color={
                                                              theme.colors[
                                                                "dark gray text"
                                                              ]
                                                            }
                                                          />
                                                        )}
                                                      </>
                                                    </Circle>
                                                    {/* full name */}
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            theme.colors.strong,
                                                          marginLeft: 10,
                                                        },
                                                        dimensions.width
                                                      )}
                                                      adjustsFontSizeToFit={
                                                        true
                                                      }
                                                      numberOfLines={1}
                                                      ellipsizeMode={"tail"}
                                                    >
                                                      {
                                                        lXPListMobileData
                                                          ?.author?.firstName
                                                      }{" "}
                                                      {
                                                        lXPListMobileData
                                                          ?.author?.lastName
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Starting */}
                                                  <>
                                                    {!lXPListMobileData?.upcoming_session ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems: {
                                                              minWidth:
                                                                Breakpoints.Laptop,
                                                              value: "flex-end",
                                                            },
                                                            flex: 1,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Text
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                theme.colors
                                                                  .strong,
                                                            },
                                                            dimensions.width
                                                          )}
                                                          adjustsFontSizeToFit={
                                                            true
                                                          }
                                                          numberOfLines={1}
                                                          ellipsizeMode={"tail"}
                                                        >
                                                          {dateFormatting(
                                                            lXPListMobileData
                                                              ?.upcoming_session
                                                              ?.start_time,
                                                            "Do MMM HH:mm"
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                                {/* description */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      marginTop: 8,
                                                      overflow: "hidden",
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* description */}
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors[
                                                            "dark gray text"
                                                          ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    numberOfLines={2}
                                                    ellipsizeMode={"tail"}
                                                  >
                                                    {
                                                      lXPListMobileData?.description
                                                    }
                                                  </Text>
                                                </View>
                                                <Spacer
                                                  right={8}
                                                  left={8}
                                                  top={0}
                                                  bottom={14}
                                                />
                                                {/* Quick Info */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: "center",
                                                      flex: {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 1,
                                                      },
                                                      flexDirection: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: "row",
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: "row",
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value: "row",
                                                        },
                                                      ],
                                                      justifyContent: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value:
                                                            "space-between",
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: "flex-start",
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: "flex-start",
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: "flex-start",
                                                        },
                                                      ],
                                                      paddingBottom: {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 0,
                                                      },
                                                      width: "100%",
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* sessions */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        marginLeft: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 4,
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Icon
                                                      name={
                                                        "AntDesign/calendar"
                                                      }
                                                      color={
                                                        theme.colors[
                                                          "dark gray text"
                                                        ]
                                                      }
                                                      size={14}
                                                    />
                                                    <Spacer
                                                      top={8}
                                                      bottom={8}
                                                      right={0}
                                                      left={5}
                                                    />
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            "Work Sans Regular"
                                                          ],
                                                          {
                                                            color:
                                                              theme.colors[
                                                                "dark gray text"
                                                              ],
                                                            fontFamily:
                                                              "System",
                                                            fontSize: 14,
                                                            fontWeight: "400",
                                                            lineHeight: 13,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                      numberOfLines={1}
                                                      ellipsizeMode={"tail"}
                                                    >
                                                      {
                                                        lXPListMobileData?.up_session_count
                                                      }
                                                      {" sessions"}
                                                    </Text>
                                                  </View>
                                                  {/* type */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        marginLeft: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 4,
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Icon
                                                      size={14}
                                                      name={
                                                        "Ionicons/ios-location-outline"
                                                      }
                                                      color={
                                                        theme.colors[
                                                          "dark gray text"
                                                        ]
                                                      }
                                                    />
                                                    <Spacer
                                                      top={8}
                                                      bottom={8}
                                                      right={0}
                                                      left={4}
                                                    />
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            "Work Sans Regular"
                                                          ],
                                                          {
                                                            color:
                                                              theme.colors[
                                                                "dark gray text"
                                                              ],
                                                            fontFamily:
                                                              "System",
                                                            fontSize: 14,
                                                            fontWeight: "400",
                                                            lineHeight: 13,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                      numberOfLines={1}
                                                      ellipsizeMode={"tail"}
                                                    >
                                                      {getLXPType(
                                                        lXPListMobileData?.isvirtual
                                                      )}
                                                    </Text>
                                                  </View>
                                                  {/* ages */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        marginLeft: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 4,
                                                        },
                                                        marginRight: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 4,
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            "Work Sans Regular"
                                                          ],
                                                          {
                                                            color:
                                                              theme.colors[
                                                                "dark gray text"
                                                              ],
                                                            fontFamily:
                                                              "System",
                                                            fontSize: 14,
                                                            fontWeight: "400",
                                                            lineHeight: 13,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                      numberOfLines={1}
                                                      ellipsizeMode={"tail"}
                                                    >
                                                      {"Ages "}
                                                      {
                                                        lXPListMobileData?.min_age
                                                      }
                                                      {"-"}
                                                      {
                                                        lXPListMobileData?.max_age
                                                      }{" "}
                                                    </Text>
                                                  </View>
                                                  {/* price */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        marginLeft: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 4,
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Icon
                                                      size={14}
                                                      name={
                                                        "Feather/dollar-sign"
                                                      }
                                                      color={
                                                        theme.colors[
                                                          "dark gray text"
                                                        ]
                                                      }
                                                    />
                                                    <Spacer
                                                      top={8}
                                                      bottom={8}
                                                      right={0}
                                                      left={4}
                                                    />
                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            "Work Sans Regular"
                                                          ],
                                                          {
                                                            color:
                                                              theme.colors[
                                                                "dark gray text"
                                                              ],
                                                            fontFamily:
                                                              "System",
                                                            fontSize: 14,
                                                            fontWeight: "400",
                                                            lineHeight: 13,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                      numberOfLines={1}
                                                      ellipsizeMode={"tail"}
                                                    >
                                                      {getFinalPrice(
                                                        Variables,
                                                        lXPListMobileData?.pricing_per_session
                                                      )}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      )}
                                    </>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                  </>
                                );
                              }}
                              data={searchableLxps}
                              listKey={"4Tns1avv"}
                              keyExtractor={(lXPListMobileData) =>
                                lXPListMobileData?.id
                              }
                              contentContainerStyle={StyleSheet.applyWidth(
                                {
                                  flex: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 1,
                                  },
                                  flexShrink: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0,
                                  },
                                  marginLeft: 10,
                                  maxWidth: {
                                    minWidth: Breakpoints.Laptop,
                                    value: "100%",
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 10,
                                  },
                                  paddingRight: 10,
                                },
                                dimensions.width
                              )}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              horizontal={false}
                              numColumns={1}
                            />
                          )}
                        </>
                      </>
                    );
                  }}
                </PublicSchemaApi.FetchGetLXPSGET>
              </View>
              {/* Map */}
              <>
                {!showMapDiv(dimensions.width >= Breakpoints.Tablet) ? null : (
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <MapView
                          renderItem={({ item }) => {
                            const mapViewData = item;
                            return (
                              <MapMarker
                                longitude={mapViewData?.longitude}
                                title={mapViewData?.name}
                                description={mapViewData?.opening_hours}
                                latitude={mapViewData?.latitude}
                              >
                                {/* Map Callout 2 */}
                                <MapCallout showTooltip={true}>
                                  <View>
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            "Text"
                                          ],
                                          {
                                            fontFamily: "System",
                                            fontSize: 18,
                                            fontWeight: "700",
                                            marginBottom: 8,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {mapViewData?.name}
                                    </Text>

                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            "Text"
                                          ],
                                          {
                                            fontFamily: "System",
                                            fontWeight: "400",
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {mapViewData?.address}
                                    </Text>
                                    <Spacer
                                      right={8}
                                      left={8}
                                      bottom={8}
                                      top={8}
                                    />
                                    {/* View Location */}
                                    <Button
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            "LearningCentreDetailsScreen",
                                            {
                                              learning_centre_id:
                                                mapViewData?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ButtonStyles(theme)[
                                          "Button"
                                        ],
                                        dimensions.width
                                      )}
                                      title={"View Schedule"}
                                    />
                                  </View>
                                </MapCallout>
                              </MapMarker>
                            );
                          }}
                          markersData={applyCityFilter(learningCenters)}
                          listKey={"JDdaTq3V"}
                          keyExtractor={(mapViewData) =>
                            mapViewData?.id ||
                            mapViewData?.uuid ||
                            JSON.stringify(mapViewData)
                          }
                          style={StyleSheet.applyWidth(
                            GlobalStyles.MapViewStyles(theme)["Map View"],
                            dimensions.width
                          )}
                          latitude={centerLat}
                          longitude={centerLong}
                          zoomEnabled={true}
                          scrollEnabled={true}
                          loadingEnabled={true}
                          showsPointsOfInterest={true}
                          apiKey={"AIzaSyAfUyq3Ia5ePhsqBo4m_w08J90Cd74XLdg"}
                          rotateEnabled={false}
                          zoom={9}
                          mapType={"none"}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
      </View>
      {/* Modal */}
      <>
        {!showDateFilter ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",
                backgroundColor: theme.colors["Modal Overlay"],
                height: "100%",
                justifyContent: "center",
                left: 0,
                position: "absolute",
                top: 0,
                width: "100%",
              },
              dimensions.width
            )}
          >
            {/* Body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors["Background"],
                  borderRadius: 8,
                  height: 260,
                  justifyContent: "center",
                  padding: 12,
                  width: 400,
                },
                dimensions.width
              )}
            >
              {/* Time slider */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  },
                  dimensions.width
                )}
              >
                {/* Start time */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center", flexDirection: "row" },
                    dimensions.width
                  )}
                >
                  {/* Start Time */}
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors.strong },
                      dimensions.width
                    )}
                  >
                    {"Start Time: "}
                  </Text>
                  {/* container */}
                  <View
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)["container style"],
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong },
                        dimensions.width
                      )}
                    >
                      {dateFormatting(startTime, "HH:mm")}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          height: "100%",
                          left: 0,
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          width: "100%",
                        },
                        dimensions.width
                      )}
                    >
                      <DatePicker
                        onDateChange={(newDatePickerValue) => {
                          try {
                            setStartTime(newDatePickerValue);
                            applyStartTimeFilter(newDatePickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: '"rgba(0, 0, 0, 0)"',
                            color: theme.colors["Light"],
                            height: "100%",
                            width: "100%",
                          },
                          dimensions.width
                        )}
                        date={startTime}
                        labelSize={0}
                        label={""}
                        leftIconMode={"inset"}
                        type={"solid"}
                        mode={"time"}
                        labelColor={'"rgba(0, 0, 0, 0)"'}
                        borderColor={'"rgba(0, 0, 0, 0)"'}
                        borderColorActive={'"rgba(0, 0, 0, 0)"'}
                        format={"HH:MM"}
                      />
                    </View>
                  </View>
                </View>
                {/* End time */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center", flexDirection: "row" },
                    dimensions.width
                  )}
                >
                  {/* End Time */}
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors.strong },
                      dimensions.width
                    )}
                  >
                    {"End Time: "}
                  </Text>
                  {/* container */}
                  <View
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)["container style"],
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong },
                        dimensions.width
                      )}
                    >
                      {dateFormatting(endTime, "HH:mm")}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          height: "100%",
                          left: 0,
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          width: "100%",
                        },
                        dimensions.width
                      )}
                    >
                      <DatePicker
                        onDateChange={(newDatePickerValue) => {
                          try {
                            setEndTime(newDatePickerValue);
                            applyEndTimeFilter(newDatePickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: '"rgba(0, 0, 0, 0)"',
                            color: theme.colors["Light"],
                            height: "100%",
                            width: "100%",
                          },
                          dimensions.width
                        )}
                        date={endTime}
                        labelSize={0}
                        label={""}
                        leftIconMode={"inset"}
                        type={"solid"}
                        mode={"time"}
                        labelColor={'"rgba(0, 0, 0, 0)"'}
                        borderColor={'"rgba(0, 0, 0, 0)"'}
                        borderColorActive={'"rgba(0, 0, 0, 0)"'}
                        format={"HH:MM"}
                      />
                    </View>
                  </View>
                </View>
              </View>
              {/* Next 7 Days */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 8,
                    paddingLeft: 4,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                <Checkbox
                  onPress={(newCheckboxValue) => {
                    try {
                      setDateRange(7);
                      applyDateFilter(7);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={dateRange === 7}
                />
                <Spacer top={8} bottom={8} left={8} right={0} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)["Inter Regular"],
                      {
                        color: theme.colors.strong,
                        fontFamily: "Inter_500Medium",
                        lineHeight: 17,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {"Next 7 Days"}
                </Text>
              </View>
              {/* Next 14 Days */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 8,
                    paddingLeft: 4,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                <Checkbox
                  onPress={(newCheckboxValue) => {
                    try {
                      setDateRange(14);
                      applyDateFilter(14);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={dateRange === 14}
                />
                <Spacer top={8} bottom={8} left={8} right={0} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)["Inter Regular"],
                      {
                        color: theme.colors.strong,
                        fontFamily: "Inter_500Medium",
                        lineHeight: 17,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {"Next 14 Days"}
                </Text>
              </View>
              {/* All */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: 8,
                    paddingLeft: 4,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                <Checkbox
                  onPress={(newCheckboxValue) => {
                    try {
                      setDateRange(-1);
                      applyDateFilter(-1);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  defaultValue={dateRange === -1}
                />
                <Spacer top={8} bottom={8} left={8} right={0} />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)["Inter Regular"],
                      {
                        color: theme.colors.strong,
                        fontFamily: "Inter_500Medium",
                        lineHeight: 17,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {"All Upcoming Dates"}
                </Text>
              </View>
              <Spacer top={8} right={8} bottom={8} left={8} />
              {/* Action Row */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  },
                  dimensions.width
                )}
              >
                {/* close */}
                <Button
                  onPress={() => {
                    try {
                      setShowDateFilter(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors.primary,
                      borderRadius: 8,
                      fontFamily: "System",
                      fontWeight: "700",
                      textAlign: "center",
                      width: 100,
                    },
                    dimensions.width
                  )}
                  title={"Close"}
                />
              </View>
            </View>
          </View>
        )}
      </>
      {/* BottomTab */}
      <>
        {dimensions.width >= Breakpoints.Tablet ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",
                backgroundColor: theme.colors["Background"],
                flexDirection: "row",
                justifyContent: "space-around",
                paddingBottom: 10,
                paddingTop: 17,
              },
              dimensions.width
            )}
          >
            {/* Discover */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate("DiscoverScreen");
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center" },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={"Feather/grid"} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: "center",
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"Discover"}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Locations */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center" },
                    dimensions.width
                  )}
                >
                  <Icon name={"Ionicons/md-location-outline"} size={26} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: "center",
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"Locations"}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Experiences */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center" },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)["Image"],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    source={Images.CASLogoBlue}
                    resizeMode={"cover"}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],
                        {
                          color: theme.colors["CAS Blue"],
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: "center",
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"Experiences"}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Checkin */}
            <View>
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        "https://ark.cityasaschool.com/"
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center" },
                    dimensions.width
                  )}
                >
                  <Icon name={"MaterialIcons/auto-awesome"} size={26} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: "center",
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"AI Copilot"}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Profile */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate("ProfileScreen");
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center" },
                    dimensions.width
                  )}
                >
                  <Icon name={"MaterialIcons/person-outline"} size={28} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: "center",
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"Profile"}
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ExploreScreen);
