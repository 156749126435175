import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const LoginScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const checkRedirectPath = path => {
    if (path) {
      props.navigation.navigate(path);
      return true;
    }
    return false;
  };

  const isLearner = type => {
    if (!type) {
      return true;
    } else if (type !== 'learner') {
      setError('Invalid attempt!');
      return false;
    }

    return true;
  };

  const isLoginDisabled = () => {
    return !(emailInput.length && passwordInput.length);
  };

  const checkPurchasePending = cart => {
    if (cart.length) {
      // quick purchase flow
      props.navigation.navigate('PurchaseProcessingScreen');
      return true;
    }

    return false;
  };

  const isDeletedAccount = response => {
    // console.log(response?.user?.user_metadata?.deleted)
    if (response?.user?.user_metadata?.deleted) {
      setIsDeleted(true);
      return true;
    } else return false;
  };

  const { theme } = props;
  const { navigation } = props;

  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [emailInput, setEmailInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordInput, setPasswordInput] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [radioButtonGroupValue2, setRadioButtonGroupValue2] =
    React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchableLxps, setSearchableLxps] = React.useState([]);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [topic, setTopic] = React.useState('Arts');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row', height: 72 },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    flexDirection: 'row',
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Primary'],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.Group}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Inactive Gray Text'],
                    flex: 1,
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <View />
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Nav 4'],
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://ark.cityasaschool.com/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['ARK 3'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialIcons/auto-awesome'}
                        size={18}
                        color={theme.colors['Strong']}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'AI Copilot'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover Nav */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DiscoverScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['Discover Nav 7'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/grid'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Discover'}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/search'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontSize: 16,
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Experiences'}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} left={16} right={16} />
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: 'row' },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Sidebar'],
                  dimensions.width
                )}
              >
                <Spacer left={8} top={8} right={8} bottom={8} />
              </View>
            )}
          </>
          {/* Scroll View Body */}
          <ScrollView
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            {/* Body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Background'],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  flex: 1,
                  paddingBottom: 32,
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 32,
                },
                dimensions.width
              )}
            >
              {/* Central Profile */}
              <View
                style={StyleSheet.applyWidth({ width: 360 }, dimensions.width)}
              >
                {/* Profile Details */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Profile Picture */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingBottom: 32,
                        paddingLeft: 14,
                        paddingTop: 10,
                        width: 360,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontSize: 24,
                          fontWeight: '600',
                        },
                        dimensions.width
                      )}
                    >
                      {'Welcome Back'}
                    </Text>

                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong, marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      {'Log in to your account to continue'}
                    </Text>
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  {/* Email */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Email */}
                      <Text
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'input_fieldname_text'
                          ],
                          dimensions.width
                        )}
                      >
                        {'Email'}
                      </Text>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setEmailInput(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)['input_field'],
                          dimensions.width
                        )}
                        value={emailInput}
                        placeholder={' '}
                        autoCapitalize={'none'}
                        textContentType={'emailAddress'}
                        keyboardType={'email-address'}
                      />
                    </View>
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  {/* Password */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Password */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['dark gray text'],
                            fontFamily: 'System',
                            fontSize: 16,
                            fontWeight: '400',
                            marginBottom: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {'Password'}
                      </Text>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setPasswordInput(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)['input_field'],
                          dimensions.width
                        )}
                        value={passwordInput}
                        placeholder={' '}
                        autoCapitalize={'none'}
                        maxLength={280}
                        multiline={false}
                        numberOfLines={1}
                        secureTextEntry={true}
                      />
                      <Spacer top={8} right={8} bottom={8} left={8} />
                    </View>
                  </View>
                  {/* Deleted Account */}
                  <>
                    {!isDeleted ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error'], marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {'Account was deleted!'}
                      </Text>
                    )}
                  </>
                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors['Error'], marginTop: 8 },
                      dimensions.width
                    )}
                  >
                    {error}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('RequestPasswordScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.8}
                      disabledOpacity={0.8}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'System',
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Forgot your password?'}
                      </Text>
                    </Touchable>
                  </View>
                  <Spacer right={8} left={8} top={16} bottom={16} />
                  {/* Login */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setIsLoading(true);
                          const loginRes = (
                            await SupabaseAuthApi.emailLoginPOST(Constants, {
                              email: emailInput,
                              password: passwordInput,
                            })
                          )?.json;
                          setIsLoading(false);
                          const token = loginRes?.access_token;
                          const err = loginRes?.error_description;
                          setError(err);
                          if (isDeletedAccount(loginRes)) {
                            return;
                          }
                          if (err) {
                            return;
                          }
                          const bearerToken = setGlobalVariableValue({
                            key: 'AUTH_HEADER_CAAS',
                            value: 'Bearer ' + token,
                          });
                          const authUUID = setGlobalVariableValue({
                            key: 'AUTH_UUID_CAAS',
                            value: loginRes?.user?.id,
                          });
                          const account_type =
                            loginRes?.user.user_metadata.provider;
                          if (!isLearner(account_type)) {
                            return;
                          }
                          const profileRes = (
                            await PublicSchemaApi.getUserProfileByIdGET(
                              Constants,
                              { user_id: authUUID }
                            )
                          )?.json;
                          setGlobalVariableValue({
                            key: 'AUTH_PROFILE_CAAS',
                            value: profileRes && profileRes[0],
                          });
                          if (checkPurchasePending(Constants['WAITING_CART'])) {
                            return;
                          }
                          navigation.navigate('ProfileScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['button_solid'],
                      dimensions.width
                    )}
                    disabled={isLoginDisabled()}
                    loading={isLoading}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                    title={'Log In'}
                  />
                </View>
                {/* Help */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontSize: 14,
                          fontWeight: '400',
                          marginRight: 8,
                        },
                        dimensions.width
                      )}
                    >
                      {"Don't have an account?"}
                    </Text>

                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('QuickCreateAccountScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.8}
                      disabledOpacity={0.8}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'System',
                            fontWeight: '400',
                          },
                          dimensions.width
                        )}
                      >
                        {'Create Account'}
                      </Text>
                    </Touchable>
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  {/* T&Cs */}
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontWeight: '400',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'By clicking Sign up, you are agreeing to City as a School’s Terms of Service, including our Code of Conduct, and our Privacy Policy.'
                      }
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
