import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const getUpcommingSeries = seriesArr => {
  // add a conditional stop before this if lxp is one time
  const isUpcomingDate = dateObj =>
    CustomPackages.moment(dateObj).diff(CustomPackages.moment(), 'seconds') > 0;
  if (seriesArr) {
    // let ret = seriesArr.filter(
    //     series=> series.sessions.some(
    //         ({start_time})=> isUpcomingDate(start_time)
    //     )
    // )
    let sortedSeries = seriesArr.map(ser => {
      ser.sessions.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
      return ser;
    });
    sortedSeries.sort(
      (serA, serB) =>
        new Date(serA.sessions[0].start_time).getTime() -
        new Date(serB.sessions[0].start_time).getTime()
    );
    // ret.sort(
    //     (a,b)=> new Date(a.upcoming_sesson?.start_time).getTime() - new Date(b.upcoming_sesson?.start_time).getTime()
    // )
    return seriesArr;
  }
  return [];
};

export default getUpcommingSeries;
