import React from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as PublicSchemaApi from "../apis/PublicSchemaApi.js";
import * as SupabaseEdgeFunctionApi from "../apis/SupabaseEdgeFunctionApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import Images from "../config/Images";
import * as CustomPackages from "../custom-files/CustomPackages";
import getDayDiff from "../global-functions/getDayDiff";
import getFinalPrice from "../global-functions/getFinalPrice";
import getLearningTypeLabel from "../global-functions/getLearningTypeLabel";
import getTimezonOffset from "../global-functions/getTimezonOffset";
import getUpcomingSessions from "../global-functions/getUpcomingSessions";
import getUpcommingSeries from "../global-functions/getUpcommingSeries";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import { MapMarker, MapView } from "@draftbit/maps";
import {
  Button,
  Circle,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  StarRating,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from "@draftbit/ui";
import { useIsFocused } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Fetch } from "react-request";

const LearningCentreDetailsScreen = (props) => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const createIntentObj = (
    setGlobalVariableValue,
    seller_email,
    seller_fullname,
    seller_id,
    final_price,
    actual_price,
    sessions,
    lxp_title,
    lxp_id,
    join_url,
    meeting_id,
    meeting_password,
    start_time,
    username,
    maxPart,
    count
  ) => {
    let paymentIntet = {
      seller: {
        email: seller_email,
        fullName: seller_fullname,
        id: seller_id,
      },
      metadata: {
        final_price: final_price,
        actual_price: actual_price,
        sessions: sessions,
        lxp_title: lxp_title,
        lxp_id: lxp_id,
        join_url: join_url,
        meeting_id: meeting_id,
        meeting_password: meeting_password,
        upcoming_start_time: start_time,
        learner_username: usernam,
        max_participants: maxPart,
        enrolled_count: count,
      },
    };

    setGlobalVariableValue({
      key: "INTENT_OBJ",
      value: paymentIntet,
    });
  };

  const formatOpeningHours = (hours) => {
    // console.log(hours)
    return hours ? hours.split("; ").map((item) => item.split(".")[0]) : [];
  };

  const isUpcomingDate = (dateObj) => {
    return (
      CustomPackages.moment(dateObj).diff(CustomPackages.moment(), "seconds") >
      0
    );
  };

  const applySearch = (query) => {
    if (query.length < 3) return;

    setSearchableLxps(
      allLxps.filer((item) => {
        return (
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.description.toLowerCase().includes(query.toLowerCase())
        );
      })
    );
  };

  const setupUpcomingSeries = (seriesArr) => {
    // add a conditional stop before this if lxp is one time
    setUpcomingSeries(
      seriesArr.filter((series) =>
        series.sessions.some(({ start_time }) => isUpcomingDate(start_time))
      )
    );
  };

  const inCart = (sessionId) => {
    // console.log('in cart', sessionCart, sessionId)

    return sessionCart.includes(sessionId);
  };

  const isEnrolledFor = (Variables, enrolled_ids) => {
    return (
      Variables.AUTH_UUID &&
      enrolled_ids &&
      enrolled_ids.includes(Variables.AUTH_UUID)
    );
  };

  const unselectSeries = (seriesItem) => {
    setSessionCart((old) =>
      old.filter(
        (ses_id) => !seriesItem.sessions.find(({ id }) => id === ses_id)
      )
    );
  };

  const addWholeSeries = (Variables, item) => {
    item.sessions.map((sess) => addToCart(Variables, sess));
  };

  const addToCart = (Variables, item) => {
    console.log("add to cart", item);
    const { enrolled_ids } = item;
    if (!enrolled_ids || !enrolled_ids.includes(Variables.AUTH_UUID_CAAS))
      setSessionCart((old) => [...old, item.id]);
  };

  const removeFromCart = (item) => {
    setSessionCart((old) => old.filter((id) => id !== item.id));
  };

  const selectedWholeSeries = (Variables, seriesItem) => {
    return (
      sessionCart.length &&
      seriesItem.sessions
        .filter(
          ({ enrolled_ids }) =>
            !enrolled_ids || !enrolled_ids.includes(Variables.AUTH_UUID_CAAS)
        )
        .every(({ id }) => sessionCart.includes(id))
    );
  };

  const toggleSessionCheckbox = (Variables, sessionItem) => {
    console.log("toggle", sessionItem, sessionCart);
    if (inCart(sessionItem.id)) {
      console.log("removed");
      removeFromCart(sessionItem);
    } else {
      console.log("add");
      addToCart(Variables, sessionItem);
    }
  };

  const showAvailableTimesSection = () => {
    // console.log(upcomingSeries.length, upcomingSessions.length)

    return upcomingSeries.length > 0 || upcomingSessions.length > 0;
  };

  const isJoinable = (Variables, item) => {
    return !inCart(item.id) && !isEnrolledFor(Variables, item.enrolled_ids);
  };

  const getMapviewData = () => {
    return [learningCenter];
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIsLoading(true);
        const res = (
          await PublicSchemaApi.getLearningCenterDetailsGET(Constants, {
            id:
              props.route?.params?.learning_centre_id ??
              "95391261-84551292-300906-371128-61514327689",
          })
        )?.json;
        setLearningCenter(res && res[0]);
        console.log(
          props.route?.params?.learning_centre_id ??
            "95391261-84551292-300906-371128-61514327689"
        );
        const center_lxps = (
          await PublicSchemaApi.getLearningExperiencesForLearningCenterPOST(
            Constants,
            {
              id:
                props.route?.params?.learning_centre_id ??
                "95391261-84551292-300906-371128-61514327689",
            }
          )
        )?.json;
        console.log(center_lxps);
        setLxps(center_lxps);
        const googlePlaceImages = (
          await SupabaseEdgeFunctionApi.getImagesForLearningCenterPOST(
            Constants,
            { name: res && res[0].name, size: 600 }
          )
        )?.json;
        setIsLoading(false);
        setGlobalVariableValue({
          key: "imageSilder",
          value: googlePlaceImages,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowingMore, setIsShowingMore] = React.useState(false);
  const [learningCenter, setLearningCenter] = React.useState({});
  const [lxpItem, setLxpItem] = React.useState({});
  const [lxps, setLxps] = React.useState([]);
  const [pastSessionCount, setPastSessionCount] = React.useState(0);
  const [sessionCart, setSessionCart] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [showingSeriesId, setShowingSeriesId] = React.useState(-1);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const [upcomingLxps, setUpcomingLxps] = React.useState([
    {
      id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
      title: "Animation Club - January",
      author: {
        id: "d5927566-a72b-44ac-9d02-317f502c043f",
        email: "svet.e.georgiev@gmail.com",
        handle: "2D animation and game design teacher and facilitator ",
        user_id: "8be0a8b9-ae79-4be9-8c12-be9c5bb7a306",
        about_me:
          "Svetoslav “Svet” Georgiev grew up in a small town in northern Bulgaria. When he was a kid, he had an enormous passion for cartoons and wanted to learn how they were made. He created tons of flipbooks and filled all his notebooks with his favorite characters until one day when he realized this could be his dream job. \n\nWith a passion for self-directed learning, he has been using his experience and knowledge from his Master’s degree in 2D Animation to teach students of all ages all around the world.\n\nHe has been involved in many projects, including 2D animation for commercials and education, music videos, visual fx, motion graphics, and video games for companies worldwide. \n\nHe enjoys spending time with his family, playing music, playing games, animating, reading books, and traveling.\n",
        lastName: "Georgiev ",
        location: "Veliko Tarnovo, Bulgaria",
        firstName: "Svet ",
        short_bio:
          "Animation and game design have always been my passion. I'm glad I can now share my love and knowledge with curious minds worldwide.\n",
        created_at: "2022-12-31T08:38:44.635399+00:00",
        updated_at: "2022-12-31T08:38:44.635399+00:00",
        is_verified: false,
        profile_picture:
          "https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/providers/providers1672991956814.jpeg",
      },
      series: [
        {
          sessions: [
            {
              id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
              lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
              duration: 50,
              series_no: 3,
              created_at: "2023-01-18T13:14:13.370983+00:00",
              start_time: "2023-01-31T18:00:00+00:00",
              updated_at: "2023-01-18T13:14:13.559+00:00",
              enrolled_ids: null,
              enrolled_count: 0,
            },
          ],
          series_no: 3,
        },
        {
          sessions: [
            {
              id: "95863d35-3472-4b9b-87c8-9c971450ccd7",
              lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
              duration: 50,
              series_no: 2,
              created_at: "2023-01-18T13:14:13.370983+00:00",
              start_time: "2023-01-24T18:00:00+00:00",
              updated_at: "2023-01-18T13:14:13.559+00:00",
              enrolled_ids: null,
              enrolled_count: 0,
            },
          ],
          series_no: 2,
        },
        {
          sessions: [
            {
              id: "a31ef858-1a1e-4576-be6b-18c0bee7f924",
              lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
              duration: 50,
              series_no: 1,
              created_at: "2023-01-18T13:14:13.370983+00:00",
              start_time: "2023-01-17T18:00:00+00:00",
              updated_at: "2023-01-18T13:14:13.559+00:00",
              enrolled_ids: null,
              enrolled_count: 0,
            },
          ],
          series_no: 1,
        },
      ],
      status: "active",
      max_age: 14,
      min_age: 10,
      subject: "Arts",
      zoom_id: "972 5714 0586",
      archived: false,
      zoom_url:
        "https://zoom.us/j/97257140586?pwd=eDAwci8rekNWQ0hoTTkwNDlxNFNFdz09",
      author_id: "8be0a8b9-ae79-4be9-8c12-be9c5bb7a306",
      isOneTime: false,
      isvirtual: true,
      coverimage:
        "https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/lxpcovers/lxpcovers1674047652180.png",
      created_at: "2023-01-18T13:14:11.645329+00:00",
      difficulty: 1,
      updated_at: "2023-01-18T13:14:11.645329+00:00",
      description:
        "The Animation Club is where students can meet and share their interests in shows, movies, animators, and studios in a friendly, positive environment filled with like-minded people. In addition to introducing shows and animation to each other, we will hold discussions about series analysis, in-depth episode analysis, and analyze the fandoms surrounding animated shows. Students will be given the opportunity to share their art and creations pertaining to animations.\n\nThe objective of our club is to share our love of animation. We look at what lies beyond the superficial surface of these animations and share our artistic creations and those from the surrounding fandoms to educate and inspire others.\n\nWe will also learn about different software and animation styles. The students have the chance to express their ideas in one of the most creative ways through animations. \n\nNo experience is necessary. We are open to all ages and animation levels.",
      zoom_password: "515253",
      max_participants: 5,
      min_participants: 1,
      up_session_count: 1,
      upcoming_session: {
        id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
        lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
        duration: 50,
        series_no: 3,
        created_at: "2023-01-18T13:14:13.370983+00:00",
        start_time: "2023-01-31T18:00:00+00:00",
        updated_at: "2023-01-18T13:14:13.559+00:00",
        enrolled_count: 0,
      },
      pricing_per_series: 45,
      required_resources: "",
      pricing_per_session: 15,
    },
  ]);
  const [upcomingSeries, setUpcomingSeries] = React.useState([
    {
      sessions: [
        {
          id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
          lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
          duration: 50,
          series_no: 3,
          created_at: "2023-01-18T13:14:13.370983+00:00",
          start_time: "2023-01-31T18:00:00+00:00",
          updated_at: "2023-01-18T13:14:13.559+00:00",
          enrolled_ids: null,
          enrolled_count: 0,
        },
      ],
      series_no: 3,
    },
  ]);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ height: "100%" }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Tablet) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: "center", flexDirection: "row", height: 72 },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    borderColor: theme.colors["Inactive Gray Text"],
                    borderRightWidth: 1,
                    flexDirection: "row",
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors["Primary"],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate("ExploreScreen");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={"cover"}
                      source={Images.Group}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    borderBottomWidth: 1,
                    borderColor: theme.colors["Inactive Gray Text"],
                    flex: 1,
                    flexDirection: "row",
                    height: "100%",
                    justifyContent: "space-between",
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)["Work Sans Regular"],
                      {
                        fontFamily: "Questrial_400Regular",
                        fontSize: 24,
                        letterSpacing: 0.2,
                        lineHeight: 20,
                        textTransform: "uppercase",
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {"city as a school"}
                </Text>
                {/* Left */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                    },
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            "https://ark.cityasaschool.com/"
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)["ARK 4"],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Ark */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "center",
                          flexDirection: "row",
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={"MaterialIcons/auto-awesome"}
                        size={18}
                        color={theme.colors["Strong"]}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"AI Copilot"}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: "center", flexDirection: "row" },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={"Feather/grid"}
                        size={18}
                        color={theme.colors["Strong"]}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Discover"}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer left={8} right={8} top={8} bottom={8} />
                  <Touchable
                    onPress={() => {
                      try {
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Experiences */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: "center", flexDirection: "row" },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors["Strong"]}
                        name={"Feather/search"}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Inter Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Experiences"}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} left={16} right={16} />
                  <Touchable activeOpacity={0.8} disabledOpacity={0.8}>
                    {/* Join */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: "center", flexDirection: "row" },
                        dimensions.width
                      )}
                    >
                      <Circle size={24} bgColor={theme.colors["Primary"]} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)["Work Sans Regular"],
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 16,
                              fontWeight: "400",
                              lineHeight: 19,
                              marginLeft: 12,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Join"}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: "row", justifyContent: "center" },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Tablet) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors["Inactive Gray Text"],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
            )}
          </>
          {/* Body */}
          <ScrollView
            style={StyleSheet.applyWidth(
              { height: "100%", width: "100%" },
              dimensions.width
            )}
            contentContainerStyle={StyleSheet.applyWidth(
              {
                alignContent: "flex-start",
                alignItems: "center",
                alignSelf: "center",
                paddingLeft: 10,
                paddingTop: 40,
              },
              dimensions.width
            )}
            showsVerticalScrollIndicator={true}
            bounces={true}
            showsHorizontalScrollIndicator={false}
          >
            {/* Centre Frame */}
            <>
              {isLoading ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: "stretch",
                      flex: 1,
                      width: [
                        { minWidth: Breakpoints.Tablet, value: 960 },
                        { minWidth: Breakpoints.Laptop, value: 800 },
                        { minWidth: Breakpoints.Desktop, value: 960 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Hero */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flex: { minWidth: Breakpoints.Desktop, value: 1 },
                        flexDirection: "row",
                        justifyContent: "center",
                      },
                      dimensions.width
                    )}
                  >
                    {/* Hero Left */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          justifyContent: "space-between",
                          paddingRight: 20,
                          width: [
                            { minWidth: Breakpoints.Tablet, value: "70%" },
                            { minWidth: Breakpoints.Laptop, value: "80%" },
                            { minWidth: Breakpoints.Desktop, value: "100%" },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Top */}
                      <View>
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: "System",
                              fontSize: 30,
                              fontWeight: "600",
                            },
                            dimensions.width
                          )}
                        >
                          {learningCenter?.name}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Tablet,
                                value: "row",
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Tablet,
                                value: "space-between",
                              },
                              marginTop: {
                                minWidth: Breakpoints.Tablet,
                                value: 16,
                              },
                              width: {
                                minWidth: Breakpoints.Tablet,
                                value: "100%",
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Reviews */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: "row",
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!learningCenter?.rating ? null : (
                                <StarRating
                                  onPress={(newStarRatingValue) => {
                                    try {
                                      setLearningCenter(newStarRatingValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  rating={(() => {
                                    const e = learningCenter?.rating;
                                    console.log(e);
                                    return e;
                                  })()}
                                  starSize={16}
                                  maxStars={5}
                                  activeColor={theme.colors.primary}
                                  inactiveColor={theme.colors.divider}
                                />
                              )}
                            </>
                            <>
                              {!learningCenter?.reviews ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)["Text"],
                                      {
                                        marginLeft: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 8,
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {learningCenter?.reviews}
                                  {" reviews"}
                                </Text>
                              )}
                            </>
                            {/* type */}
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)["Text"],
                                  {
                                    marginLeft: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 12,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {learningCenter?.type}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: "center",
                                },
                                flexDirection: {
                                  minWidth: Breakpoints.Tablet,
                                  value: "row",
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <Icon size={24} name={"Feather/share"} />
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)["Text"],
                                  {
                                    marginLeft: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 4,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"Share Page"}
                            </Text>
                            {/* Icon 2 */}
                            <Icon
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 8,
                                  },
                                },
                                dimensions.width
                              )}
                              size={24}
                              name={"AntDesign/bells"}
                            />
                            {/* Text 2 */}
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)["Text"],
                                  {
                                    marginLeft: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 4,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"Save"}
                            </Text>
                          </View>
                        </View>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: "column",
                                },
                                { minWidth: Breakpoints.Laptop, value: "row" },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                paddingBottom: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 8,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!learningCenter?.name ? null : (
                                <SupabaseEdgeFunctionApi.FetchGetImagesForLearningCenterPOST
                                  name={learningCenter?.name}
                                  size={900}
                                >
                                  {({
                                    loading,
                                    error,
                                    data,
                                    refetchGetImagesForLearningCenter,
                                  }) => {
                                    const fetchData = data;
                                    if (loading) {
                                      return <ActivityIndicator />;
                                    }

                                    if (error) {
                                      return <ActivityIndicator />;
                                    }

                                    return (
                                      <Swiper
                                        renderItem={({ item }) => {
                                          const swiperData = item;
                                          return (
                                            <SwiperItem>
                                              <Image
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderRadius: 8,
                                                    height: "100%",
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: "100%",
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: "100%",
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: "100%",
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: "100%",
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                                resizeMode={"cover"}
                                                source={{
                                                  uri: `${swiperData}`,
                                                }}
                                              />
                                            </SwiperItem>
                                          );
                                        }}
                                        data={fetchData}
                                        listKey={"9t8gXO1b"}
                                        keyExtractor={(swiperData) =>
                                          swiperData?.id ||
                                          swiperData?.uuid ||
                                          JSON.stringify(swiperData)
                                        }
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SwiperStyles(theme)[
                                              "Swiper"
                                            ],
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: "100%",
                                              },
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        dotColor={theme.colors.light}
                                        dotActiveColor={theme.colors.primary}
                                        dotsTouchable={true}
                                        loop={true}
                                      />
                                    );
                                  }}
                                </SupabaseEdgeFunctionApi.FetchGetImagesForLearningCenterPOST>
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginLeft: [
                                  { minWidth: Breakpoints.Desktop, value: 32 },
                                  { minWidth: Breakpoints.Laptop, value: 32 },
                                  { minWidth: Breakpoints.Tablet, value: 12 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    "Inter Bold 20 Title"
                                  ],
                                  {
                                    marginBottom: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 12,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"Opening Hours:"}
                            </Text>
                            <FlatList
                              renderItem={({ item }) => {
                                const listData = item;
                                return (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)["Text"],
                                        {
                                          fontFamily: {
                                            minWidth: Breakpoints.Tablet,
                                            value: "Inter_400Regular",
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    numberOfLines={1}
                                    ellipsizeMode={"tail"}
                                  >
                                    {listData}
                                  </Text>
                                );
                              }}
                              data={formatOpeningHours(
                                learningCenter?.opening_hours
                              )}
                              listKey={"HDPo168p"}
                              keyExtractor={(listData) =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                            {/* Location Card */}
                            <View
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    "Location Card"
                                  ],
                                  {
                                    marginTop: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 12,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <MapView
                                renderItem={({ item }) => {
                                  const mapViewData = item;
                                  return (
                                    <MapMarker
                                      longitude={learningCenter?.longitude}
                                      latitude={learningCenter?.latitude}
                                      pinImageSize={50}
                                    />
                                  );
                                }}
                                markersData={[1]}
                                listKey={"6fi4iRr3"}
                                keyExtractor={(mapViewData) =>
                                  mapViewData?.id ||
                                  mapViewData?.uuid ||
                                  JSON.stringify(mapViewData)
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.MapViewStyles(theme)["Map View"],
                                  dimensions.width
                                )}
                                latitude={learningCenter?.latitude}
                                longitude={learningCenter?.longitude}
                                loadingEnabled={true}
                                showsPointsOfInterest={true}
                                customMapStyle={"Beautiful West Coast Villa"}
                                apiKey={
                                  "AIzaSyAfUyq3Ia5ePhsqBo4m_w08J90Cd74XLdg"
                                }
                                scrollEnabled={false}
                                rotateEnabled={false}
                                zoomEnabled={false}
                                zoom={16}
                              />
                              {/* Bottom */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomLeftRadius: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 8,
                                    },
                                    borderBottomRightRadius: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 8,
                                    },
                                    borderBottomWidth: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 1,
                                    },
                                    borderColor: {
                                      minWidth: Breakpoints.Tablet,
                                      value: theme.colors["Divider"],
                                    },
                                    borderLeftWidth: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 1,
                                    },
                                    borderRightWidth: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 1,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: "row",
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: {
                                        minWidth: Breakpoints.Tablet,
                                        value: "center",
                                      },
                                      flex: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 1,
                                      },
                                      paddingBottom: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                      paddingTop: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Call */}
                                  <Link
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `callto:${learningCenter?.phone}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)["Link"],
                                        {
                                          color: {
                                            minWidth: Breakpoints.Tablet,
                                            value: theme.colors["Strong"],
                                          },
                                          fontFamily: {
                                            minWidth: Breakpoints.Desktop,
                                            value: "Inter_500Medium",
                                          },
                                          fontSize: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={"Call"}
                                  />
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: {
                                        minWidth: Breakpoints.Tablet,
                                        value: "center",
                                      },
                                      flex: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 1,
                                      },
                                      paddingBottom: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                      paddingTop: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Website */}
                                  <Link
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `${(() => {
                                              const e = learningCenter?.website;
                                              console.log(e);
                                              return e;
                                            })()}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)["Link"],
                                        {
                                          color: {
                                            minWidth: Breakpoints.Tablet,
                                            value: theme.colors["Strong"],
                                          },
                                          fontFamily: {
                                            minWidth: Breakpoints.Desktop,
                                            value: "Inter_500Medium",
                                          },
                                          fontSize: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={"Website"}
                                  />
                                </View>
                                {/* View 3 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: {
                                        minWidth: Breakpoints.Tablet,
                                        value: "center",
                                      },
                                      flex: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 1,
                                      },
                                      paddingBottom: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                      paddingTop: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Directions */}
                                  <Link
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `${learningCenter?.URL}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)["Link"],
                                        {
                                          color: {
                                            minWidth: Breakpoints.Tablet,
                                            value: theme.colors["Strong"],
                                          },
                                          fontFamily: {
                                            minWidth: Breakpoints.Desktop,
                                            value: "Inter_500Medium",
                                          },
                                          fontSize: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={"Directions"}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <Spacer right={8} left={8} top={12} bottom={12} />
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* View 3 */}
                        <>
                          {!lxpItem?.details ? null : (
                            <View>
                              {/* Text 2 */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    "Inter Bold 20 Title"
                                  ],
                                  dimensions.width
                                )}
                              >
                                {"About this location"}
                              </Text>
                              {/* Text 3 */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)["Text"],
                                    {
                                      marginTop: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 12,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {lxpItem?.details}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* Text 2 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                "Inter Bold 20 Title"
                              ],
                              {
                                marginTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 12,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {"Learning Experiences"}
                        </Text>
                        {/* Text 4 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)["Text"],
                              {
                                marginTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 8,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {"Timezone - "}
                          {getTimezonOffset()}
                        </Text>
                      </View>

                      <View>
                        {/* Lxps */}
                        <FlatList
                          renderItem={({ item }) => {
                            const lxpsData = item;
                            return (
                              <>
                                {/* LXP card */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        "LXP card"
                                      ],
                                      {
                                        alignContent: "flex-start",
                                        flexDirection: {
                                          minWidth: Breakpoints.Tablet,
                                          value: "row",
                                        },
                                        paddingRight: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 12,
                                        },
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 600,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 600,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 900,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderRadius: 8,
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        height: 94,
                                        overflow: "hidden",
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 150,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomLeftRadius: 0,
                                          borderBottomRightRadius: 0,
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          height: "100%",
                                          width: "100%",
                                        },
                                        dimensions.width
                                      )}
                                      source={{
                                        uri: `${lxpsData?.coverimage}`,
                                      }}
                                      resizeMode={"cover"}
                                    />
                                    {/* starting card */}
                                    <>
                                      {!lxpsData?.upcoming_session ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "flex-end",
                                              backgroundColor:
                                                theme.colors["Background"],
                                              borderRadius: 8,
                                              bottom: 8,
                                              flexDirection: "row",
                                              left: 4,
                                              padding: 8,
                                              position: "absolute",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            style={StyleSheet.applyWidth(
                                              { marginBottom: 2 },
                                              dimensions.width
                                            )}
                                            name={
                                              "MaterialCommunityIcons/timer-sand-empty"
                                            }
                                            size={12}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    "WorkSans_600SemiBold",
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {"Starts "}
                                            {getDayDiff(
                                              lxpsData?.upcoming_session
                                                ?.start_time
                                            )}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                  {/* Details */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 1,
                                        },
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        paddingTop: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 8,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* title */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            "Inter Regular"
                                          ],
                                          {
                                            color: theme.colors["Strong"],
                                            fontFamily: "Inter_700Bold",
                                            fontSize: 16,
                                            lineHeight: 15,
                                            paddingBottom: 4,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      ellipsizeMode={"tail"}
                                      numberOfLines={1}
                                    >
                                      {lxpsData?.title}
                                    </Text>
                                    <Spacer
                                      right={8}
                                      bottom={8}
                                      left={8}
                                      top={0}
                                    />
                                    {/* Quick Info */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: "center",
                                          flexDirection: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: "column",
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: "row",
                                            },
                                          ],
                                          justifyContent: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: "space-between",
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: "flex-start",
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* type */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginLeft: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 4,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          size={14}
                                          color={theme.colors["Strong"]}
                                          name={"Ionicons/ios-location-outline"}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                "Work Sans Regular"
                                              ],
                                              {
                                                color: theme.colors["Strong"],
                                                fontFamily: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: "System",
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: "System",
                                                  },
                                                ],
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 14,
                                                  },
                                                ],
                                                fontWeight: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: "400",
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: "400",
                                                  },
                                                ],
                                                lineHeight: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 13,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 14,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          numberOfLines={1}
                                          ellipsizeMode={"tail"}
                                        >
                                          {getLearningTypeLabel(
                                            lxpsData?.lxp_location_type
                                          )}
                                        </Text>
                                      </View>
                                      {/* sessions */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginLeft: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 4,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          name={"AntDesign/calendar"}
                                          color={theme.colors["Strong"]}
                                          size={14}
                                        />
                                        <Spacer
                                          left={8}
                                          right={0}
                                          bottom={0}
                                          top={0}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                "Work Sans Regular"
                                              ],
                                              {
                                                color: theme.colors["Strong"],
                                                fontFamily: "System",
                                                fontSize: 14,
                                                fontWeight: "400",
                                                lineHeight: 13,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          numberOfLines={1}
                                          ellipsizeMode={"tail"}
                                        >
                                          {lxpsData?.up_session_count}
                                          {" sessions"}
                                        </Text>
                                      </View>
                                      {/* ages */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginLeft: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 4,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                "Work Sans Regular"
                                              ],
                                              {
                                                color: theme.colors["Strong"],
                                                fontFamily: "System",
                                                fontSize: 14,
                                                fontWeight: "400",
                                                lineHeight: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 13,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 14,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          numberOfLines={1}
                                          ellipsizeMode={"head"}
                                        >
                                          {"Ages "}
                                          {lxpsData?.min_age}
                                          {" - "}
                                          {lxpsData?.max_age}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* description */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginTop: 8, overflow: "hidden" },
                                        dimensions.width
                                      )}
                                    >
                                      {/* description */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              theme.colors["dark gray text"],
                                          },
                                          dimensions.width
                                        )}
                                        ellipsizeMode={"tail"}
                                        numberOfLines={4}
                                      >
                                        {lxpsData?.description}
                                      </Text>
                                    </View>
                                    <Spacer
                                      right={8}
                                      left={8}
                                      top={0}
                                      bottom={14}
                                    />
                                    {/* Meta */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: "center",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          marginBottom: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 8,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* author */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flex: 1,
                                            flexDirection: "row",
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Circle
                                          bgColor={theme.colors.light}
                                          size={24}
                                        >
                                          <>
                                            {!lxpsData?.author
                                              ?.profile_picture ? null : (
                                              <Image
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    height: "100%",
                                                    width: "100%",
                                                  },
                                                  dimensions.width
                                                )}
                                                resizeMode={"contain"}
                                                source={{
                                                  uri: `${lxpsData?.author?.profile_picture}`,
                                                }}
                                              />
                                            )}
                                          </>
                                          <>
                                            {lxpsData?.author
                                              ?.profile_picture ? null : (
                                              <Icon
                                                size={24}
                                                name={"Ionicons/person"}
                                                color={
                                                  theme.colors["dark gray text"]
                                                }
                                              />
                                            )}
                                          </>
                                        </Circle>
                                        {/* full name */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              marginLeft: 10,
                                            },
                                            dimensions.width
                                          )}
                                          adjustsFontSizeToFit={true}
                                          numberOfLines={1}
                                          ellipsizeMode={"tail"}
                                        >
                                          {lxpsData?.author?.firstName}{" "}
                                          {lxpsData?.author?.lastName}
                                        </Text>
                                      </View>
                                      {/* Starting */}
                                      <>
                                        {!lxpsData?.upcoming_session
                                          ?.start_time ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: "flex-end",
                                                },
                                                flex: 1,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                { color: theme.colors.strong },
                                                dimensions.width
                                              )}
                                              adjustsFontSizeToFit={true}
                                              numberOfLines={1}
                                              ellipsizeMode={"tail"}
                                            >
                                              {
                                                lxpsData?.upcoming_session
                                                  ?.start_time
                                              }
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        justifyContent: {
                                          minWidth: Breakpoints.Tablet,
                                          value: "center",
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Price */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: "center",
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: "flex-start",
                                            },
                                          ],
                                          flexDirection: "row",
                                          marginLeft: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 4,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: {
                                              minWidth: Breakpoints.Tablet,
                                              value: "flex-start",
                                            },
                                            flexDirection: {
                                              minWidth: Breakpoints.Tablet,
                                              value: "row",
                                            },
                                            marginBottom: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 12,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                "Work Sans Regular"
                                              ],
                                              {
                                                color: theme.colors["Strong"],
                                                fontFamily: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: "System",
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: "System",
                                                  },
                                                ],
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 20,
                                                  },
                                                ],
                                                fontWeight: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: "400",
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: "700",
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          numberOfLines={1}
                                          ellipsizeMode={"tail"}
                                        >
                                          {"$"}
                                          {getFinalPrice(
                                            Variables,
                                            lxpsData?.pricing_per_session
                                          )}
                                        </Text>
                                        {/* Text 2 */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                "Text"
                                              ],
                                              {
                                                marginLeft: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 12,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {"per session"}
                                        </Text>
                                      </View>
                                    </View>
                                    <Button
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            "LXPDetailsScreen",
                                            { lxp_id: lxpsData?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            "Button"
                                          ],
                                          {
                                            height: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 40,
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={"Continue"}
                                    />
                                  </View>
                                </View>
                                <Spacer top={8} right={8} bottom={8} left={8} />
                              </>
                            );
                          }}
                          data={lxps}
                          listKey={"9b1F7vbw"}
                          keyExtractor={(lxpsData) => lxpsData?.id}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.FlatListStyles(theme)["Lxps"],
                            dimensions.width,
                            ["width", "height", "flexGrow"]
                          )}
                          contentContainerStyle={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.FlatListStyles(theme)["Lxps"],
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Tablet,
                                  value: "stretch",
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 16,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          horizontal={false}
                        />
                        {/* Empty Container */}
                        <>
                          {lxps?.length ? null : (
                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      "Inter Bold 20 Title"
                                    ],
                                    {
                                      fontFamily: {
                                        minWidth: Breakpoints.Laptop,
                                        value: "Inter_500Medium",
                                      },
                                      fontSize: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 16,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {"No Learning Experience"}
                              </Text>
                            </View>
                          )}
                        </>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </>
            <>
              {!isLoading ? null : (
                <ActivityIndicator
                  style={StyleSheet.applyWidth(
                    { height: "100%", width: "100%" },
                    dimensions.width
                  )}
                  animating={true}
                  hidesWhenStopped={true}
                  size={"large"}
                />
              )}
            </>
          </ScrollView>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LearningCentreDetailsScreen);
