import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const uploadImageToSupabaseStorage = async (string, prefix) => {
  if (string.includes('https')) return string;
  const byteNumbers = new Array(string.length);

  const type = string.split(';')[0].split(':')[1];

  function _base64ToArrayBuffer(base64) {
    var binary_string = CustomPackages.decode(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const { data, error } = await CustomPackages.supabase.storage
    .from(prefix)
    .upload(
      prefix + Date.now() + '.' + type.split('/')[1],
      _base64ToArrayBuffer(string.split(';')[1].split(',')[1]),
      {
        contentType: type,
      }
    );

  return (
    'https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/' +
    data.Key
  );
};

export default uploadImageToSupabaseStorage;
