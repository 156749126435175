import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as SupabaseEdgeFunctionApi from '../apis/SupabaseEdgeFunctionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const QuickCreateAccountScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const signupFormHasError = () => {
    let hasError = false;

    const emailExpr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
    const passwordExpr = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!$#%_]{8,}$/;
    for (let [fieldName, fieldObj] of Object.entries(signupForm)) {
      let { value, label } = fieldObj;

      switch (fieldName) {
        case 'firstName':
        case 'lastName':
        case 'username': {
          if (value.length < 1) {
            setFormField(fieldName, 'error', `${label} can't be empty!`);
            hasError = true;
          }
          break;
        }

        case 'email':
        case 'parentEmail': {
          if (!emailExpr.test(value)) {
            setFormField(fieldName, 'error', `${label} is invalid!`);
            hasError = true;
          }
          break;
        }

        case 'password': {
          if (!passwordExpr.test(value)) {
            setFormField(
              fieldName,
              'error',
              `${label} should be at least 8 characters in length, contain at least 1 letter, 1 number and 1 of these @!$#%_ special charecters.`
            );
            hasError = true;
          }
          break;
        }
        default:
          break;
      }
    }

    return hasError;
  };

  const isQuickSignup = cart => {
    if (cart.length) {
      props.navigation.navigate('PurchaseProcessingScreen');
      return true;
    } else {
      return false;
    }
  };

  const createContactObject = (email, firstName, lastName, country) => {
    return { email, firstName, lastName, city: country };
  };

  const setFormField = (fieldName, attr, value) => {
    setSignupForm(prev => {
      let newField;
      if (attr === 'value')
        newField = { ...prev[fieldName], [attr]: value, error: '' };
      else newField = { ...prev[fieldName], [attr]: value };

      const newForm = { ...prev, [fieldName]: newField };
      // console.log('newForm', newForm)
      return newForm;
    });
  };

  const { theme } = props;
  const { navigation } = props;

  const [accountType, setAccountType] = React.useState('learner');
  const [error, setError] = React.useState('');
  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const [signupForm, setSignupForm] = React.useState({
    email: { error: '', label: 'Email', value: '' },
    lastName: { error: '', label: 'Last Name', value: '' },
    password: { error: '', label: 'Password', value: '' },
    username: { error: '', label: 'Handle', value: '' },
    firstName: { error: '', label: 'First Name', value: '' },
    parentEmail: { error: '', label: "Parent or guardian's email", value: '' },
  });
  const [tempFieldValue, setTempFieldValue] = React.useState('');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row', height: 72 },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    flexDirection: 'row',
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Primary'],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.Group}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Inactive Gray Text'],
                    flex: 1,
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <View />
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Nav 5'],
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://ark.cityasaschool.com/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['ARK 2'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialIcons/auto-awesome'}
                        size={18}
                        color={theme.colors['Strong']}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'AI Copilot'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover Nav */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DiscoverScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['Discover Nav 8'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/grid'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Discover'}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/search'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            { fontSize: 16, lineHeight: 19 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Experiences'}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} left={16} right={16} />
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: 'row' },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
            )}
          </>
          {/* Scroll View Body */}
          <ScrollView
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            {/* Body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Background'],
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  flex: 1,
                  paddingBottom: 32,
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 32,
                },
                dimensions.width
              )}
            >
              {/* Central Profile */}
              <View
                style={StyleSheet.applyWidth(
                  { maxWidth: 360 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingBottom: 32,
                      paddingLeft: 14,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontSize: 24,
                        fontWeight: '600',
                      },
                      dimensions.width
                    )}
                  >
                    {'Create Account'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      { color: theme.colors.strong, marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    {"Let's get started"}
                  </Text>
                </View>
                {/* tabsFrame */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Tab header'],
                    dimensions.width
                  )}
                >
                  {/* LearnerTabButtons */}
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    {/* LearnerActive */}
                    <>
                      {!(accountType === 'learner') ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Background'],
                              borderBottomWidth: 2,
                              borderColor: theme.colors['Primary'],
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          title={'Learner'}
                        />
                      )}
                    </>
                    {/* LearnerInActive */}
                    <>
                      {!(accountType !== 'learner') ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setAccountType('learner');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Background'],
                              borderBottomWidth: 2,
                              borderColor: theme.colors['Inactive Gray Text'],
                              borderRadius: 0,
                              color: theme.colors['Inactive Gray Text'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          title={'Learner'}
                        />
                      )}
                    </>
                  </View>
                  {/* ParentTabBUttons */}
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    {/* ParentActive */}
                    <>
                      {!(accountType === 'parent') ? null : (
                        <Button
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Background'],
                              borderBottomWidth: 2,
                              borderColor: theme.colors['Primary'],
                              borderRadius: 0,
                              color: theme.colors['Primary'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          title={'Parent (Coming Soon)'}
                        />
                      )}
                    </>
                    {/* ParentInActive */}
                    <>
                      {!(accountType !== 'parent') ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setAccountType('parent');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Background'],
                              borderBottomWidth: 2,
                              borderColor: theme.colors['Inactive Gray Text'],
                              borderRadius: 0,
                              color: theme.colors['Inactive Gray Text'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          disabled={true}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          title={'Parent (Coming Soon)'}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* Learner Profile Details */}
                <>
                  {!(accountType === 'learner') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* firstNameLastName */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, marginRight: 16 },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.firstName.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'firstName',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.firstName?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.firstName.error}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* Last Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.lastName.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'lastName',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.lastName?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.lastName.error}
                          </Text>
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Email */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.email.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'email',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.email?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            keyboardType={'email-address'}
                            textContentType={'emailAddress'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.email.error}
                          </Text>
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Handle */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Handle */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.username.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'username',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.username?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            keyboardType={'default'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.username.error}
                          </Text>
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Password */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Password */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.password.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'password',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.password?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            maxLength={280}
                            multiline={false}
                            numberOfLines={1}
                            secureTextEntry={true}
                            clearButtonMode={'while-editing'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.password?.error}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Parent Email */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Parent Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {signupForm?.parentEmail.label}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                setTempFieldValue(newTextInputValue);
                                setFormField(
                                  'parentEmail',
                                  'value',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={signupForm?.parentEmail?.value}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {signupForm?.parentEmail.error}
                          </Text>
                        </View>
                      </View>
                      <Spacer right={8} left={8} top={16} bottom={16} />
                      {/* Create Account */}
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              signupFormHasError();
                              setIsSigningUp(true);
                              const parent_id = (
                                await PublicSchemaApi.upsertParentPOST(
                                  Constants,
                                  { parentEmail: signupForm?.parentEmail.value }
                                )
                              )?.json;
                              const signupRes = (
                                await SupabaseAuthApi.emailSignupPOST(
                                  Constants,
                                  {
                                    parent_email: signupForm?.parentEmail.value,
                                    parent_id: parent_id,
                                    signupEmail: signupForm?.email.value,
                                    signupFirstName:
                                      signupForm?.firstName.value,
                                    signupLastName: signupForm?.lastName.value,
                                    signupPassword: signupForm?.password.value,
                                    username: signupForm?.username.value,
                                  }
                                )
                              )?.json;

                              const valueJnXbfrpd = signupRes?.msg;
                              setError(valueJnXbfrpd);
                              const errorMsg = valueJnXbfrpd;
                              if (errorMsg) {
                                return;
                              }
                              setIsSigningUp(false);
                              const contactObj = createContactObject(
                                signupForm?.email.value,
                                signupForm?.firstName.value,
                                signupForm?.lastName.value,
                                undefined
                              );
                              (
                                await SupabaseEdgeFunctionApi.createSendGridContactPOST(
                                  Constants,
                                  {
                                    action: 'add-contact',
                                    contact: contactObj,
                                    list_id: Constants['WEB_INTERESTS_LIST_ID'],
                                  }
                                )
                              )?.json;
                              setGlobalVariableValue({
                                key: 'AUTH_UUID_CAAS',
                                value: signupRes?.user?.id,
                              });
                              setGlobalVariableValue({
                                key: 'AUTH_HEADER_CAAS',
                                value: 'Bearer ' + signupRes?.access_token,
                              });
                              if (isQuickSignup(Constants['WAITING_CART'])) {
                                return;
                              }
                              navigation.navigate('LoginScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        loading={isSigningUp}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                        title={'Create Account'}
                      />
                      {/* Error */}
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error'], marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {error}
                      </Text>
                    </View>
                  )}
                </>
                {/* Parent Profile Details */}
                <>
                  {!(accountType === 'parent') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    >
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* firstNameLastName */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, marginRight: 16 },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'First Name'}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* Last Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'Last Name'}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Email */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Email */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'Email'}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            keyboardType={'email-address'}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Handle */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Handle */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'Handle'}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            keyboardType={'default'}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Learning Goal */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'Password'}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                            maxLength={280}
                            multiline={false}
                            numberOfLines={1}
                            secureTextEntry={true}
                            clearButtonMode={'while-editing'}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors['Error'], marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            {'Error'}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Personal Website */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {"Parent or guardian's email"}
                          </Text>
                          <TextInput
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            placeholder={' '}
                            autoCapitalize={'none'}
                          />
                        </View>
                      </View>
                      <Spacer right={8} left={8} top={16} bottom={16} />
                      {/* Create Account */}
                      <Button
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                        title={'Create Account'}
                      />
                    </View>
                  )}
                </>
                {/* Help */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontSize: 14,
                          fontWeight: '400',
                          marginRight: 8,
                        },
                        dimensions.width
                      )}
                    >
                      {'Already have an account'}
                    </Text>

                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('LoginScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeOpacity={0.8}
                      disabledOpacity={0.8}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Primary'] },
                          dimensions.width
                        )}
                      >
                        {'Log in'}
                      </Text>
                    </Touchable>
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  {/* T&Cs */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong, textAlign: 'center' },
                        dimensions.width
                      )}
                    >
                      {
                        'By clicking Create Account, you are agreeing to City as a School’s '
                      }
                    </Text>
                    <Link
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.cityasaschool.com/terms-of-service'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        dimensions.width
                      )}
                      title={'Terms of Service '}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong, textAlign: 'center' },
                        dimensions.width
                      )}
                    >
                      {'including our '}
                    </Text>
                    <Link
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.cityasaschool.com/code-of-conduct'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        dimensions.width
                      )}
                      title={'Code of Conduct'}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong, textAlign: 'center' },
                        dimensions.width
                      )}
                    >
                      {', and our '}
                    </Text>
                    <Link
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.cityasaschool.com/privacy'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        dimensions.width
                      )}
                      title={'Privacy Policy.'}
                    />
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(QuickCreateAccountScreen);
