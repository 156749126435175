import React from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as PublicSchemaApi from "../apis/PublicSchemaApi.js";
import * as SupabaseAuthApi from "../apis/SupabaseAuthApi.js";
import * as SupabaseEdgeFunctionApi from "../apis/SupabaseEdgeFunctionApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import Images from "../config/Images";
import * as CustomPackages from "../custom-files/CustomPackages";
import countSeriesEnrolled from "../global-functions/countSeriesEnrolled";
import dateFormatting from "../global-functions/dateFormatting";
import enrolledForWholeSeries from "../global-functions/enrolledForWholeSeries";
import getDayDiff from "../global-functions/getDayDiff";
import getFinalPrice from "../global-functions/getFinalPrice";
import getLXPType from "../global-functions/getLXPType";
import getLXPTypeTitle from "../global-functions/getLXPTypeTitle";
import getLearningTypeLabel from "../global-functions/getLearningTypeLabel";
import getOneTimeSessionItem from "../global-functions/getOneTimeSessionItem";
import getSeriesDateRange from "../global-functions/getSeriesDateRange";
import getSessionItem from "../global-functions/getSessionItem";
import getTimezonOffset from "../global-functions/getTimezonOffset";
import getUpcomingSessions from "../global-functions/getUpcomingSessions";
import getUpcommingSeries from "../global-functions/getUpcommingSeries";
import openUrlUsingLinking from "../global-functions/openUrlUsingLinking";
import * as Utils from "../utils";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import { MapMarker, MapView } from "@draftbit/maps";
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from "@draftbit/ui";
import { useIsFocused } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Fetch } from "react-request";

const LXPDetailsScreen = (props) => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const createIntentObj = (
    setGlobalVariableValue,
    seller_email,
    seller_fullname,
    seller_id,
    final_price,
    actual_price,
    sessions,
    lxp_title,
    lxp_id,
    join_url,
    meeting_id,
    meeting_password,
    start_time,
    username,
    maxPart,
    count
  ) => {
    let paymentIntet = {
      seller: {
        email: seller_email,
        fullName: seller_fullname,
        id: seller_id,
      },
      metadata: {
        final_price: final_price,
        actual_price: actual_price,
        sessions: sessions,
        lxp_title: lxp_title,
        lxp_id: lxp_id,
        join_url: join_url,
        meeting_id: meeting_id,
        meeting_password: meeting_password,
        upcoming_start_time: start_time,
        learner_username: usernam,
        max_participants: maxPart,
        enrolled_count: count,
      },
    };

    setGlobalVariableValue({
      key: "INTENT_OBJ",
      value: paymentIntet,
    });
  };

  const purchasingAsAnonymousUser = (AUTH_UUID) => {
    if (!AUTH_UUID) {
      // login first before buying
      props.navigation.navigate("LoginScreen");
      return true;
    }
    return false;
  };

  const setupUpcomingSeries = (seriesArr) => {
    // add a conditional stop before this if lxp is one time
    setUpcomingSeries(
      seriesArr.filter((series) =>
        series.sessions.some(({ start_time }) => isUpcomingDate(start_time))
      )
    );
  };

  const unselectSeries = (seriesItem) => {
    setSessionCart((old) =>
      old.filter(
        (ses_id) => !seriesItem.sessions.find(({ id }) => id === ses_id)
      )
    );
  };

  const addWholeSeries = (Variables, item) => {
    item.sessions.map((sess) => !inCart(sess.id) && addToCart(Variables, sess));
  };

  const removeFromCart = (item) => {
    setSessionCart((old) => old.filter((id) => id !== item.id));
  };

  const selectedWholeSeries = (Variables, seriesItem) => {
    return (
      sessionCart.length &&
      seriesItem.sessions
        .filter(
          ({ enrolled_ids }) =>
            !enrolled_ids || !enrolled_ids.includes(Variables.AUTH_UUID_CAAS)
        )
        .every(({ id }) => sessionCart.includes(id))
    );
  };

  const toggleSessionCheckbox = (Variables, sessionItem) => {
    console.log("toggle", sessionItem, sessionCart);
    if (inCart(sessionItem.id)) {
      console.log("removed");
      removeFromCart(sessionItem);
    } else {
      console.log("add");
      addToCart(Variables, sessionItem);
    }
  };

  const showAvailableTimesSection = () => {
    // console.log(upcomingSeries.length, upcomingSessions.length)

    return upcomingSeries.length > 0 || upcomingSessions.length > 0;
  };

  const isJoinable = (Variables, item) => {
    return !inCart(item.id) && !isEnrolledFor(Variables, item.id);
  };

  const isEnrolledFor = (Variables, sessionId) => {
    return (
      Variables.ENROLLED_SESSION_ID?.length &&
      Variables.ENROLLED_SESSION_ID.includes(sessionId)
    );
  };

  const isUpcomingDate = (dateObj) => {
    return (
      CustomPackages.moment(dateObj).diff(CustomPackages.moment(), "seconds") >
      0
    );
  };

  const addToCart = (Variables, item) => {
    console.log("add to cart", item);
    // const {enrolled_ids} = item
    // if (!enrolled_ids || !enrolled_ids.includes(Variables.AUTH_UUID_CAAS))
    if (
      !Variables.ENROLLED_SESSION_ID.length ||
      !Variables.ENROLLED_SESSION_ID.includes(item.id)
    )
      setSessionCart((old) => [...old, item.id]);
  };

  const inCart = (sessionId) => {
    // console.log('in cart', sessionCart, sessionId)

    return sessionCart.includes(sessionId);
  };

  const formatOpeningHours = (hours) => {
    return hours ? hours.split("; ").map((item) => item.split(".")[0]) : [];
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIsLoading(true);
        const lxp = (
          await PublicSchemaApi.getExtendedLXPPOST(Constants, {
            lxp_id:
              props.route?.params?.lxp_id ??
              "7682d42b-107b-40d7-940e-abf16f97619d",
          })
        )?.json;

        const valuentOiKyAV = getUpcommingSeries(lxp?.series);
        setUpcomingSeries(valuentOiKyAV);
        const upComingSereis = valuentOiKyAV;
        const value2ga9p21b = getUpcomingSessions(
          lxp?.series && (lxp?.series)[0]
        );
        setUpcomingSessions(value2ga9p21b);
        const upComingSess = value2ga9p21b;
        setLxpItem(lxp);
        const count = (
          await PublicSchemaApi.countProviderPastSessionsPOST(Constants, {
            provider_uuid: (() => {
              const e = lxp?.author_id;
              console.log(e);
              return e;
            })(),
          })
        )?.json;
        setPastSessionCount(count);
        const upComingLxps = (
          await PublicSchemaApi.getUpcomingClassesForAProviderPOST(Constants, {
            curr_lxp_id:
              props.route?.params?.lxp_id ??
              "7682d42b-107b-40d7-940e-abf16f97619d",
            limit: 3,
            provider_uuid: lxp?.author_id,
          })
        )?.json;
        setUpcomingLxps(upComingLxps);
        setIsLoading(false);
        if (!Constants["AUTH_UUID_CAAS"]) {
          return;
        }
        const session_ids = (
          await PublicSchemaApi.getLearner$sEnrolledSessionIdsPOST(Constants)
        )?.json;
        setGlobalVariableValue({
          key: "ENROLLED_SESSION_ID",
          value: session_ids,
        });
        console.log(
          props.route?.params?.lxp_id ?? "7682d42b-107b-40d7-940e-abf16f97619d"
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [enrolledSessionIds, setEnrolledSessionIds] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPurchasing, setIsPurchasing] = React.useState(false);
  const [isShowingMore, setIsShowingMore] = React.useState(false);
  const [lxpItem, setLxpItem] = React.useState({});
  const [pastSessionCount, setPastSessionCount] = React.useState(0);
  const [sessionCart, setSessionCart] = React.useState([]);
  const [showingSeriesId, setShowingSeriesId] = React.useState(-1);
  const [upcomingLxps, setUpcomingLxps] = React.useState({
    id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
    title: "Animation Club - January",
    author: {
      id: "d5927566-a72b-44ac-9d02-317f502c043f",
      email: "svet.e.georgiev@gmail.com",
      handle: "2D animation and game design teacher and facilitator ",
      user_id: "8be0a8b9-ae79-4be9-8c12-be9c5bb7a306",
      about_me:
        "Svetoslav “Svet” Georgiev grew up in a small town in northern Bulgaria. When he was a kid, he had an enormous passion for cartoons and wanted to learn how they were made. He created tons of flipbooks and filled all his notebooks with his favorite characters until one day when he realized this could be his dream job. \n\nWith a passion for self-directed learning, he has been using his experience and knowledge from his Master’s degree in 2D Animation to teach students of all ages all around the world.\n\nHe has been involved in many projects, including 2D animation for commercials and education, music videos, visual fx, motion graphics, and video games for companies worldwide. \n\nHe enjoys spending time with his family, playing music, playing games, animating, reading books, and traveling.\n",
      lastName: "Georgiev ",
      location: "Veliko Tarnovo, Bulgaria",
      firstName: "Svet ",
      short_bio:
        "Animation and game design have always been my passion. I'm glad I can now share my love and knowledge with curious minds worldwide.\n",
      created_at: "2022-12-31T08:38:44.635399+00:00",
      updated_at: "2022-12-31T08:38:44.635399+00:00",
      is_verified: false,
      profile_picture:
        "https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/providers/providers1672991956814.jpeg",
    },
    series: [
      {
        sessions: [
          {
            id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
            lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
            duration: 50,
            series_no: 3,
            created_at: "2023-01-18T13:14:13.370983+00:00",
            start_time: "2023-01-31T18:00:00+00:00",
            updated_at: "2023-01-18T13:14:13.559+00:00",
            enrolled_ids: null,
            enrolled_count: 0,
          },
        ],
        series_no: 3,
      },
      {
        sessions: [
          {
            id: "95863d35-3472-4b9b-87c8-9c971450ccd7",
            lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
            duration: 50,
            series_no: 2,
            created_at: "2023-01-18T13:14:13.370983+00:00",
            start_time: "2023-01-24T18:00:00+00:00",
            updated_at: "2023-01-18T13:14:13.559+00:00",
            enrolled_ids: null,
            enrolled_count: 0,
          },
        ],
        series_no: 2,
      },
      {
        sessions: [
          {
            id: "a31ef858-1a1e-4576-be6b-18c0bee7f924",
            lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
            duration: 50,
            series_no: 1,
            created_at: "2023-01-18T13:14:13.370983+00:00",
            start_time: "2023-01-17T18:00:00+00:00",
            updated_at: "2023-01-18T13:14:13.559+00:00",
            enrolled_ids: null,
            enrolled_count: 0,
          },
        ],
        series_no: 1,
      },
    ],
    status: "active",
    max_age: 14,
    min_age: 10,
    subject: "Arts",
    zoom_id: "972 5714 0586",
    archived: false,
    zoom_url:
      "https://zoom.us/j/97257140586?pwd=eDAwci8rekNWQ0hoTTkwNDlxNFNFdz09",
    author_id: "8be0a8b9-ae79-4be9-8c12-be9c5bb7a306",
    isOneTime: false,
    isvirtual: true,
    coverimage:
      "https://qthvouonhshkvbaugrhc.supabase.co/storage/v1/object/public/lxpcovers/lxpcovers1674047652180.png",
    created_at: "2023-01-18T13:14:11.645329+00:00",
    difficulty: 1,
    updated_at: "2023-01-18T13:14:11.645329+00:00",
    description:
      "The Animation Club is where students can meet and share their interests in shows, movies, animators, and studios in a friendly, positive environment filled with like-minded people. In addition to introducing shows and animation to each other, we will hold discussions about series analysis, in-depth episode analysis, and analyze the fandoms surrounding animated shows. Students will be given the opportunity to share their art and creations pertaining to animations.\n\nThe objective of our club is to share our love of animation. We look at what lies beyond the superficial surface of these animations and share our artistic creations and those from the surrounding fandoms to educate and inspire others.\n\nWe will also learn about different software and animation styles. The students have the chance to express their ideas in one of the most creative ways through animations. \n\nNo experience is necessary. We are open to all ages and animation levels.",
    zoom_password: "515253",
    max_participants: 5,
    min_participants: 1,
    up_session_count: 1,
    upcoming_session: {
      id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
      lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
      duration: 50,
      series_no: 3,
      created_at: "2023-01-18T13:14:13.370983+00:00",
      start_time: "2023-01-31T18:00:00+00:00",
      updated_at: "2023-01-18T13:14:13.559+00:00",
      enrolled_count: 0,
    },
    pricing_per_series: 45,
    required_resources: "",
    pricing_per_session: 15,
  });
  const [upcomingSeries, setUpcomingSeries] = React.useState([
    {
      sessions: [
        {
          id: "fa7764c5-4adc-4c81-a8bd-83d23185230a",
          lxp_id: "ca74131a-c075-4058-8e1e-7b1c57f244be",
          duration: 50,
          series_no: 3,
          created_at: "2023-01-18T13:14:13.370983+00:00",
          start_time: "2023-01-31T18:00:00+00:00",
          updated_at: "2023-01-18T13:14:13.559+00:00",
          enrolled_ids: null,
          enrolled_count: 0,
        },
      ],
      series_no: 3,
    },
  ]);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <>
        {!isLoading ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              { height: "100%", width: "100%" },
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={"large"}
          />
        )}
      </>
      {/* Container */}
      <>
        {isLoading ? null : (
          <View
            style={StyleSheet.applyWidth({ height: "100%" }, dimensions.width)}
          >
            {/* Mobile Header */}
            <>
              {dimensions.width >= Breakpoints.Tablet ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Back */}
                  <IconButton
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={"AntDesign/left"}
                    color={theme.colors["Not Black"]}
                  />
                  {/* Tittle */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Inter Bold 20 Title"],
                        {
                          fontFamily: "System",
                          fontWeight: "600",
                          lineHeight: 22,
                          marginLeft: 8,
                          marginRight: 8,
                        }
                      ),
                      dimensions.width
                    )}
                    ellipsizeMode={"tail"}
                    numberOfLines={1}
                  >
                    {lxpItem?.title}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: "center", flexDirection: "row" },
                      dimensions.width
                    )}
                  >
                    {/* Share */}
                    <IconButton
                      size={32}
                      icon={"Entypo/share-alternative"}
                      color={theme.colors["Not Black"]}
                    />
                    {/* Fav */}
                    <IconButton
                      style={StyleSheet.applyWidth(
                        { marginLeft: 12 },
                        dimensions.width
                      )}
                      size={32}
                      icon={"MaterialIcons/favorite-border"}
                      color={theme.colors["Not Black"]}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header */}
            <>
              {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: "center", flexDirection: "row", height: 72 },
                    dimensions.width
                  )}
                >
                  {/* With Bar */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        borderColor: theme.colors["Inactive Gray Text"],
                        borderRightWidth: 1,
                        flexDirection: "row",
                        paddingBottom: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Blue Bar */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors["Primary"],
                          height: 42,
                          width: 3,
                        },
                        dimensions.width
                      )}
                    />
                    {/* Logo Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "center",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate("ExploreScreen");
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        <Image
                          style={StyleSheet.applyWidth(
                            { height: 32, width: 32 },
                            dimensions.width
                          )}
                          resizeMode={"cover"}
                          source={Images.Group}
                        />
                      </Touchable>
                    </View>
                  </View>
                  {/* Brand & Action */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "center",
                        borderBottomWidth: 1,
                        borderColor: theme.colors["Inactive Gray Text"],
                        flex: 1,
                        flexDirection: "row",
                        height: "100%",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingLeft: 14,
                        paddingRight: 14,
                        paddingTop: 0,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)["Work Sans Regular"],
                          {
                            fontFamily: "Questrial_400Regular",
                            fontSize: 24,
                            letterSpacing: 0.2,
                            lineHeight: 20,
                            textTransform: "uppercase",
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {"city as a school"}
                    </Text>
                    {/* Nav */}
                    <View
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)["Nav 3"],
                        dimensions.width
                      )}
                    >
                      {/* ARK */}
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                "https://ark.cityasaschool.com/"
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)["ARK 8"],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            name={"MaterialIcons/auto-awesome"}
                            size={18}
                            color={theme.colors["Strong"]}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)["Inter Regular"],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: "System",
                                  fontSize: 16,
                                  fontWeight: "400",
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"AI Copilot"}
                          </Text>
                        </View>
                      </Touchable>
                      {/* Discover Nav */}
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate("DiscoverScreen");
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)["Discover Nav 2"],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: "center",
                              flexDirection: "row",
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors["Strong"]}
                            name={"Feather/grid"}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)["Inter Regular"],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: "System",
                                  fontSize: 16,
                                  fontWeight: "400",
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"Discover"}
                          </Text>
                        </View>
                      </Touchable>

                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate("ExploreScreen");
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Classes */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: "center", flexDirection: "row" },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors["Strong"]}
                            name={"Feather/search"}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)["Inter Regular"],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: "System",
                                  fontSize: 16,
                                  fontWeight: "400",
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"Experiences"}
                          </Text>
                        </View>
                      </Touchable>
                      <Spacer top={8} bottom={8} right={16} left={0} />
                      {/* Join */}
                      <>
                        {Constants["AUTH_UUID_CAAS"] ? null : (
                          <Link
                            onPress={() => {
                              try {
                                navigation.navigate("LoginScreen");
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)["Link"],
                                {
                                  fontFamily: "Inter_500Medium",
                                  fontSize: 16,
                                  lineHeight: 19,
                                  marginLeft: 12,
                                }
                              ),
                              dimensions.width
                            )}
                            title={"Join"}
                          />
                        )}
                      </>
                      {/* Avatar */}
                      <>
                        {!Constants["AUTH_UUID_CAAS"] ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: "center", flexDirection: "row" },
                              dimensions.width
                            )}
                          >
                            {/* Touchable (go to profile) */}
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate("ProfileScreen");
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants["AUTH_PROFILE_CAAS"]
                                  ?.profile_picture ? null : (
                                  <Circle
                                    size={24}
                                    bgColor={theme.colors["Primary"]}
                                  >
                                    <>
                                      {!Constants["AUTH_PROFILE_CAAS"]
                                        ?.profile_picture ? null : (
                                        <Image
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                "Image"
                                              ],
                                              { height: "100%", width: "100%" }
                                            ),
                                            dimensions.width
                                          )}
                                          resizeMode={"cover"}
                                          source={{
                                            uri: `${Constants["AUTH_PROFILE_CAAS"]?.profile_picture}`,
                                          }}
                                        />
                                      )}
                                    </>
                                    <>
                                      {Constants["AUTH_PROFILE_CAAS"]
                                        ?.profile_picture ? null : (
                                        <Icon
                                          size={24}
                                          name={"Ionicons/person"}
                                        />
                                      )}
                                    </>
                                  </Circle>
                                )}
                              </>
                            </Touchable>
                            {/* Logout */}
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await SupabaseAuthApi.logoutPOST(
                                        Constants
                                      )
                                    )?.json;
                                    setGlobalVariableValue({
                                      key: "AUTH_HEADER_CAAS",
                                      value: "",
                                    });
                                    setGlobalVariableValue({
                                      key: "AUTH_UUID_CAAS",
                                      value: "",
                                    });
                                    setGlobalVariableValue({
                                      key: "AUTH_PROFILE_CAAS",
                                      value: {},
                                    });
                                    navigation.navigate("LoginScreen", {
                                      redirectPath: "",
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                              icon={"MaterialCommunityIcons/login-variant"}
                              size={24}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: [
                    { minWidth: Breakpoints.Laptop, value: 1 },
                    { minWidth: Breakpoints.Mobile, value: 1 },
                  ],
                  flexDirection: "row",
                  justifyContent: "center",
                },
                dimensions.width
              )}
            >
              {/* Sidebar */}
              <>
                {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors["Inactive Gray Text"],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 15,
                        paddingLeft: 15,
                        paddingTop: 15,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    <Spacer top={8} right={8} bottom={8} left={8} />
                  </View>
                )}
              </>
              {/* Body */}
              <ScrollView
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 40,
                  },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={true}
                bounces={true}
                showsHorizontalScrollIndicator={false}
              >
                {/* Centre Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      width: [
                        { minWidth: Breakpoints.Mobile, value: "100%" },
                        { minWidth: Breakpoints.Tablet, value: 960 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Hero */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Tablet, value: "row" },
                          { minWidth: Breakpoints.Mobile, value: "row" },
                        ],
                        flexWrap: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: "wrap-reverse",
                          },
                          { minWidth: Breakpoints.Laptop, value: "nowrap" },
                        ],
                        justifyContent: "space-between",
                        width: "100%",
                      },
                      dimensions.width
                    )}
                  >
                    {/* Hero Left */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: [
                            { minWidth: Breakpoints.Mobile, value: "column" },
                            { minWidth: Breakpoints.Tablet, value: "column" },
                          ],
                          justifyContent: "space-between",
                          paddingRight: 20,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: "100%" },
                            { minWidth: Breakpoints.Tablet, value: "50%" },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Top */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: "flex-start" },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <>
                          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: "System",
                                  fontSize: 30,
                                  fontWeight: "600",
                                },
                                dimensions.width
                              )}
                            >
                              {lxpItem?.title}
                            </Text>
                          )}
                        </>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* Details Row */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: "row",
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: "space-between",
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: "flex-start",
                                },
                              ],
                              width: "100%",
                            },
                            dimensions.width
                          )}
                        >
                          {/* Type */}
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong, marginRight: 8 },
                              dimensions.width
                            )}
                          >
                            {getLXPTypeTitle(lxpItem?.isOneTime)}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          {/* Location */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: "row" },
                              dimensions.width
                            )}
                          >
                            <Icon
                              style={StyleSheet.applyWidth(
                                { right: 0 },
                                dimensions.width
                              )}
                              name={"Ionicons/md-location-outline"}
                              size={16}
                            />
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.strong, marginLeft: 8 },
                                dimensions.width
                              )}
                            >
                              {getLearningTypeLabel(lxpItem?.lxp_location_type)}
                            </Text>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                          </View>
                          <>
                            {!lxpItem?.upcoming_session ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Desktop,
                                      value: "center",
                                    },
                                    flexDirection: "row",
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  style={StyleSheet.applyWidth(
                                    { right: 0 },
                                    dimensions.width
                                  )}
                                  name={"MaterialIcons/timer"}
                                  size={16}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      marginLeft: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {lxpItem?.upcoming_session?.duration}
                                  {" Mins per session"}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                        <Spacer right={8} left={8} top={12} bottom={12} />
                        {/* Teacher Name Card Row */}
                        <View>
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate("TeacherProfileScreen", {
                                  handle: lxpItem?.author?.handle,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: "center",
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors["gray line"],
                                  borderLeftWidth: 1,
                                  borderRadius: 20,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  flexDirection: "row",
                                  paddingBottom: 4,
                                  paddingLeft: 4,
                                  paddingRight: 4,
                                  paddingTop: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!!lxpItem?.author?.profile_picture ? null : (
                                  <Icon
                                    size={32}
                                    name={"Ionicons/person"}
                                    color={theme.colors["dark gray text"]}
                                  />
                                )}
                              </>
                              <>
                                {!lxpItem?.author?.profile_picture ? null : (
                                  <Image
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderRadius: 200,
                                        height: 32,
                                        width: 32,
                                      },
                                      dimensions.width
                                    )}
                                    resizeMode={"contain"}
                                    source={{
                                      uri: `${lxpItem?.author?.profile_picture}`,
                                    }}
                                  />
                                )}
                              </>
                              <Spacer top={8} right={8} bottom={8} left={0} />
                              {/* Name Badge */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: "center",
                                    flexDirection: "row",
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: "System",
                                      fontWeight: "400",
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {lxpItem?.author?.firstName}{" "}
                                  {lxpItem?.author?.lastName}
                                </Text>
                                <>
                                  {!lxpItem?.author?.is_verified ? null : (
                                    <Icon
                                      style={StyleSheet.applyWidth(
                                        { marginBottom: 8, marginLeft: 8 },
                                        dimensions.width
                                      )}
                                      size={24}
                                      name={"MaterialIcons/verified"}
                                      color={theme.colors["Primary"]}
                                    />
                                  )}
                                </>
                              </View>
                            </View>
                          </Touchable>
                        </View>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                      </View>
                      {/* Reviews */}
                      <View />
                      {/* Available Times Row */}
                      <>
                        {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: "row",
                                justifyContent: "space-between",
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors.primary,
                                  borderRadius: 8,
                                  fontFamily: "System",
                                  fontWeight: "700",
                                  textAlign: "center",
                                },
                                dimensions.width
                              )}
                              activeOpacity={0.8}
                              disabledOpacity={0.8}
                              title={"View Available Times"}
                            />
                          </View>
                        )}
                      </>
                    </View>
                    {/* Hero Right */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: 1,
                          flexDirection: "row",
                          flexWrap: "wrap-reverse",
                          maxHeight: 230,
                          minHeight: 230,
                          minWidth: [
                            { minWidth: Breakpoints.Mobile, value: "100%" },
                            { minWidth: Breakpoints.Tablet, value: 390 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: "100%" },
                            { minWidth: Breakpoints.Tablet, value: "50%" },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Image
                        style={StyleSheet.applyWidth(
                          { borderRadius: 8, height: "100%", width: "100%" },
                          dimensions.width
                        )}
                        resizeMode={"cover"}
                        source={{ uri: `${lxpItem?.coverimage}` }}
                      />
                    </View>
                  </View>
                  {/* Key Info */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors["gray line"],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: "column" },
                          { minWidth: Breakpoints.Tablet, value: "row" },
                        ],
                        justifyContent: "space-between",
                        marginBottom: 40,
                        marginTop: 40,
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        width: "100%",
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: "flex-start", flexDirection: "row" },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: "System",
                            fontSize: 20,
                            fontWeight: "600",
                            paddingRight: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {"$"}
                        {getFinalPrice(Variables, lxpItem?.pricing_per_session)}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors.strong },
                          dimensions.width
                        )}
                      >
                        {"per session"}
                      </Text>
                    </View>

                    <View>
                      <>
                        {!!lxpItem?.isOneTime ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: "flex-start",
                                flexDirection: "row",
                                marginTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: "System",
                                  fontSize: 20,
                                  fontWeight: "600",
                                  paddingRight: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {"$"}
                              {getFinalPrice(
                                Variables,
                                lxpItem?.pricing_per_series
                              )}
                            </Text>

                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.strong },
                                dimensions.width
                              )}
                            >
                              {"whole series"}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                    {/* years old */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "flex-start",
                          flexDirection: "row",
                          marginTop: [
                            { minWidth: Breakpoints.Mobile, value: 12 },
                            { minWidth: Breakpoints.Laptop, value: 0 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: "System",
                            fontSize: 20,
                            fontWeight: "600",
                            paddingRight: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {lxpItem?.min_age}
                        {" - "}
                        {lxpItem?.max_age}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors.strong },
                          dimensions.width
                        )}
                      >
                        {"years old"}
                      </Text>
                    </View>
                    {/* Learner per class */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "flex-start",
                          flexDirection: "row",
                          marginTop: [
                            { minWidth: Breakpoints.Mobile, value: 12 },
                            { minWidth: Breakpoints.Laptop, value: 0 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: "System",
                            fontSize: 20,
                            fontWeight: "600",
                            paddingRight: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {lxpItem?.min_participants}
                        {" - "}
                        {lxpItem?.max_participants}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors.strong },
                          dimensions.width
                        )}
                      >
                        {"Learner per class"}
                      </Text>
                    </View>
                  </View>
                  {/* Row */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Desktop, value: "row" },
                          { minWidth: Breakpoints.Tablet, value: "row" },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Description */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: "flex-start",
                          },
                          flex: { minWidth: Breakpoints.Tablet, value: 1 },
                          marginRight: {
                            minWidth: Breakpoints.Tablet,
                            value: 16,
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: "System",
                            fontSize: 24,
                            fontWeight: "600",
                          },
                          dimensions.width
                        )}
                      >
                        {"Description"}
                      </Text>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Desc */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: "flex-start" },
                          dimensions.width
                        )}
                      >
                        {/* Short */}
                        <>
                          {isShowingMore ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.strong },
                                dimensions.width
                              )}
                              numberOfLines={4}
                              ellipsizeMode={"tail"}
                            >
                              {lxpItem?.description}
                            </Text>
                          )}
                        </>
                        {/* Full */}
                        <>
                          {!isShowingMore ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.strong },
                                dimensions.width
                              )}
                            >
                              {lxpItem?.description}
                            </Text>
                          )}
                        </>
                        <Spacer top={8} right={8} left={8} bottom={0} />
                        {/* Toggle Button Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: '"rgba(0, 0, 0, 0)"',
                              flexDirection: "row",
                            },
                            dimensions.width
                          )}
                        >
                          {/* Toggler */}
                          <Touchable
                            onPress={() => {
                              try {
                                setIsShowingMore(!isShowingMore);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              { height: "100%" },
                              dimensions.width
                            )}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                          >
                            {/* less */}
                            <>
                              {!isShowingMore ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: "center",
                                      flexDirection: "row",
                                      padding: 0,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* less */}
                                  <Icon
                                    style={StyleSheet.applyWidth(
                                      { backgroundColor: '"rgba(0, 0, 0, 0)"' },
                                      dimensions.width
                                    )}
                                    name={"Feather/minus"}
                                    size={16}
                                    color={theme.colors["Primary"]}
                                  />
                                  {/* less */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          "Inter Regular"
                                        ],
                                        {
                                          color: theme.colors["Primary"],
                                          fontFamily: "System",
                                          fontWeight: "400",
                                          paddingRight: 4,
                                          textDecorationLine: "underline",
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {"Read Less"}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* more */}
                            <>
                              {isShowingMore ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: "center",
                                      flexDirection: "row",
                                      padding: 0,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* more */}
                                  <Icon
                                    style={StyleSheet.applyWidth(
                                      { backgroundColor: '"rgba(0, 0, 0, 0)"' },
                                      dimensions.width
                                    )}
                                    size={16}
                                    color={theme.colors["Primary"]}
                                    name={"MaterialIcons/add"}
                                  />
                                  {/* more */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          "Inter Regular"
                                        ],
                                        {
                                          color: theme.colors["Primary"],
                                          fontFamily: "System",
                                          fontWeight: "400",
                                          paddingRight: 4,
                                          textDecorationLine: "underline",
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {"Read more"}
                                  </Text>
                                </View>
                              )}
                            </>
                          </Touchable>
                        </View>
                      </View>
                    </View>
                    {/* Location */}
                    <>
                      {!lxpItem?.learning_center_id ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: "flex-start",
                              },
                              marginBottom: {
                                minWidth: Breakpoints.Desktop,
                                value: 12,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Desc */}
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: "flex-start" },
                              dimensions.width
                            )}
                          >
                            <PublicSchemaApi.FetchGetLearningCenterDetailsGET
                              id={lxpItem?.learning_center_id}
                            >
                              {({
                                loading,
                                error,
                                data,
                                refetchGetLearningCenterDetails,
                              }) => {
                                const fetchData = data;
                                if (loading) {
                                  return <ActivityIndicator />;
                                }

                                if (error) {
                                  return <ActivityIndicator />;
                                }

                                return (
                                  <FlatList
                                    renderItem={({ item }) => {
                                      const listData = item;
                                      return (
                                        <View>
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: "System",
                                                fontSize: 24,
                                                fontWeight: "600",
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.name}
                                          </Text>
                                          {/* type */}
                                          <>
                                            {!listData?.type ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: "center",
                                                    },
                                                    flexDirection: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: "row",
                                                    },
                                                    marginTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 12,
                                                    },
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  size={24}
                                                  name={"AntDesign/home"}
                                                />
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )["Inter Regular"],
                                                      {
                                                        marginLeft: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value: 8,
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?.type}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          {/* header */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Inter Regular"
                                                ],
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily: {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: "Inter_700Bold",
                                                  },
                                                  fontSize: {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 16,
                                                  },
                                                  marginBottom: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Desktop,
                                                      value: 12,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 12,
                                                    },
                                                  ],
                                                  marginTop: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Desktop,
                                                      value: 12,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 12,
                                                    },
                                                  ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {"Opening Hours:"}
                                          </Text>
                                          {/* opening hours */}
                                          <FlatList
                                            renderItem={({ item }) => {
                                              const openingHoursData = item;
                                              return (
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )["Inter Regular"],
                                                    dimensions.width
                                                  )}
                                                >
                                                  {openingHoursData}
                                                </Text>
                                              );
                                            }}
                                            data={formatOpeningHours(
                                              listData?.opening_hours
                                            )}
                                            listKey={JSON.stringify(
                                              formatOpeningHours(
                                                listData?.opening_hours
                                              )
                                            )}
                                            keyExtractor={(openingHoursData) =>
                                              openingHoursData
                                            }
                                            contentContainerStyle={StyleSheet.applyWidth(
                                              {
                                                marginTop: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 8,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            showsHorizontalScrollIndicator={
                                              true
                                            }
                                            showsVerticalScrollIndicator={true}
                                          />
                                          {/* Location card */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 220,
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 317,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <MapView
                                              renderItem={({ item }) => {
                                                const mapViewData = item;
                                                return (
                                                  <MapMarker
                                                    longitude={
                                                      mapViewData?.longitude
                                                    }
                                                    title={listData?.longitude}
                                                    latitude={
                                                      mapViewData?.latitude
                                                    }
                                                    pinImageSize={50}
                                                  />
                                                );
                                              }}
                                              markersData={fetchData}
                                              listKey={JSON.stringify(
                                                fetchData
                                              )}
                                              keyExtractor={(mapViewData) =>
                                                mapViewData?.id ||
                                                mapViewData?.uuid ||
                                                JSON.stringify(mapViewData)
                                              }
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.MapViewStyles(
                                                  theme
                                                )["Map View"],
                                                dimensions.width
                                              )}
                                              latitude={listData?.latitude}
                                              longitude={listData?.longitude}
                                              loadingEnabled={true}
                                              showsPointsOfInterest={true}
                                              customMapStyle={
                                                "Beautiful West Coast Villa"
                                              }
                                              apiKey={
                                                "AIzaSyAfUyq3Ia5ePhsqBo4m_w08J90Cd74XLdg"
                                              }
                                              scrollEnabled={false}
                                              rotateEnabled={false}
                                              zoomEnabled={false}
                                              zoom={16}
                                            />
                                            {/* Bottom */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderBottomLeftRadius: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 8,
                                                  },
                                                  borderBottomRightRadius: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 8,
                                                  },
                                                  borderBottomWidth: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 1,
                                                  },
                                                  borderColor: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value:
                                                      theme.colors["Divider"],
                                                  },
                                                  borderLeftWidth: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 1,
                                                  },
                                                  borderRightWidth: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 1,
                                                  },
                                                  flexDirection: {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: "row",
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: "center",
                                                    },
                                                    flex: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 1,
                                                    },
                                                    paddingBottom: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                    paddingTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Call */}
                                                <Link
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          await WebBrowser.openBrowserAsync(
                                                            `callto:${listData?.phone}`
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.LinkStyles(
                                                        theme
                                                      )["Link"],
                                                      {
                                                        color: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value:
                                                            theme.colors[
                                                              "Strong"
                                                            ],
                                                        },
                                                        fontFamily: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value:
                                                            "Inter_500Medium",
                                                        },
                                                        fontSize: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 18,
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  title={"Call"}
                                                />
                                              </View>
                                              {/* View 2 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: "center",
                                                    },
                                                    flex: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 1,
                                                    },
                                                    paddingBottom: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                    paddingTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Website */}
                                                <Link
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          await WebBrowser.openBrowserAsync(
                                                            `${listData?.website}`
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.LinkStyles(
                                                        theme
                                                      )["Link"],
                                                      {
                                                        color: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value:
                                                            theme.colors[
                                                              "Strong"
                                                            ],
                                                        },
                                                        fontFamily: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value:
                                                            "Inter_500Medium",
                                                        },
                                                        fontSize: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 18,
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  title={"Website"}
                                                />
                                              </View>
                                              {/* View 3 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: "center",
                                                    },
                                                    flex: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 1,
                                                    },
                                                    paddingBottom: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                    paddingTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 6,
                                                    },
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Directions */}
                                                <Link
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          await WebBrowser.openBrowserAsync(
                                                            `${listData?.URL}`
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.LinkStyles(
                                                        theme
                                                      )["Link"],
                                                      {
                                                        color: {
                                                          minWidth:
                                                            Breakpoints.Tablet,
                                                          value:
                                                            theme.colors[
                                                              "Strong"
                                                            ],
                                                        },
                                                        fontFamily: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value:
                                                            "Inter_500Medium",
                                                        },
                                                        fontSize: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 18,
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  title={"Directions"}
                                                />
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      );
                                    }}
                                    data={(() => {
                                      const e = fetchData;
                                      console.log(e);
                                      return e;
                                    })()}
                                    listKey={"hwUYtPEZ"}
                                    keyExtractor={(listData) => listData?.id}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                );
                              }}
                            </PublicSchemaApi.FetchGetLearningCenterDetailsGET>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  <Spacer right={8} left={8} top={20} bottom={20} />
                  {/* Available Times */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: "100%" },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: "System",
                          fontSize: 24,
                          fontWeight: "600",
                        },
                        dimensions.width
                      )}
                    >
                      {"Available Times"}
                    </Text>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)["Text"],
                        dimensions.width
                      )}
                    >
                      {"Timezone - "}
                      {getTimezonOffset()}
                    </Text>
                    <Spacer right={8} left={8} top={20} bottom={20} />
                    {/* Times List */}
                    <>
                      {!showAvailableTimesSection() ? null : (
                        <View>
                          {/* one time sessions */}
                          <>
                            {!lxpItem?.isOneTime ? null : (
                              <FlatList
                                renderItem={({ item }) => {
                                  const oneTimeSessionsData = item;
                                  return (
                                    <>
                                      {/* Container */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: "row" },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Wrapper */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderColor:
                                                theme.colors["gray line"],
                                              borderRadius: 8,
                                              borderWidth: 1,
                                              padding: 10,
                                              width: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* date */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              { color: theme.colors.strong },
                                              dimensions.width
                                            )}
                                          >
                                            {dateFormatting(
                                              oneTimeSessionsData?.start_time,
                                              "ddd DD MMM"
                                            )}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* date range */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: "System",
                                                fontSize: 20,
                                                fontWeight: "400",
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {getOneTimeSessionItem(
                                              oneTimeSessionsData
                                            )}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* Enrolled Count */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 8,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              oneTimeSessionsData?.enrolled_count
                                            }
                                            {"/"}
                                            {lxpItem?.max_participants}
                                            {" learners enrolled"}
                                          </Text>
                                          {/* Join Button */}
                                          <>
                                            {!isJoinable(
                                              Variables,
                                              oneTimeSessionsData
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    addToCart(
                                                      Variables,
                                                      oneTimeSessionsData
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      theme.colors.primary,
                                                    borderRadius: 8,
                                                    fontFamily: "System",
                                                    fontWeight: "700",
                                                    textAlign: "center",
                                                  },
                                                  dimensions.width
                                                )}
                                                activeOpacity={0.8}
                                                disabledOpacity={0.8}
                                                title={"Join"}
                                              />
                                            )}
                                          </>
                                          <>
                                            {!!isJoinable(
                                              Variables,
                                              oneTimeSessionsData
                                            ) ? null : (
                                              <View>
                                                {/* Selected */}
                                                <>
                                                  {!!isEnrolledFor(
                                                    Variables,
                                                    oneTimeSessionsData?.id
                                                  ) ? null : (
                                                    <Button
                                                      onPress={() => {
                                                        try {
                                                          removeFromCart(
                                                            oneTimeSessionsData
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: "System",
                                                          fontWeight: "700",
                                                          textAlign: "center",
                                                        },
                                                        dimensions.width
                                                      )}
                                                      activeOpacity={0.8}
                                                      disabledOpacity={0.8}
                                                      title={"Selected"}
                                                    />
                                                  )}
                                                </>
                                                {/* Enrolled */}
                                                <>
                                                  {!isEnrolledFor(
                                                    Variables,
                                                    oneTimeSessionsData?.id
                                                  ) ? null : (
                                                    <Button
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: "System",
                                                          fontWeight: "700",
                                                          textAlign: "center",
                                                        },
                                                        dimensions.width
                                                      )}
                                                      activeOpacity={0.8}
                                                      disabledOpacity={0.8}
                                                      title={"Enrolled"}
                                                    />
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        <Spacer
                                          top={8}
                                          right={8}
                                          bottom={8}
                                          left={8}
                                        />
                                      </View>
                                    </>
                                  );
                                }}
                                data={upcomingSessions}
                                listKey={"0MZ6rZmA"}
                                keyExtractor={(oneTimeSessionsData) =>
                                  oneTimeSessionsData?.id
                                }
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                horizontal={true}
                              />
                            )}
                          </>
                          {/* series */}
                          <>
                            {!!lxpItem?.isOneTime ? null : (
                              <FlatList
                                renderItem={({ item }) => {
                                  const seriesData = item;
                                  return (
                                    <>
                                      {/* Container */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: "row" },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Wrapper */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderColor:
                                                theme.colors["gray line"],
                                              borderRadius: 8,
                                              borderStyle: "solid",
                                              borderWidth: 1,
                                              padding: 10,
                                              width: 290,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* title */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Inter Regular"
                                                ],
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    "Inter_600SemiBold",
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {"Series # "}
                                            {seriesData?.series_no}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* date range */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily: "System",
                                                  fontSize: 20,
                                                  fontWeight: "400",
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {getSeriesDateRange(seriesData)}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* count */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              { color: theme.colors.strong },
                                              dimensions.width
                                            )}
                                          >
                                            {seriesData?.sessions?.length}
                                            {" Session(s)"}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* show */}
                                          <>
                                            {!(
                                              seriesData?.series_no !==
                                              showingSeriesId
                                            ) ? null : (
                                              <Touchable
                                                onPress={() => {
                                                  try {
                                                    setShowingSeriesId(
                                                      seriesData?.series_no
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                activeOpacity={0.8}
                                                disabledOpacity={0.8}
                                              >
                                                {/* container */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: "flex-end",
                                                      flexDirection: "row",
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* status */}
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors[
                                                            "Primary"
                                                          ],
                                                        paddingRight: 8,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {"Show"}
                                                  </Text>
                                                  <Icon
                                                    size={12}
                                                    color={
                                                      theme.colors["Primary"]
                                                    }
                                                    name={"AntDesign/down"}
                                                  />
                                                </View>
                                              </Touchable>
                                            )}
                                          </>
                                          {/* sessions */}
                                          <>
                                            {!(
                                              showingSeriesId ===
                                              seriesData?.series_no
                                            ) ? null : (
                                              <FlatList
                                                renderItem={({ item }) => {
                                                  const sessionsData = item;
                                                  return (
                                                    <>
                                                      {/* Row */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "row",
                                                            justifyContent:
                                                              "space-between",
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* item */}
                                                        <Text
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                theme.colors
                                                                  .strong,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {getSessionItem(
                                                            sessionsData
                                                          )}
                                                        </Text>
                                                        <Spacer
                                                          top={8}
                                                          right={8}
                                                          bottom={8}
                                                          left={8}
                                                        />
                                                        {/* selected */}
                                                        <Checkbox
                                                          onPress={(
                                                            newSelectedValue
                                                          ) => {
                                                            const checkboxValue =
                                                              newSelectedValue;
                                                            try {
                                                              toggleSessionCheckbox(
                                                                Variables,
                                                                sessionsData
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          }}
                                                          defaultValue={inCart(
                                                            sessionsData?.id
                                                          )}
                                                        />
                                                        {/* enrolled */}
                                                        <>
                                                          {!isEnrolledFor(
                                                            Variables,
                                                            sessionsData?.id
                                                          ) ? null : (
                                                            <Checkbox
                                                              disabled={isEnrolledFor(
                                                                Variables,
                                                                sessionsData?.id
                                                              )}
                                                              defaultValue={
                                                                true
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      </View>
                                                    </>
                                                  );
                                                }}
                                                data={seriesData?.sessions}
                                                listKey={JSON.stringify(
                                                  seriesData?.sessions
                                                )}
                                                keyExtractor={(sessionsData) =>
                                                  sessionsData?.id
                                                }
                                                contentContainerStyle={StyleSheet.applyWidth(
                                                  { flex: 1 },
                                                  dimensions.width
                                                )}
                                                numColumns={1}
                                                onEndReachedThreshold={0.5}
                                                showsHorizontalScrollIndicator={
                                                  true
                                                }
                                                showsVerticalScrollIndicator={
                                                  true
                                                }
                                              />
                                            )}
                                          </>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* hide */}
                                          <>
                                            {!(
                                              seriesData?.series_no ===
                                              showingSeriesId
                                            ) ? null : (
                                              <Touchable
                                                onPress={() => {
                                                  try {
                                                    setShowingSeriesId(-1);
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                activeOpacity={0.8}
                                                disabledOpacity={0.8}
                                              >
                                                {/* container */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: "flex-end",
                                                      flexDirection: "row",
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* status */}
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors[
                                                            "Primary"
                                                          ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {"Hide"}
                                                  </Text>
                                                  <Icon
                                                    name={"AntDesign/up"}
                                                    size={12}
                                                    color={
                                                      theme.colors["Primary"]
                                                    }
                                                  />
                                                </View>
                                              </Touchable>
                                            )}
                                          </>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* Enrolled Count */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              { color: theme.colors.strong },
                                              dimensions.width
                                            )}
                                          >
                                            {countSeriesEnrolled(seriesData)}
                                            {"/"}
                                            {lxpItem?.max_participants}
                                            {" learners enrolled"}
                                          </Text>
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                          {/* Enrolled */}
                                          <>
                                            {!enrolledForWholeSeries(
                                              Variables,
                                              seriesData
                                            ) ? null : (
                                              <Button
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      theme.colors.primary,
                                                    borderRadius: 8,
                                                    fontFamily: "System",
                                                    fontWeight: "700",
                                                    textAlign: "center",
                                                  },
                                                  dimensions.width
                                                )}
                                                disabled={true}
                                                activeOpacity={0.8}
                                                title={"Enrolled"}
                                                disabledOpacity={0.8}
                                              />
                                            )}
                                          </>
                                          {/* Unenrolled actions */}
                                          <>
                                            {!!enrolledForWholeSeries(
                                              Variables,
                                              seriesData
                                            ) ? null : (
                                              <View>
                                                {/* Selected */}
                                                <>
                                                  {!selectedWholeSeries(
                                                    Variables,
                                                    seriesData
                                                  ) ? null : (
                                                    <Button
                                                      onPress={() => {
                                                        try {
                                                          unselectSeries(
                                                            seriesData
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: "System",
                                                          fontWeight: "700",
                                                          textAlign: "center",
                                                        },
                                                        dimensions.width
                                                      )}
                                                      activeOpacity={0.8}
                                                      disabledOpacity={0.8}
                                                      title={"Selected Series"}
                                                    />
                                                  )}
                                                </>
                                                {/* Join */}
                                                <>
                                                  {selectedWholeSeries(
                                                    Variables,
                                                    seriesData
                                                  ) ? null : (
                                                    <Button
                                                      onPress={() => {
                                                        try {
                                                          addWholeSeries(
                                                            Variables,
                                                            seriesData
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: "System",
                                                          fontWeight: "700",
                                                          textAlign: "center",
                                                        },
                                                        dimensions.width
                                                      )}
                                                      activeOpacity={0.8}
                                                      disabledOpacity={0.6}
                                                      title={
                                                        "Join Whole Series"
                                                      }
                                                    />
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        <Spacer
                                          top={8}
                                          right={8}
                                          bottom={8}
                                          left={8}
                                        />
                                      </View>
                                    </>
                                  );
                                }}
                                data={upcomingSeries}
                                listKey={"VwXDJcv5"}
                                keyExtractor={(seriesData) =>
                                  seriesData?.series_no
                                }
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                horizontal={true}
                                inverted={false}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* Empty Sessions */}
                    <>
                      {!!showAvailableTimesSection() ? null : (
                        <View>
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)["Inter Regular"],
                                {
                                  color: theme.colors["Strong"],
                                  fontFamily: "System",
                                  fontSize: 20,
                                  fontWeight: "600",
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"No Upcoming Sessions"}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          <Button
                            onPress={() => {
                              try {
                                navigation.navigate("TeacherProfileScreen", {
                                  handle: lxpItem?.author?.handle,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)[
                                  "button_solid"
                                ],
                                { width: 180 }
                              ),
                              dimensions.width
                            )}
                            title={"Request Session"}
                          />
                        </View>
                      )}
                    </>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                  </View>
                  {/* Required Resources  */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: "100%" },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: "System",
                          fontSize: 24,
                          fontWeight: "600",
                        },
                        dimensions.width
                      )}
                    >
                      {"Required Resources"}
                    </Text>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors.strong },
                        dimensions.width
                      )}
                    >
                      {lxpItem?.required_resources}
                    </Text>
                  </View>
                  <Spacer right={8} left={8} top={20} bottom={20} />
                  {/* Offered By */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Mobile, value: "column" },
                          { minWidth: Breakpoints.Laptop, value: "row" },
                        ],
                        width: "100%",
                      },
                      dimensions.width
                    )}
                  >
                    {/* Provider Details Left */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: "50%" },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              "bold_nav_header_20px"
                            ],
                            { fontSize: 24 }
                          ),
                          dimensions.width
                        )}
                      >
                        {"Offered By "}
                      </Text>
                      <Spacer right={8} left={8} bottom={16} top={16} />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate("TeacherProfileScreen", {
                              handle: lxpItem?.author?.handle,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Profile */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: "row" },
                            dimensions.width
                          )}
                        >
                          <>
                            {!!lxpItem?.author?.profile_picture ? null : (
                              <Icon
                                style={StyleSheet.applyWidth(
                                  { height: 100, width: 100 },
                                  dimensions.width
                                )}
                                size={100}
                                name={"Ionicons/person"}
                                color={theme.colors["Light"]}
                              />
                            )}
                          </>
                          <>
                            {!lxpItem?.author?.profile_picture ? null : (
                              <Image
                                style={StyleSheet.applyWidth(
                                  {
                                    borderRadius: 100,
                                    height: 100,
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                                source={{
                                  uri: `${lxpItem?.author?.profile_picture}`,
                                }}
                                resizeMode={"contain"}
                              />
                            )}
                          </>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          {/* Teacher Name */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: "column" },
                              dimensions.width
                            )}
                          >
                            {/* Name & badge */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: "row" },
                                dimensions.width
                              )}
                            >
                              {/* FirstName LastName */}
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      "bold_nav_header_20px"
                                    ],
                                    { marginLeft: 8 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {lxpItem?.author?.firstName}{" "}
                                {lxpItem?.author?.lastName}
                              </Text>
                              <>
                                {!lxpItem?.author?.is_verfied ? null : (
                                  <Icon
                                    style={StyleSheet.applyWidth(
                                      { marginBottom: 12, marginLeft: 8 },
                                      dimensions.width
                                    )}
                                    size={24}
                                    name={"MaterialIcons/verified"}
                                    color={theme.colors["Primary"]}
                                  />
                                )}
                              </>
                            </View>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* Count Completed Classes */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors["dark gray text"],
                                  fontFamily: "System",
                                  fontSize: 12,
                                  fontWeight: "400",
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {pastSessionCount}
                              {" completed classes"}
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                      {/* Teacher short bio */}
                      <>
                        {!lxpItem?.author?.short_bio ? null : (
                          <View>
                            <Spacer top={8} right={8} bottom={8} left={8} />
                            {/* Bio Bubble */}
                            <>
                              {!lxpItem?.author ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors["gray line"],
                                      borderLeftWidth: 1,
                                      borderRadius: 8,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      paddingBottom: 16,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                      paddingTop: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: "Inter_400Regular",
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {lxpItem?.author?.short_bio}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                    <Spacer top={8} right={8} left={8} bottom={8} />
                    {/* Reviews Right */}
                    <View
                      style={StyleSheet.applyWidth(
                        { maxHeight: 320, minHeight: 300, width: "50%" },
                        dimensions.width
                      )}
                    >
                      {/* Reviews Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: "center", flexDirection: "row" },
                          dimensions.width
                        )}
                      >
                        {/* Star */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                "bold_nav_header_20px"
                              ],
                              { paddingRight: 4 }
                            ),
                            dimensions.width
                          )}
                        >
                          {"⭐️"}
                        </Text>

                        <Text
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              "bold_nav_header_20px"
                            ],
                            dimensions.width
                          )}
                        >
                          {"Reviews"}
                        </Text>
                      </View>
                      <Spacer right={8} left={8} top={8} bottom={8} />
                      <Utils.CustomCodeErrorBoundary>
                        <iframe
                          height="800px"
                          id="testimonialto-city-as-a-school-feedback-light-animated"
                          src="https://embed-v2.testimonial.to/w/city-as-a-school-feedback?animated=on&theme=light&shadowColor=ffffff40"
                          frameborder="0"
                          scrolling="no"
                          width="300px"
                        ></iframe>
                      </Utils.CustomCodeErrorBoundary>
                      {/* Write your review */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  "https://testimonial.to/city-as-a-school-feedback/all"
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: "center",
                                flexDirection: "row",
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)["Text"],
                                  {
                                    color: theme.colors["Primary"],
                                    fontFamily: "System",
                                    fontWeight: "400",
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {"Write Your Review"}
                            </Text>
                            <Icon
                              name={"MaterialIcons/chevron-right"}
                              color={theme.colors["Primary"]}
                              size={20}
                            />
                          </View>
                        </Touchable>
                      </View>
                    </View>
                  </View>
                  <Spacer right={8} left={8} top={20} bottom={20} />
                  {/* Other Classes */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: "flex-start",
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: "flex-start",
                        },
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: '"rgba(0, 0, 0, 0)"',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: '"rgba(0, 0, 0, 0)"',
                          },
                        ],
                        flex: 1,
                        marginBottom: 100,
                        width: { minWidth: Breakpoints.Tablet, value: "100%" },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: "System",
                          fontSize: 24,
                          fontWeight: "600",
                        },
                        dimensions.width
                      )}
                    >
                      {"Other Classes By "}
                      {lxpItem?.author?.firstName} {lxpItem?.author?.lastName}
                    </Text>
                    <Spacer right={8} left={8} bottom={16} top={16} />
                    {/* Empty Container */}
                    <>
                      {!!upcomingLxps?.length ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: "flex-start" },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors["dark gray text"],
                                fontFamily: "System",
                                fontSize: 20,
                                fontWeight: "600",
                              },
                              dimensions.width
                            )}
                          >
                            {"No Upcoming Classes"}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Upcoming mobile */}
                    <>
                      {dimensions.width >= Breakpoints.Tablet ? null : (
                        <FlatList
                          renderItem={({ item }) => {
                            const upcomingMobileData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: {
                                      minWidth: Breakpoints.Laptop,
                                      value: "row",
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        setIsLoading(true);
                                        const newLxp = (
                                          await PublicSchemaApi.getExtendedLXPPOST(
                                            Constants,
                                            { lxp_id: upcomingMobileData?.id }
                                          )
                                        )?.json;

                                        const valueqYodArng =
                                          getUpcommingSeries(newLxp?.series);
                                        setUpcomingSeries(valueqYodArng);
                                        const upSeries = valueqYodArng;
                                        const valueD1XfYd20 =
                                          getUpcomingSessions(
                                            newLxp?.series &&
                                              (newLxp?.series)[0]
                                          );
                                        setUpcomingSessions(valueD1XfYd20);
                                        const upSess = valueD1XfYd20;
                                        setLxpItem(newLxp);
                                        const count = (
                                          await PublicSchemaApi.countProviderPastSessionsPOST(
                                            Constants,
                                            {
                                              provider_uuid:
                                                upcomingMobileData?.author_id,
                                            }
                                          )
                                        )?.json;
                                        setPastSessionCount(count);
                                        const upLxps = (
                                          await PublicSchemaApi.getUpcomingClassesForAProviderPOST(
                                            Constants,
                                            {
                                              curr_lxp_id:
                                                upcomingMobileData?.id,
                                              limit: 3,
                                              provider_uuid:
                                                upcomingMobileData?.author_id,
                                            }
                                          )
                                        )?.json;
                                        setUpcomingLxps(upLxps);
                                        setIsLoading(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  activeOpacity={0.8}
                                  disabledOpacity={0.8}
                                >
                                  {/* LXP card */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          "LXP card"
                                        ],
                                        {
                                          alignContent: "flex-start",
                                          width: 300,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomLeftRadius: 0,
                                          borderBottomRightRadius: 0,
                                          borderRadius: 8,
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          height: 94,
                                          overflow: "hidden",
                                          width: 300,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Image
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            height: "100%",
                                            width: "100%",
                                          },
                                          dimensions.width
                                        )}
                                        source={{
                                          uri: `${upcomingMobileData?.coverimage}`,
                                        }}
                                        resizeMode={"cover"}
                                      />
                                      {/* starting card */}
                                      <>
                                        {!upcomingMobileData?.upcoming_session ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: "flex-end",
                                                backgroundColor:
                                                  theme.colors["Background"],
                                                borderRadius: 8,
                                                bottom: 8,
                                                flexDirection: "row",
                                                left: 4,
                                                padding: 8,
                                                position: "absolute",
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Icon
                                              style={StyleSheet.applyWidth(
                                                { marginBottom: 2 },
                                                dimensions.width
                                              )}
                                              name={
                                                "MaterialCommunityIcons/timer-sand-empty"
                                              }
                                              size={12}
                                            />
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )["Work Sans Regular"],
                                                  {
                                                    color: theme.colors.strong,
                                                    fontFamily:
                                                      "WorkSans_600SemiBold",
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {"Starts "}
                                              {getDayDiff(
                                                upcomingMobileData
                                                  ?.upcoming_session?.start_time
                                              )}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                    <Spacer
                                      right={8}
                                      bottom={8}
                                      left={8}
                                      top={0}
                                    />
                                    {/* Details */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* title */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              "Inter Regular"
                                            ],
                                            {
                                              color: theme.colors["Strong"],
                                              fontFamily: "Inter_700Bold",
                                              fontSize: 16,
                                              lineHeight: 15,
                                              paddingBottom: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        ellipsizeMode={"tail"}
                                        numberOfLines={1}
                                      >
                                        {upcomingMobileData?.title}
                                      </Text>
                                      <Spacer
                                        right={8}
                                        bottom={8}
                                        left={8}
                                        top={0}
                                      />
                                      {/* Meta */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginBottom: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* author */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flex: 1,
                                              flexDirection: "row",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Circle
                                            bgColor={theme.colors.light}
                                            size={24}
                                          >
                                            <>
                                              {!upcomingMobileData?.author
                                                ?.profile_picture ? null : (
                                                <Image
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: "100%",
                                                      width: "100%",
                                                    },
                                                    dimensions.width
                                                  )}
                                                  resizeMode={"contain"}
                                                  source={{
                                                    uri: `${upcomingMobileData?.author?.profile_picture}`,
                                                  }}
                                                />
                                              )}
                                            </>
                                            <>
                                              {upcomingMobileData?.author
                                                ?.profile_picture ? null : (
                                                <Icon
                                                  size={24}
                                                  name={"Ionicons/person"}
                                                  color={
                                                    theme.colors[
                                                      "dark gray text"
                                                    ]
                                                  }
                                                />
                                              )}
                                            </>
                                          </Circle>
                                          {/* full name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                marginLeft: 10,
                                              },
                                              dimensions.width
                                            )}
                                            adjustsFontSizeToFit={true}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {
                                              upcomingMobileData?.author
                                                ?.firstName
                                            }{" "}
                                            {
                                              upcomingMobileData?.author
                                                ?.lastName
                                            }
                                          </Text>
                                        </View>
                                        {/* Starting */}
                                        <>
                                          {!upcomingMobileData?.upcoming_session
                                            ?.start_time ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: "flex-end",
                                                  },
                                                  flex: 1,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.strong,
                                                  },
                                                  dimensions.width
                                                )}
                                                adjustsFontSizeToFit={true}
                                                numberOfLines={1}
                                                ellipsizeMode={"tail"}
                                              >
                                                {dateFormatting(
                                                  upcomingMobileData
                                                    ?.upcoming_session
                                                    ?.start_time,
                                                  "Do MMM HH:mm"
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                      {/* description */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 8, overflow: "hidden" },
                                          dimensions.width
                                        )}
                                      >
                                        {/* description */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors["dark gray text"],
                                            },
                                            dimensions.width
                                          )}
                                          numberOfLines={2}
                                          ellipsizeMode={"tail"}
                                        >
                                          {upcomingMobileData?.description}
                                        </Text>
                                      </View>
                                      <Spacer
                                        right={8}
                                        left={8}
                                        top={0}
                                        bottom={14}
                                      />
                                      {/* Quick Info */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            justifyContent: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: "space-between",
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: "flex-start",
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* sessions */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            name={"AntDesign/calendar"}
                                            color={theme.colors["Strong"]}
                                            size={14}
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {
                                              upcomingMobileData?.up_session_count
                                            }
                                            {" sessions"}
                                          </Text>
                                        </View>
                                        {/* type */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            size={14}
                                            color={theme.colors["Strong"]}
                                            name={
                                              "Ionicons/ios-location-outline"
                                            }
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {getLXPType(
                                              upcomingMobileData?.isvirtual
                                            )}
                                          </Text>
                                        </View>
                                        {/* ages */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"head"}
                                          >
                                            {"Ages "}
                                            {upcomingMobileData?.min_age}
                                            {" - "}
                                            {upcomingMobileData?.max_age}
                                          </Text>
                                        </View>
                                        {/* Price */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            size={14}
                                            name={"Feather/dollar-sign"}
                                            color={theme.colors["Strong"]}
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {getFinalPrice(
                                              Variables,
                                              upcomingMobileData?.pricing_per_session
                                            )}{" "}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                                <Spacer top={8} right={8} bottom={8} left={8} />
                              </View>
                            );
                          }}
                          data={upcomingLxps}
                          listKey={"sI5lEmhf"}
                          keyExtractor={(upcomingMobileData) =>
                            upcomingMobileData?.id
                          }
                          style={StyleSheet.applyWidth(
                            { height: 200, width: "100%" },
                            dimensions.width
                          )}
                          contentContainerStyle={StyleSheet.applyWidth(
                            { alignItems: "flex-start" },
                            dimensions.width
                          )}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          horizontal={false}
                          showsVerticalScrollIndicator={true}
                        />
                      )}
                    </>
                    {/* Upcoming */}
                    <>
                      {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                        <FlatList
                          renderItem={({ item }) => {
                            const upcomingData = item;
                            return (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: {
                                      minWidth: Breakpoints.Laptop,
                                      value: "row",
                                    },
                                    marginRight: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 16,
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const newLxp = (
                                          await PublicSchemaApi.getExtendedLXPPOST(
                                            Constants,
                                            { lxp_id: upcomingData?.id }
                                          )
                                        )?.json;
                                        const count = (
                                          await PublicSchemaApi.countProviderPastSessionsPOST(
                                            Constants,
                                            {
                                              provider_uuid:
                                                upcomingData?.author_id,
                                            }
                                          )
                                        )?.json;
                                        const upLxps = (
                                          await PublicSchemaApi.getUpcomingClassesForAProviderPOST(
                                            Constants,
                                            {
                                              curr_lxp_id: upcomingData?.id,
                                              limit: 3,
                                              provider_uuid:
                                                upcomingData?.author_id,
                                            }
                                          )
                                        )?.json;
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  activeOpacity={0.8}
                                  disabledOpacity={0.8}
                                >
                                  {/* LXP card */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          "LXP card"
                                        ],
                                        {
                                          alignContent: "flex-start",
                                          width: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 200,
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 300,
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomLeftRadius: 0,
                                          borderBottomRightRadius: 0,
                                          borderRadius: 8,
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          height: 94,
                                          overflow: "hidden",
                                          width: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 200,
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 300,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Image
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            height: "100%",
                                            width: "100%",
                                          },
                                          dimensions.width
                                        )}
                                        source={{
                                          uri: `${upcomingData?.coverimage}`,
                                        }}
                                        resizeMode={"cover"}
                                      />
                                      {/* starting card */}
                                      <>
                                        {!upcomingData?.upcoming_session ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: "flex-end",
                                                backgroundColor:
                                                  theme.colors["Background"],
                                                borderRadius: 8,
                                                bottom: 8,
                                                flexDirection: "row",
                                                left: 4,
                                                padding: 8,
                                                position: "absolute",
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Icon
                                              style={StyleSheet.applyWidth(
                                                { marginBottom: 2 },
                                                dimensions.width
                                              )}
                                              name={
                                                "MaterialCommunityIcons/timer-sand-empty"
                                              }
                                              size={12}
                                            />
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )["Work Sans Regular"],
                                                  {
                                                    color: theme.colors.strong,
                                                    fontFamily:
                                                      "WorkSans_600SemiBold",
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {"Starts "}
                                              {getDayDiff(
                                                upcomingData?.upcoming_session
                                                  ?.start_time
                                              )}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                    <Spacer
                                      right={8}
                                      bottom={8}
                                      left={8}
                                      top={0}
                                    />
                                    {/* Details */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* title */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              "Inter Regular"
                                            ],
                                            {
                                              color: theme.colors["Strong"],
                                              fontFamily: "Inter_700Bold",
                                              fontSize: 16,
                                              lineHeight: 15,
                                              paddingBottom: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        ellipsizeMode={"tail"}
                                        numberOfLines={1}
                                      >
                                        {upcomingData?.title}
                                      </Text>
                                      <Spacer
                                        right={8}
                                        bottom={8}
                                        left={8}
                                        top={0}
                                      />
                                      {/* Meta */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginBottom: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 8,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* author */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flex: 1,
                                              flexDirection: "row",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Circle
                                            bgColor={theme.colors.light}
                                            size={24}
                                          >
                                            <>
                                              {!upcomingData?.author
                                                ?.profile_picture ? null : (
                                                <Image
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: "100%",
                                                      width: "100%",
                                                    },
                                                    dimensions.width
                                                  )}
                                                  resizeMode={"contain"}
                                                  source={{
                                                    uri: `${upcomingData?.author?.profile_picture}`,
                                                  }}
                                                />
                                              )}
                                            </>
                                            <>
                                              {upcomingData?.author
                                                ?.profile_picture ? null : (
                                                <Icon
                                                  size={24}
                                                  name={"Ionicons/person"}
                                                  color={
                                                    theme.colors[
                                                      "dark gray text"
                                                    ]
                                                  }
                                                />
                                              )}
                                            </>
                                          </Circle>
                                          {/* full name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                marginLeft: 10,
                                              },
                                              dimensions.width
                                            )}
                                            adjustsFontSizeToFit={true}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {upcomingData?.author?.firstName}{" "}
                                            {upcomingData?.author?.lastName}
                                          </Text>
                                        </View>
                                        {/* Starting */}
                                        <>
                                          {!upcomingData?.upcoming_session
                                            ?.start_time ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: "flex-end",
                                                  },
                                                  flex: 1,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.strong,
                                                  },
                                                  dimensions.width
                                                )}
                                                adjustsFontSizeToFit={true}
                                                numberOfLines={1}
                                                ellipsizeMode={"tail"}
                                              >
                                                {dateFormatting(
                                                  upcomingData?.upcoming_session
                                                    ?.start_time,
                                                  "Do MMM HH:mm"
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                      {/* description */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 8, overflow: "hidden" },
                                          dimensions.width
                                        )}
                                      >
                                        {/* description */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors["dark gray text"],
                                            },
                                            dimensions.width
                                          )}
                                          numberOfLines={2}
                                          ellipsizeMode={"tail"}
                                        >
                                          {upcomingData?.description}
                                        </Text>
                                      </View>
                                      <Spacer
                                        right={8}
                                        left={8}
                                        top={0}
                                        bottom={14}
                                      />
                                      {/* Quick Info */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: "center",
                                            flexDirection: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: "column",
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: "row",
                                              },
                                            ],
                                            justifyContent: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: "space-between",
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: "flex-start",
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* sessions */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            name={"AntDesign/calendar"}
                                            color={theme.colors["Strong"]}
                                            size={14}
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {upcomingData?.up_session_count}
                                            {" sessions"}
                                          </Text>
                                        </View>
                                        {/* type */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            size={14}
                                            color={theme.colors["Strong"]}
                                            name={
                                              "Ionicons/ios-location-outline"
                                            }
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {getLXPType(
                                              upcomingData?.isvirtual
                                            )}
                                          </Text>
                                        </View>
                                        {/* ages */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"head"}
                                          >
                                            {"Ages "}
                                            {upcomingData?.min_age}
                                            {" - "}
                                            {upcomingData?.max_age}
                                          </Text>
                                        </View>
                                        {/* Price */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: "center",
                                              flexDirection: "row",
                                              marginLeft: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 4,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            size={14}
                                            name={"Feather/dollar-sign"}
                                            color={theme.colors["Strong"]}
                                          />
                                          <Spacer
                                            top={8}
                                            bottom={8}
                                            left={8}
                                            right={0}
                                          />
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  "Work Sans Regular"
                                                ],
                                                {
                                                  color: theme.colors["Strong"],
                                                  fontFamily: "System",
                                                  fontSize: 14,
                                                  fontWeight: "400",
                                                  lineHeight: 13,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            numberOfLines={1}
                                            ellipsizeMode={"tail"}
                                          >
                                            {getFinalPrice(
                                              Variables,
                                              upcomingData?.pricing_per_session
                                            )}{" "}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              </View>
                            );
                          }}
                          data={upcomingLxps}
                          listKey={"yCuazzFv"}
                          keyExtractor={(upcomingData) => upcomingData?.id}
                          contentContainerStyle={StyleSheet.applyWidth(
                            { alignItems: "flex-start" },
                            dimensions.width
                          )}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          horizontal={true}
                          showsVerticalScrollIndicator={true}
                        />
                      )}
                    </>
                  </View>
                </View>
              </ScrollView>
            </View>
            {/* Enroll Container */}
            <>
              {!sessionCart?.length ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",
                      backgroundColor: theme.colors["Background"],
                      bottom: 0,
                      height: 123,
                      justifyContent: "space-evenly",
                      left: 0,
                      position: "absolute",
                      width: "100%",
                      zIndex: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Inter Regular"],
                        {
                          color: theme.colors.strong,
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 14,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {"Enrolling in "}
                    {sessionCart?.length}
                    {" sessions"}
                  </Text>
                  {/* Enroll Live */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: "WAITING_CART",
                            value: sessionCart,
                          });
                          if (
                            purchasingAsAnonymousUser(
                              Constants["AUTH_UUID_CAAS"]
                            )
                          ) {
                            return;
                          }
                          setIsPurchasing(true);
                          const intentObj = (
                            await SupabaseEdgeFunctionApi.createAPaymentIntentLivePOST(
                              Constants,
                              {
                                buyer_id: Constants["AUTH_UUID_CAAS"],
                                session_ids: sessionCart,
                              }
                            )
                          )?.json;
                          console.log(intentObj);
                          setIsPurchasing(false);
                          if (!intentObj?.url) {
                            navigation.navigate("ProfileScreen");
                          }
                          if (!intentObj) {
                            return;
                          }
                          openUrlUsingLinking(intentObj?.url);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 8,
                        fontFamily: "System",
                        fontWeight: "700",
                        textAlign: "center",
                        width: 346,
                      },
                      dimensions.width
                    )}
                    loading={isPurchasing}
                    title={"Enroll Now"}
                  />
                </View>
              )}
            </>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(LXPDetailsScreen);
