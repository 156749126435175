import * as Linking from "expo-linking";
const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      path: "",
      initialRouteName: "ExploreScreen",
      ExploreScreen: "explore",
      PasswordResetScreen: {
        path: "password_reset/:accessToken",
        parse: {
          accessToken: (token) => token,
        },
      },
      LearningCenterDetailsScreen: {
        path: "learning_center/:learning_center_id",
        parse: {
          learning_center_id: (id) => id,
        },
      },
      LXPDetailsScreen: {
        path: "lxp_details/:lxp_id",
        parse: {
          lxp_id: (id) => id,
        },
      },
      LoginScreen: {
        path: "login/:redirectPath",
        parse: {
          redirectPath: (path) => path,
        },
      },
      ProfileScreen: {
        path: "profile/:checkoutId/:projectId",
        parse: {
          checkoutId: (info) => info,
          projectId: (pro) => pro,
        },
      },
      TeacherProfileScreen: {
        path: "teacher_profile/:handle",
        parse: {
          handle: (id) => id,
        },
      },
      ExternalWebpageScreen: {
        path: "external/:title/:url",
        parse: {
          title: (title) => title,
          url: (url) => url,
        },
      },
      QuickCreateAccountScreen: "signup",
      RequestPasswordScreen: "request_password",
    },
  },
};

export default linking;
