import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomPackages from '../custom-files/CustomPackages';
import responseErrorExtractor from '../global-functions/responseErrorExtractor';
import uploadImageToSupabaseStorage from '../global-functions/uploadImageToSupabaseStorage';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import {
  Button,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Picker,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const LearnerSettingsScreen_B3fRXmVl = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const isValidForm = () => {
    if (!profileObj.firstName) {
      setFirstNameErr('First Name is Required value!');
      return false;
    } else setFirstNameErr('');

    if (!profileObj.lastName) {
      setLastNameErr('Last Name is Required value!');
      return false;
    } else setLastNameErr('');

    if (!profileObj.username) {
      setUsernameErr('Username is Required value!');
      return false;
    } else setUsernameErr('');

    return true;
  };

  const checkResErr = err => {
    if (err) {
      //stop
      // navigate to login
      CustomPackages.Linking.openURL(
        'https://learn.cityasaschool.com/login/undefined'
      );
      return true;
    } else {
      return false;
    }
  };

  const updateProfileData = (key, value) => {
    setProfileObj(prev => ({ ...prev, [key]: value }));
  };

  const { theme } = props;
  const { navigation } = props;

  const publicSchemaUpdateLearnerProfilePATCH =
    PublicSchemaApi.useUpdateLearnerProfilePATCH();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIsLoading(true);
        const profileREs = (
          await PublicSchemaApi.getUserProfileByIdGET(Constants, {
            user_id: Constants['AUTH_UUID_CAAS'],
          })
        )?.json;
        const resErr = responseErrorExtractor(profileREs);
        if (checkResErr(resErr)) {
          return;
        }
        if (!profileREs?.length) {
          return;
        }
        const profileData = profileREs?.[0];
        setProfileObj(profileData);
        setIsLoading(false);
        setDob(new Date(profileData?.birthday));
        setAvatarUrl(profileData?.profile_picture);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [avatarUrl, setAvatarUrl] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [dob, setDob] = React.useState(new Date());
  const [firstNameErr, setFirstNameErr] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [lastNameErr, setLastNameErr] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [profileObj, setProfileObj] = React.useState({});
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [radioButtonGroupValue2, setRadioButtonGroupValue2] =
    React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [usernameErr, setUsernameErr] = React.useState('');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <>
        {isLoading ? null : (
          <View
            style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
          >
            {/* Header */}
            <>
              {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', height: 72 },
                    dimensions.width
                  )}
                >
                  {/* With Bar */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderColor: theme.colors['Inactive Gray Text'],
                        borderRightWidth: 1,
                        flexDirection: 'row',
                        paddingBottom: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Blue Bar */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Primary'],
                          height: 42,
                          width: 3,
                        },
                        dimensions.width
                      )}
                    />
                    {/* Logo Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ExploreScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Image
                          style={StyleSheet.applyWidth(
                            { height: 32, width: 32 },
                            dimensions.width
                          )}
                          resizeMode={'cover'}
                          source={Images.Group}
                        />
                      </Touchable>
                    </View>
                  </View>
                  {/* Brand & Action */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Inactive Gray Text'],
                        flex: 1,
                        flexDirection: 'row',
                        height: '100%',
                        justifyContent: 'space-between',
                        paddingBottom: 0,
                        paddingLeft: 14,
                        paddingRight: 14,
                        paddingTop: 0,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Work Sans Regular'],
                          {
                            fontFamily: 'System',
                            fontSize: 24,
                            fontWeight: '600',
                            letterSpacing: 0.2,
                            lineHeight: 20,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Settings'}
                    </Text>
                    {/* Top Nav */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* ARK */}
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://ark.cityasaschool.com/'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)['ARK 9'],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            name={'MaterialIcons/auto-awesome'}
                            size={18}
                            color={theme.colors['Strong']}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'AI Copilot'}
                          </Text>
                        </View>
                      </Touchable>
                      {/* Discover Nav */}
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('DiscoverScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)['Discover Nav 3'],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors['Strong']}
                            name={'Feather/grid'}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Discover'}
                          </Text>
                        </View>
                      </Touchable>

                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ExploreScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors['Strong']}
                            name={'Feather/search'}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontSize: 16,
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Experiences'}
                          </Text>
                        </View>
                      </Touchable>
                      <Spacer top={8} bottom={8} right={16} left={0} />
                      {/* Avatar */}
                      <>
                        {!Constants['AUTH_UUID_CAAS'] ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)['logged nav'],
                              dimensions.width
                            )}
                          >
                            {/* Touchable (go to profile) */}
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('ProfileScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants['AUTH_PROFILE_CAAS']
                                  ?.profile_picture ? null : (
                                  <Circle
                                    size={24}
                                    bgColor={theme.colors['Primary']}
                                  >
                                    <>
                                      {!Constants['AUTH_PROFILE_CAAS']
                                        ?.profile_picture ? null : (
                                        <Image
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                              ],
                                              { height: '100%', width: '100%' }
                                            ),
                                            dimensions.width
                                          )}
                                          resizeMode={'cover'}
                                          source={{
                                            uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                                          }}
                                        />
                                      )}
                                    </>
                                    <>
                                      {Constants['AUTH_PROFILE_CAAS']
                                        ?.profile_picture ? null : (
                                        <Icon
                                          size={24}
                                          name={'Ionicons/person'}
                                        />
                                      )}
                                    </>
                                  </Circle>
                                )}
                              </>
                            </Touchable>
                            {/* Logout */}
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await SupabaseAuthApi.logoutPOST(
                                        Constants
                                      )
                                    )?.json;
                                    setGlobalVariableValue({
                                      key: 'AUTH_HEADER_CAAS',
                                      value: '',
                                    });
                                    setGlobalVariableValue({
                                      key: 'AUTH_UUID_CAAS',
                                      value: '',
                                    });
                                    setGlobalVariableValue({
                                      key: 'AUTH_PROFILE_CAAS',
                                      value: {},
                                    });
                                    navigation.navigate('LoginScreen', {
                                      redirectPath: '',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                              icon={'MaterialCommunityIcons/login-variant'}
                              size={24}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* Sidebar */}
              <>
                {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors['Inactive Gray Text'],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 15,
                        paddingLeft: 15,
                        paddingTop: 15,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    <Spacer top={8} right={8} bottom={8} left={8} />
                  </View>
                )}
              </>
              {/* Scroll View Body */}
              <ScrollView
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                bounces={true}
              >
                {/* Body */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors['Background'],
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 1,
                      paddingBottom: 32,
                      paddingLeft: 32,
                      paddingRight: 32,
                      paddingTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  {/* Central Profile */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 360 },
                      dimensions.width
                    )}
                  >
                    {/* Profile Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const avatar = await openImagePickerUtil({});
                              setAvatarUrl(avatar);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          { height: 100, width: 100 },
                          dimensions.width
                        )}
                      >
                        {/* Profile Picture */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              height: 100,
                              paddingBottom: 10,
                              paddingLeft: 14,
                              paddingTop: 10,
                              width: 100,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!avatarUrl ? null : (
                              <Image
                                style={StyleSheet.applyWidth(
                                  {
                                    borderRadius: 200,
                                    height: 100,
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                                resizeMode={'cover'}
                                source={{ uri: `${avatarUrl}` }}
                              />
                            )}
                          </>
                          <>
                            {avatarUrl ? null : (
                              <Circle
                                size={100}
                                bgColor={theme.colors['Divider']}
                              >
                                <Icon name={'Ionicons/person'} size={100} />
                              </Circle>
                            )}
                          </>
                          <View
                            style={StyleSheet.applyWidth(
                              { bottom: 0, position: 'absolute', right: 0 },
                              dimensions.width
                            )}
                          >
                            <Circle
                              size={20}
                              bgColor={theme.colors['CAS Blue']}
                            >
                              <Icon
                                name={'Feather/edit-2'}
                                size={12}
                                color={theme.colors['Background']}
                              />
                            </Circle>
                          </View>
                        </View>
                      </Touchable>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* firstNameLastName */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'First Name'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData(
                                  'firstName',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            defaultValue={profileObj?.firstName}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'input_fieldname_text'
                                ],
                                { color: theme.colors['Error'], fontSize: 12 }
                              ),
                              dimensions.width
                            )}
                          >
                            {firstNameErr}
                          </Text>
                        </View>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        <View>
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Last Name'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData(
                                  'lastName',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            defaultValue={profileObj?.lastName}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'input_fieldname_text'
                                ],
                                { color: theme.colors['Error'], fontSize: 12 }
                              ),
                              dimensions.width
                            )}
                          >
                            {lastNameErr}
                          </Text>
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Handle */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Handle'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData(
                                  'username',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            defaultValue={profileObj?.username}
                          />
                          {/* Error */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'input_fieldname_text'
                                ],
                                { color: theme.colors['Error'], fontSize: 12 }
                              ),
                              dimensions.width
                            )}
                          >
                            {usernameErr}
                          </Text>
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Learning Goal */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Learning Goal'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData(
                                  'learning_goal',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            numberOfLines={4}
                            maxLength={280}
                            multiline={true}
                            defaultValue={profileObj?.learning_goal}
                          />
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong, marginTop: 6 },
                              dimensions.width
                            )}
                          >
                            {'Max 280 characters'}
                          </Text>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                        </View>
                      </View>
                      {/* Personal Website */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Personal Website'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData(
                                  'personal_website',
                                  newTextInputValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            defaultValue={profileObj?.personal_website}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Personal Website */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'City'}
                          </Text>
                          <TextInput
                            onChangeText={newTextInputValue => {
                              try {
                                updateProfileData('city', newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                borderBottomWidth: 1,
                                borderColor: theme.colors.divider,
                                borderLeftWidth: 1,
                                borderRadius: 8,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: theme.colors['Strong'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            autoCapitalize={'none'}
                            placeholder={'Enter a value...'}
                            defaultValue={profileObj?.city}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* Birthday Gender */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* Birthday */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Birthday'}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              { backgroundColor: theme.colors['Light'] },
                              dimensions.width
                            )}
                          >
                            <DatePicker
                              onDateChange={newDatePickerValue => {
                                try {
                                  setDob(newDatePickerValue);
                                  updateProfileData(
                                    'birhtday',
                                    newDatePickerValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { backgroundColor: theme.colors['Divider'] },
                                dimensions.width
                              )}
                              date={dob}
                              mode={'date'}
                              label={'Date'}
                              leftIconMode={'inset'}
                              type={'solid'}
                            />
                          </View>
                        </View>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* Gender */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '45%' },
                            dimensions.width
                          )}
                        >
                          {/* First Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {'Gender'}
                          </Text>
                          <Picker
                            onValueChange={newPickerValue => {
                              const pickerValue = newPickerValue;
                              try {
                                updateProfileData('gender', newPickerValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            options={['Male', 'Female', 'Other']}
                            leftIconMode={'inset'}
                            type={'solid'}
                            iconSize={24}
                            autoDismissKeyboard={true}
                            placeholder={'Gender'}
                            defaultValue={profileObj?.gender}
                          />
                        </View>
                      </View>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      {/* I'm currently a */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          {/* Currently a */}
                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'input_fieldname_text'
                              ],
                              dimensions.width
                            )}
                          >
                            {"I'm currently a "}
                          </Text>

                          <RadioButtonGroup
                            onValueChange={newRadioButtonGroupValue => {
                              try {
                                updateProfileData(
                                  'learning_context',
                                  newRadioButtonGroupValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            defaultValue={profileObj?.learning_context}
                          >
                            <RadioButtonRow
                              value={'Secondary High Schooler'}
                              label={'Secondary / High Schooler'}
                              color={theme.colors.primary}
                              unselectedColor={
                                theme.colors['Inactive Gray Text']
                              }
                            />
                            <RadioButtonRow
                              value={'University Student'}
                              label={'University Student'}
                              color={theme.colors.primary}
                              unselectedColor={
                                theme.colors['Inactive Gray Text']
                              }
                            />
                            <RadioButtonRow
                              value={'Homeschooler'}
                              label={'Homeschooler'}
                              color={theme.colors.primary}
                              unselectedColor={
                                theme.colors['Inactive Gray Text']
                              }
                            />
                            <RadioButtonRow
                              value={'Early Stage Professional'}
                              label={'Early Stage Professional'}
                              color={theme.colors.primary}
                              unselectedColor={
                                theme.colors['Inactive Gray Text']
                              }
                            />
                          </RadioButtonGroup>
                        </View>
                      </View>
                      <Spacer right={8} left={8} top={16} bottom={16} />
                      {/* Save Button */}
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (!isValidForm()) {
                                return;
                              }
                              setIsUploading(true);
                              const imgUrl = await uploadImageToSupabaseStorage(
                                avatarUrl,
                                'avatar'
                              );
                              (
                                await publicSchemaUpdateLearnerProfilePATCH.mutateAsync(
                                  {
                                    avatar_url: imgUrl,
                                    birthday: dob,
                                    city: profileObj?.city,
                                    context: profileObj?.learning_context,
                                    first_name: profileObj?.firstName,
                                    gender: profileObj?.gender,
                                    goal: profileObj?.learning_goal,
                                    last_name: profileObj?.lastName,
                                    personal_website:
                                      profileObj?.personal_website,
                                    username: profileObj?.username,
                                    uuid: Constants['AUTH_UUID_CAAS'],
                                  }
                                )
                              )?.json;
                              setIsUploading(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            textAlign: 'center',
                            width: '50%',
                          },
                          dimensions.width
                        )}
                        loading={isUploading}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                        title={'Save Changes '}
                      />
                    </View>
                    {/* Help */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'System',
                            fontSize: 24,
                            fontWeight: '600',
                          },
                          dimensions.width
                        )}
                      >
                        {'Help'}
                      </Text>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://cityasaschool.notion.site/CAS-Helpdesk-7c6fe8b8380846288ecbae31bd74d566'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {'Help Documents'}
                          </Text>
                          <Icon
                            size={24}
                            color={theme.colors['Strong']}
                            name={'MaterialIcons/chevron-right'}
                          />
                        </View>
                      </Touchable>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('RequestPasswordScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {'Reset Password'}
                          </Text>
                          <Icon
                            size={24}
                            color={theme.colors['Strong']}
                            name={'MaterialIcons/chevron-right'}
                          />
                        </View>
                      </Touchable>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://go.crisp.chat/chat/embed/?website_id=5da7a8de-200b-43f5-80df-d44a951658f2'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {'Contact Us'}
                          </Text>
                          <Icon
                            size={24}
                            color={theme.colors['Strong']}
                            name={'MaterialIcons/chevron-right'}
                          />
                        </View>
                      </Touchable>
                      <Spacer top={8} right={8} bottom={8} left={8} />
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://city-as-a-school.canny.io/feature-requests'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'System',
                                fontSize: 16,
                                fontWeight: '400',
                              },
                              dimensions.width
                            )}
                          >
                            {'Request New Features'}
                          </Text>
                          <Icon
                            size={24}
                            color={theme.colors['Strong']}
                            name={'MaterialIcons/chevron-right'}
                          />
                        </View>
                      </Touchable>
                      <Spacer right={8} left={8} bottom={16} top={16} />
                      {/* Logout */}
                      <Button
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'AUTH_HEADER_CAAS',
                              value: '',
                            });
                            setGlobalVariableValue({
                              key: 'AUTH_UUID_CAAS',
                              value: '',
                            });
                            setGlobalVariableValue({
                              key: 'AUTH_PROFILE_CAAS',
                              value: {},
                            });
                            navigation.navigate('LoginScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            textAlign: 'center',
                            width: '50%',
                          },
                          dimensions.width
                        )}
                        loading={isUploading}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                        title={'Logout '}
                      />
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
          </View>
        )}
      </>
      <>
        {!isLoading ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={'large'}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(LearnerSettingsScreen_B3fRXmVl);
