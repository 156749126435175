import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomPackages from '../custom-files/CustomPackages';
import dateFormatting from '../global-functions/dateFormatting';
import getDayDiff from '../global-functions/getDayDiff';
import getTimezonOffset from '../global-functions/getTimezonOffset';
import responseErrorExtractor from '../global-functions/responseErrorExtractor';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Circle,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const TeacherProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getSessionFormattedEndTime = (session_item, format) => {
    return CustomPackages.moment(session_item.session_start)
      .add(session_item.session_duration, 'minutes')
      .format(format);
  };

  const checkResponseErr = err => {
    console.log('profile response error', err);
    if (err) {
      // the user is not logged
      props.navigation.navigate('LoginScreen');
      // stop
      return true;
    }
    return false;
  };

  const getProvideTypeLabel = isVirtual => {
    return isVirtual ? 'Zoom' : 'Inperson';
  };

  const applyUpcomingFilter = lxps => {
    // console.log(lxps.length, lxps.filter(item=> currStatus ? item.upcoming_session : item.upcoming_session == null).length)

    // setFilteredLxps(lxps.filter(item=> currStatus ? item.upcoming_session : item.upcoming_session == null))
    // setFilteredLxps(lxps.filter(item=> currStatus ? item.upcoming_session : item.upcoming_session == null))
    return lxps && lxps.length
      ? lxps.filter(item =>
          showActiveClasses
            ? item.upcoming_session
            : item.upcoming_session == null
        )
      : [];
  };

  const { theme } = props;
  const { navigation } = props;

  const publicSchemaDirectMessageATeacherPOST =
    PublicSchemaApi.useDirectMessageATeacherPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIsLoading(true);
        const teacher_profile_GET = (
          await PublicSchemaApi.getTeacher$sProfileByUsernameGET(Constants, {
            username: props.route?.params?.handle ?? 'CAS',
          })
        )?.json;

        const valueNIYaJPNq = teacher_profile_GET && teacher_profile_GET[0];
        setTeacher_profile(valueNIYaJPNq);
        const teacher_pro = valueNIYaJPNq;
        const count = (
          await PublicSchemaApi.countProviderPastSessionsPOST(Constants, {
            provider_uuid: teacher_pro?.user_id,
          })
        )?.json;
        setPastSessionCount(count);
        const lxps = (
          await PublicSchemaApi.getLxpsForProviderPOST(Constants, {
            provider_id: teacher_pro?.user_id,
          })
        )?.json;
        const lxpErr = responseErrorExtractor(lxps);
        if (checkResponseErr(lxpErr)) {
          return;
        }
        setLxps(lxps);
        setIsLoading(false);
        console.log(props.route?.params?.handle ?? 'CAS');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [filteredLxps, setFilteredLxps] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lxps, setLxps] = React.useState([]);
  const [newMessage, setNewMessage] = React.useState('');
  const [pastSessionCount, setPastSessionCount] = React.useState(0);
  const [sendingMessage, setSendingMessage] = React.useState(false);
  const [showActiveClasses, setShowActiveClasses] = React.useState(true);
  const [teacher_profile, setTeacher_profile] = React.useState({});

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Container */}
      <>
        {isLoading ? null : (
          <View
            style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
          >
            {/* Mobile Header */}
            <>
              {dimensions.width >= Breakpoints.Tablet ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      paddingLeft: 32,
                      paddingTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  {/* Back */}
                  <IconButton
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'AntDesign/left'}
                    color={theme.colors['Not Black']}
                  />
                  {/* Tittle */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Inter Bold 20 Title'],
                        {
                          fontFamily: 'System',
                          fontWeight: '600',
                          lineHeight: 22,
                          marginLeft: 8,
                          marginRight: 8,
                        }
                      ),
                      dimensions.width
                    )}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                  >
                    {null}
                  </Text>
                </View>
              )}
            </>
            {/* Header */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', height: 72 },
                    dimensions.width
                  )}
                >
                  {/* With Bar */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderColor: theme.colors['Inactive Gray Text'],
                        borderRightWidth: 1,
                        flexDirection: 'row',
                        paddingBottom: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Blue Bar */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Primary'],
                          height: 42,
                          width: 3,
                        },
                        dimensions.width
                      )}
                    />
                    {/* Logo Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ExploreScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Image
                          style={StyleSheet.applyWidth(
                            { height: 32, width: 32 },
                            dimensions.width
                          )}
                          resizeMode={'cover'}
                          source={Images.Group}
                        />
                      </Touchable>
                    </View>
                  </View>
                  {/* Brand & Action */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Inactive Gray Text'],
                        flex: 1,
                        flexDirection: 'row',
                        height: '100%',
                        justifyContent: 'space-between',
                        paddingBottom: 0,
                        paddingLeft: 14,
                        paddingRight: 14,
                        paddingTop: 0,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Work Sans Regular'],
                          {
                            fontFamily: 'Questrial_400Regular',
                            fontSize: 24,
                            letterSpacing: 0.2,
                            lineHeight: 20,
                            textTransform: 'uppercase',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'city as a school'}
                    </Text>
                    {/* Nav */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* ARK */}
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                'https://ark.cityasaschool.com/'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)['ARK 5'],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            name={'MaterialIcons/auto-awesome'}
                            size={18}
                            color={theme.colors['Strong']}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'AI Copilot'}
                          </Text>
                        </View>
                      </Touchable>
                      {/* Discover Nav */}
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('DiscoverScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)['Discover Nav 4'],
                          dimensions.width
                        )}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginRight: 0,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors['Strong']}
                            name={'Feather/grid'}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Discover'}
                          </Text>
                        </View>
                      </Touchable>

                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ExploreScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        activeOpacity={0.8}
                        disabledOpacity={0.8}
                      >
                        {/* Discover */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={18}
                            color={theme.colors['Strong']}
                            name={'Feather/search'}
                          />
                          <Spacer top={8} right={8} bottom={8} left={0} />
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Inter Regular'],
                                {
                                  color: theme.colors.strong,
                                  fontSize: 16,
                                  lineHeight: 19,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Experiences'}
                          </Text>
                        </View>
                      </Touchable>
                      <Spacer top={8} bottom={8} left={16} right={16} />
                      {/* Join */}
                      <>
                        {Constants['AUTH_UUID_CAAS'] ? null : (
                          <Link
                            onPress={() => {
                              try {
                                navigation.navigate('LoginScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  fontFamily: 'Inter_500Medium',
                                  fontSize: 16,
                                  lineHeight: 19,
                                  marginLeft: 12,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Join'}
                          />
                        )}
                      </>
                      {/* Avatar */}
                      <>
                        {!Constants['AUTH_UUID_CAAS'] ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Touchable (go to setting) */}
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('ProfileScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <>
                                {!Constants['AUTH_PROFILE_CAAS']
                                  ?.profile_picture ? null : (
                                  <Circle
                                    size={24}
                                    bgColor={theme.colors['Primary']}
                                  >
                                    <>
                                      {!Constants['AUTH_PROFILE_CAAS']
                                        ?.profile_picture ? null : (
                                        <Image
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                              ],
                                              { height: '100%', width: '100%' }
                                            ),
                                            dimensions.width
                                          )}
                                          resizeMode={'cover'}
                                          source={{
                                            uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                                          }}
                                        />
                                      )}
                                    </>
                                    <>
                                      {Constants['AUTH_PROFILE_CAAS']
                                        ?.profile_picture ? null : (
                                        <Icon
                                          size={24}
                                          name={'Ionicons/person'}
                                        />
                                      )}
                                    </>
                                  </Circle>
                                )}
                              </>
                            </Touchable>
                            {/* Logout */}
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await SupabaseAuthApi.logoutPOST(
                                        Constants
                                      )
                                    )?.json;
                                    setGlobalVariableValue({
                                      key: 'AUTH_HEADER_CAAS',
                                      value: '',
                                    });
                                    setGlobalVariableValue({
                                      key: 'AUTH_UUID_CAAS',
                                      value: '',
                                    });
                                    setGlobalVariableValue({
                                      key: 'AUTH_PROFILE_CAAS',
                                      value: {},
                                    });
                                    navigation.navigate('LoginScreen', {
                                      redirectPath: '',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                              icon={'MaterialCommunityIcons/login-variant'}
                              size={24}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* Sidebar */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors['Inactive Gray Text'],
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 15,
                        paddingLeft: 15,
                        paddingTop: 15,
                        width: 72,
                      },
                      dimensions.width
                    )}
                  >
                    <Spacer top={8} right={8} bottom={8} left={8} />
                  </View>
                )}
              </>
              {/* Body */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                <ScrollView
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      paddingBottom: 32,
                      paddingLeft: 32,
                      paddingRight: 32,
                      paddingTop: 40,
                    },
                    dimensions.width
                  )}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  bounces={true}
                >
                  {/* Central Profile */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: { minWidth: Breakpoints.Tablet, value: 550 } },
                      dimensions.width
                    )}
                  >
                    {/* Picture & Name */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Picture and Name */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            paddingBottom: 10,
                            paddingLeft: 14,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {teacher_profile?.profile_picture ? null : (
                            <Icon
                              name={'Ionicons/person'}
                              color={theme.colors['dark gray text']}
                              size={100}
                            />
                          )}
                        </>
                        <>
                          {!teacher_profile?.profile_picture ? null : (
                            <Image
                              style={StyleSheet.applyWidth(
                                { borderRadius: 200, height: 100, width: 100 },
                                dimensions.width
                              )}
                              resizeMode={'cover'}
                              source={{
                                uri: `${teacher_profile?.profile_picture}`,
                              }}
                            />
                          )}
                        </>
                        <Spacer top={8} bottom={8} left={8} right={8} />
                        {/* Name & Handel */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* Name Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'flex-start',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                ],
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'column',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'row',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { overflow: 'hidden', width: '60%' },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  { color: theme.colors.strong, fontSize: 24 },
                                  dimensions.width
                                )}
                                ellipsizeMode={'tail'}
                                numberOfLines={2}
                              >
                                {teacher_profile?.firstName}{' '}
                                {teacher_profile?.lastName}
                              </Text>
                            </View>
                            <Spacer top={8} bottom={8} left={4} right={0} />
                            <>
                              {!teacher_profile?.is_verified ? null : (
                                <Icon
                                  size={24}
                                  name={'MaterialIcons/verified'}
                                  color={theme.colors['Primary']}
                                />
                              )}
                            </>
                          </View>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Inactive Gray Text'],
                                fontFamily: 'System',
                                fontWeight: '400',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {pastSessionCount}
                            {' completed classes'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* Bio Contact */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Speech Bubble */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Inactive Gray Text'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['gray line'],
                              },
                            ],
                            borderRadius: 8,
                            borderWidth: 1,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* postDate */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Inter Regular'],
                              { color: theme.colors['Strong'], marginTop: 0 }
                            ),
                            dimensions.width
                          )}
                        >
                          {teacher_profile?.short_bio}
                        </Text>
                        {/* Message field */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              flexDirection: 'row',
                              marginTop: 40,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <TextInput
                              onChangeText={newTextInputValue => {
                                try {
                                  setNewMessage(newTextInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ],
                                  {
                                    backgroundColor: theme.colors['gray line'],
                                    borderRadius: 12,
                                    fontFamily: 'System',
                                    fontWeight: '400',
                                    paddingBottom: 12,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 12,
                                  }
                                ),
                                dimensions.width
                              )}
                              value={newMessage}
                              placeholder={'Message Directly'}
                              autoCapitalize={'none'}
                              placeholderTextColor={
                                theme.colors['dark gray text']
                              }
                            />
                          </View>
                          {/* Send */}
                          <>
                            {sendingMessage ? null : (
                              <IconButton
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setSendingMessage(true);
                                      (
                                        await publicSchemaDirectMessageATeacherPOST.mutateAsync(
                                          {
                                            content: newMessage,
                                            teacher_id:
                                              teacher_profile?.user_id,
                                          }
                                        )
                                      )?.json;
                                      setSendingMessage(false);
                                      navigation.navigate('InboxScreen');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 16, opacity: 1 },
                                  dimensions.width
                                )}
                                disabled={!newMessage?.length}
                                icon={
                                  'MaterialCommunityIcons/send-circle-outline'
                                }
                                size={40}
                                color={theme.colors['Primary']}
                              />
                            )}
                          </>
                          <>
                            {!sendingMessage ? null : (
                              <ActivityIndicator
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ],
                                  dimensions.width
                                )}
                                size={'small'}
                                animating={true}
                                hidesWhenStopped={true}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                    {/* Button Row */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          marginBottom: 16,
                          marginTop: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Book Mentor Call */}
                      <>
                        {!teacher_profile?.calendly_url ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    'https://airtable.com/shrRQGWkgEqnxXgVd'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor:
                                    theme.colors['dark gray text'],
                                  borderRadius: 20,
                                  color: theme.colors['Background'],
                                  width: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 300,
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 300,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                            icon={'AntDesign/calendar'}
                            title={'Book 1-1 Mentor Call'}
                          />
                        )}
                      </>
                    </View>
                    {/* About Me */}
                    <View>
                      {/* About me */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              'Inter Bold 20 Title'
                            ],
                            { marginBottom: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'About me'}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          { overflow: 'hidden' },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            dimensions.width
                          )}
                        >
                          {teacher_profile?.about_me}
                        </Text>
                      </View>
                    </View>
                    {/* Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 40, marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      {/* Location */}
                      <>
                        {!teacher_profile?.location ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginBottom: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              style={StyleSheet.applyWidth(
                                { marginRight: 16 },
                                dimensions.width
                              )}
                              name={'Ionicons/earth-sharp'}
                              size={20}
                              color={theme.colors['dark gray text']}
                            />
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'Inter Bold 20 Title'
                                  ],
                                  {
                                    color: theme.colors['dark gray text'],
                                    fontFamily: 'Inter_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 16,
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Lives in '}
                              {teacher_profile?.location}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* Start Date */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          style={StyleSheet.applyWidth(
                            { marginRight: 16 },
                            dimensions.width
                          )}
                          name={'Entypo/calendar'}
                          size={20}
                          color={theme.colors['dark gray text']}
                        />
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'Inter Bold 20 Title'
                              ],
                              {
                                color: theme.colors['dark gray text'],
                                fontFamily: 'Inter_400Regular',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Joined '}
                          {dateFormatting(
                            teacher_profile?.created_at,
                            'MMMM YYYY'
                          )}
                        </Text>
                      </View>
                    </View>
                    {/* Classes */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 32 },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              'Inter Bold 20 Title'
                            ],
                            {
                              alignSelf: 'flex-start',
                              marginBottom: 16,
                              textAlign: 'left',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Classes'}
                      </Text>
                      {/* Tabs row */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Tabs */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* Active */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Clicked */}
                            <>
                              {!showActiveClasses ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 2,
                                      borderColor: theme.colors['Primary'],
                                      paddingBottom: 12,
                                      paddingLeft: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                      paddingRight: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'Inter Regular'
                                        ],
                                        {
                                          color: theme.colors['Primary'],
                                          lineHeight: 17,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Active'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* Not Clicked */}
                            <>
                              {showActiveClasses ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Inactive Gray Text'],
                                      paddingBottom: 12,
                                      paddingLeft: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      ],
                                      paddingRight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowActiveClasses(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      { height: '100%', width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Inter Regular'
                                          ],
                                          {
                                            color:
                                              theme.colors['dark gray text'],
                                            lineHeight: 17,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Active'}
                                    </Text>
                                  </Touchable>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Past */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Not Clicked */}
                            <>
                              {!showActiveClasses ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Inactive Gray Text'],
                                      paddingBottom: 12,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowActiveClasses(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      { height: '100%', width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Inter Regular'
                                          ],
                                          {
                                            color:
                                              theme.colors['dark gray text'],
                                            lineHeight: 17,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Past'}
                                    </Text>
                                  </Touchable>
                                </View>
                              )}
                            </>
                            {/* Clicked */}
                            <>
                              {showActiveClasses ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 2,
                                      borderColor: theme.colors['Primary'],
                                      paddingBottom: 12,
                                      paddingLeft: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                      paddingRight: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'Inter Regular'
                                        ],
                                        {
                                          color: theme.colors['Primary'],
                                          lineHeight: 17,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Past'}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                        {/* Divider */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Divider
                            style={StyleSheet.applyWidth(
                              GlobalStyles.DividerStyles(theme)['Divider'],
                              dimensions.width
                            )}
                            color={theme.colors['Inactive Gray Text']}
                          />
                        </View>
                      </View>
                      {/* LXPs */}
                      <FlatList
                        renderItem={({ item }) => {
                          const lXPsData = item;
                          return (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('LXPDetailsScreen', {
                                    lxp_id: lXPsData?.id,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row', marginBottom: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Image */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 94,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 94,
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 150,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 228,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 228,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        height: '100%',
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                    source={{ uri: `${lXPsData?.coverimage}` }}
                                    resizeMode={'cover'}
                                  />
                                  {/* starting card */}
                                  <>
                                    {!lXPsData?.upcoming_session ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-end',
                                            backgroundColor:
                                              theme.colors['Background'],
                                            borderRadius: 8,
                                            bottom: 8,
                                            flexDirection: 'row',
                                            left: 4,
                                            marginRight: 4,
                                            minWidth: 140,
                                            padding: 8,
                                            position: 'absolute',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          style={StyleSheet.applyWidth(
                                            { marginBottom: 2 },
                                            dimensions.width
                                          )}
                                          name={
                                            'MaterialCommunityIcons/timer-sand-empty'
                                          }
                                          size={12}
                                        />
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Work Sans Regular'
                                              ],
                                              {
                                                color: theme.colors.strong,
                                                fontFamily:
                                                  'WorkSans_600SemiBold',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          ellipsizeMode={'head'}
                                        >
                                          {'Starts '}
                                          {getDayDiff(
                                            lXPsData?.upcoming_session
                                              ?.start_time
                                          )}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                </View>
                                {/* Info */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      paddingLeft: 12,
                                      paddingRight: 6,
                                      paddingTop: 6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    {/* Title */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Inter Bold 20 Title'
                                          ],
                                          {
                                            alignSelf: 'flex-start',
                                            fontSize: 14,
                                            lineHeight: 15,
                                            textAlign: 'left',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      ellipsizeMode={'tail'}
                                    >
                                      {lXPsData?.title}
                                    </Text>
                                  </View>
                                  {/* Duration */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        marginBottom: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 8,
                                        },
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 10,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 12,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* starts on */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Inter Regular'
                                          ],
                                          {
                                            color:
                                              theme.colors['dark gray text'],
                                            fontSize: 14,
                                            lineHeight: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {dateFormatting(
                                        lXPsData?.upcoming_session?.start_time,
                                        'DD MMM HH:mm'
                                      )}
                                    </Text>
                                  </View>
                                  {/* Meta */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 10,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 8,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Series */}
                                    <>
                                      {lXPsData?.isOneTime ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              marginRight: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            name={'Entypo/calendar'}
                                            size={12}
                                            color={
                                              theme.colors['dark gray text']
                                            }
                                          />
                                          {/* count */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Work Sans Regular'
                                                ],
                                                {
                                                  color: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        theme.colors[
                                                          'Custom Color_3'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value:
                                                        theme.colors[
                                                          'dark gray text'
                                                        ],
                                                    },
                                                  ],
                                                  fontSize: 10,
                                                  lineHeight: 13,
                                                  marginLeft: 6,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {lXPsData?.series?.length}
                                            {' Series'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Sessions */}
                                    <>
                                      {!lXPsData?.isOneTime ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              marginRight: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 8,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            name={'Entypo/calendar'}
                                            size={12}
                                            color={
                                              theme.colors['dark gray text']
                                            }
                                          />
                                          {/* count */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Work Sans Regular'
                                                ],
                                                {
                                                  color: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        theme.colors[
                                                          'Custom Color_3'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value:
                                                        theme.colors[
                                                          'dark gray text'
                                                        ],
                                                    },
                                                  ],
                                                  fontSize: 10,
                                                  lineHeight: 13,
                                                  marginLeft: 6,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {lXPsData?.up_session_count}
                                            {' Sessions'}
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* Provide Type */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          marginRight: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 8,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        size={12}
                                        name={'Ionicons/location-outline'}
                                        color={theme.colors['dark gray text']}
                                      />
                                      {/* count */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Work Sans Regular'
                                            ],
                                            {
                                              color: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value:
                                                    theme.colors[
                                                      'Custom Color_3'
                                                    ],
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    theme.colors[
                                                      'dark gray text'
                                                    ],
                                                },
                                              ],
                                              fontSize: 10,
                                              lineHeight: 13,
                                              marginLeft: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {getProvideTypeLabel(
                                          lXPsData?.isvirtual
                                        )}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </Touchable>
                          );
                        }}
                        data={applyUpcomingFilter(lxps)}
                        listKey={'OskOSwj2'}
                        keyExtractor={lXPsData => lXPsData?.id}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { paddingTop: 16 },
                          dimensions.width
                        )}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </View>
                    {/* Reviews */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start', maxHeight: 320 },
                        dimensions.width
                      )}
                    >
                      {/* Review Title Row */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Star */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                alignSelf: 'flex-start',
                                fontSize: 20,
                                marginRight: 8,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'⭐️'}
                        </Text>
                        {/* Title */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'Inter Bold 20 Title'
                              ],
                              {
                                alignSelf: 'flex-start',
                                marginBottom: 0,
                                textAlign: 'left',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Reviews'}
                        </Text>
                      </View>
                      <Utils.CustomCodeErrorBoundary>
                        <iframe
                          height="800px"
                          id="testimonialto-city-as-a-school-feedback-light-animated"
                          src="https://embed-v2.testimonial.to/w/city-as-a-school-feedback?animated=on&theme=light&shadowColor=ffffff40"
                          frameborder="0"
                          scrolling="no"
                          width="100%"
                        ></iframe>
                      </Utils.CustomCodeErrorBoundary>
                      {/* Write your review */}
                      <View
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)[
                            'Write your review touchable'
                          ],
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  'https://testimonial.to/city-as-a-school-feedback/all'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'center',
                                flexDirection: 'row',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'System',
                                    fontWeight: '400',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Write Your Review'}
                            </Text>
                            <Icon
                              name={'MaterialIcons/chevron-right'}
                              color={theme.colors['Primary']}
                              size={20}
                            />
                          </View>
                        </Touchable>
                      </View>
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          </View>
        )}
      </>
      <>
        {!isLoading ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={'large'}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(TeacherProfileScreen);
