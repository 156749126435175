import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import DiscoverScreen from './screens/DiscoverScreen';
import ExploreScreen from './screens/ExploreScreen';
import InboxScreen from './screens/InboxScreen';
import LXPDetailsScreen from './screens/LXPDetailsScreen';
import LearnerSettingsScreen_B3fRXmVl from './screens/LearnerSettingsScreen_B3fRXmVl';
import LearningCentreDetailsScreen from './screens/LearningCentreDetailsScreen';
import LoginScreen from './screens/LoginScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import ProfileScreen from './screens/ProfileScreen';
import PurchaseProcessingScreen from './screens/PurchaseProcessingScreen';
import QuickCreateAccountScreen from './screens/QuickCreateAccountScreen';
import RequestPasswordScreen from './screens/RequestPasswordScreen';
import TeacherProfileScreen from './screens/TeacherProfileScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Placeholder() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#131A2A',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 36,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 12,
          color: '#FFF',
        }}
      >
        Missing Screen
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        This screen is not in a navigator.
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        Go to Navigation mode, and click the + (plus) icon in the Navigator tab
        on the left side to add this screen to a Navigator.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, color: '#FFF' }}>
        If the screen is in a Tab Navigator, make sure the screen is assigned to
        a tab in the Config panel on the right.
      </Text>
    </View>
  );
}
export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        mode="card"
        headerMode="none"
        initialRouteName="ExploreScreen"
      >
        <Stack.Screen
          name="ExploreScreen"
          component={ExploreScreen}
          options={{
            title: 'Explore',
          }}
        />
        <Stack.Screen
          name="ProfileScreen"
          component={ProfileScreen}
          options={{
            title: 'Profile',
          }}
        />
        <Stack.Screen
          name="PasswordResetScreen"
          component={PasswordResetScreen}
          options={{
            title: 'Password Reset',
          }}
        />
        <Stack.Screen
          name="RequestPasswordScreen"
          component={RequestPasswordScreen}
          options={{
            title: 'Request Password',
          }}
        />
        <Stack.Screen
          name="LearnerSettingsScreen_B3fRXmVl"
          component={LearnerSettingsScreen_B3fRXmVl}
          options={{
            title: 'LearnerSettings',
          }}
        />
        <Stack.Screen
          name="QuickCreateAccountScreen"
          component={QuickCreateAccountScreen}
          options={{
            title: 'Quick Create Account',
          }}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            title: 'Login',
          }}
        />
        <Stack.Screen
          name="PurchaseProcessingScreen"
          component={PurchaseProcessingScreen}
          options={{
            title: 'Purchase Processing',
          }}
        />
        <Stack.Screen
          name="LearningCentreDetailsScreen"
          component={LearningCentreDetailsScreen}
          options={{
            title: 'Learning Centre Details',
          }}
        />
        <Stack.Screen
          name="TeacherProfileScreen"
          component={TeacherProfileScreen}
          options={{
            title: 'Teacher Profile',
          }}
        />
        <Stack.Screen
          name="DiscoverScreen"
          component={DiscoverScreen}
          options={{
            title: 'Discover',
          }}
        />
        <Stack.Screen
          name="InboxScreen"
          component={InboxScreen}
          options={{
            title: 'Inbox',
          }}
        />
        <Stack.Screen
          name="LXPDetailsScreen"
          component={LXPDetailsScreen}
          options={{
            title: 'LXP Details',
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
