import React from 'react';

// Count number of students enrolled for different sessions for a series
const countSeriesEnrolled = seriesItem => {
  let ids = [];

  seriesItem.sessions.map(({ enrolled_ids }) => {
    if (enrolled_ids)
      ids = [...ids, ...enrolled_ids.filter(id => !ids.includes(id))];
  });

  return ids.length;
};

export default countSeriesEnrolled;
