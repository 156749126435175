import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const TouchableStyles = theme =>
  StyleSheet.create({
    ARK: { marginRight: 16 },
    'ARK 2': { marginRight: 16 },
    'ARK 3': { marginRight: 16 },
    'ARK 4': { marginRight: 16 },
    'ARK 5': { marginRight: 16 },
    'ARK 6': { marginRight: 16 },
    'ARK 7': { marginRight: 16 },
    'ARK 8': { marginRight: 16 },
    'ARK 9': { marginRight: 16 },
    'Discover Nav': { marginRight: 16 },
    'Discover Nav 2': { marginRight: 16 },
    'Discover Nav 3': { marginRight: 16 },
    'Discover Nav 4': { marginRight: 16 },
    'Discover Nav 5': { marginRight: 16 },
    'Discover Nav 6': { marginRight: 16 },
    'Discover Nav 7': { marginRight: 16 },
    'Discover Nav 8': { marginRight: 16 },
    'LXP Card Touchable': { marginBottom: 16, width: '50%' },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({ 'Activity Indicator': { height: 36, width: 36 } });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    button_solid: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      textAlign: 'center',
      width: '100%',
    },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { height: 1 } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const ImageStyles = theme =>
  StyleSheet.create({ Image: { height: 100, width: 100 } });

export const TextStyles = theme =>
  StyleSheet.create({
    'Inter Bold 20 Title': { fontFamily: 'Inter_600SemiBold', fontSize: 20 },
    'Inter Regular': { fontFamily: 'Inter_400Regular' },
    'Work Sans Regular': { fontFamily: 'WorkSans_400Regular' },
    bold_nav_header_20px: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 20,
      fontWeight: '600',
    },
    input_fieldname_text: {
      color: theme.colors['dark gray text'],
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '400',
      marginBottom: 8,
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'LXP card': {
      alignContent: 'center',
      borderColor: theme.colors['gray line'],
      borderRadius: 8,
      borderWidth: 1,
      height: { minWidth: Breakpoints.BigScreen, value: 240 },
      paddingBottom: 8,
    },
    'Location Card': {
      height: { minWidth: Breakpoints.Tablet, value: 220 },
      width: { minWidth: Breakpoints.Tablet, value: 317 },
    },
    'Mobile Header': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 12,
    },
    Nav: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    'Nav 2': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    'Nav 3': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    'Nav 4': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    'Nav 5': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    Sidebar: {
      borderColor: theme.colors['Inactive Gray Text'],
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingTop: 15,
      width: 72,
    },
    'Tab header': { flexDirection: 'row' },
    'Write your review touchable': { marginTop: 8 },
    bottomTab: {
      alignItems: 'center',
      backgroundColor: theme.colors['Background'],
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 10,
      paddingTop: 17,
    },
    'bottomTab 2': {
      alignItems: 'center',
      backgroundColor: theme.colors['Background'],
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 10,
      paddingTop: 17,
    },
    'container style': {
      borderColor: theme.colors['Light'],
      borderRadius: 8,
      borderWidth: 1,
      overflow: 'hidden',
      padding: 12,
    },
    'image with starting badge': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: 94,
      overflow: 'hidden',
      width: '100%',
    },
    'logged nav': { alignItems: 'center', flexDirection: 'row' },
    'logged nav 2': { alignItems: 'center', flexDirection: 'row' },
    'starting card': {
      alignItems: 'flex-end',
      backgroundColor: theme.colors['Background'],
      borderRadius: 8,
      bottom: 8,
      flexDirection: 'row',
      left: 4,
      padding: 8,
      position: 'absolute',
    },
  });

export const LinkStyles = theme => StyleSheet.create({});

export const FlatListStyles = theme =>
  StyleSheet.create({ List: { flex: 1 }, Lxps: { alignItems: 'flex-start' } });

export const MapViewStyles = theme =>
  StyleSheet.create({ 'Map View': { flex: 1, height: '100%', width: '100%' } });

export const SwiperStyles = theme =>
  StyleSheet.create({ Swiper: { height: 300, width: '100%' } });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Input': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'input work sans': {
      fontFamily: 'WorkSans_400Regular',
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 8,
      paddingTop: 8,
    },
    input_field: {
      backgroundColor: theme.colors['Divider'],
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      color: theme.colors['Strong'],
      fontFamily: 'System',
      fontWeight: '400',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { flex: 1 } });

export const PickerStyles = theme =>
  StyleSheet.create({
    'picker work sans': { fontFamily: 'WorkSans_400Regular' },
  });
