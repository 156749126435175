import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as SupabaseEdgeFunctionApi from '../apis/SupabaseEdgeFunctionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomPackages from '../custom-files/CustomPackages';
import dateFormatting from '../global-functions/dateFormatting';
import openUrlUsingLinking from '../global-functions/openUrlUsingLinking';
import responseErrorExtractor from '../global-functions/responseErrorExtractor';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Circle,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const parseNavParam = param => {
    console.log(param);
    let cleaned = param.replace(
      param[0] === '?' ? '?project_id=' : 'project_id=',
      ''
    );
    cleaned = cleaned.replace('checkout_id=', '');
    // let cleaned = param.substring(1)

    let ret = cleaned.split('&');

    console.log(ret);

    // setProjId(ret[0].split('=')[1])
    // setCheckId(ret[1].split('=')[1])

    setProjId(ret[0]);
    setCheckId(ret[1]);

    return ret;
  };

  const prepareDialouge = res => {
    let checkStatus = res.status;
    if (checkStatus) {
      if (['settling', 'settled'].includes(checkStatus)) {
        setIsSuccessful(true);
        setDialContent('Payment successful!');
        setTabI(1);
      } else {
        setIsSuccessful(false);
        setDialContent('Payment unsuccessful!');
      }

      setTimeout(() => setDialContent(''), 5000);
    }
  };

  const getSessionFormattedEndTime = (session_item, format) => {
    return CustomPackages.moment(session_item.session_start)
      .add(session_item.session_duration, 'minutes')
      .format(format);
  };

  const checkResponseErr = err => {
    console.log('profile response error', err);
    if (err) {
      // the user is not logged
      props.navigation.navigate('LoginScreen');
      // stop
      return true;
    }
    return false;
  };

  const myFunction = data => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    if (data && data?.length) {
      return data.filter(item =>
        item.title.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIsLoading(true);
        const profileRes = (
          await PublicSchemaApi.getUserProfileByIdGET(Constants, {
            user_id: Constants['AUTH_UUID_CAAS'],
          })
        )?.json;
        setIsLoading(false);
        const profileErr = responseErrorExtractor(profileRes);
        const hasProfileErr = checkResponseErr(profileErr);
        if (hasProfileErr) {
          setGlobalVariableValue({
            key: 'AUTH_HEADER_CAAS',
            value: '',
          });
        }
        if (hasProfileErr) {
          setGlobalVariableValue({
            key: 'AUTH_PROFILE_CAAS',
            value: {},
          });
        }
        if (hasProfileErr) {
          return;
        }
        const profileData = profileRes?.[0];
        setGlobalVariableValue({
          key: 'AUTH_PROFILE_CAAS',
          value: profileData,
        });
        if (!(props.route?.params?.checkoutId ?? '')) {
          return;
        }
        setIsLoading(true);
        const checkoutRes = (
          await SupabaseEdgeFunctionApi.getACheckoutPOST(Constants, {
            checkout_id: (() => {
              const e = props.route?.params?.checkoutId ?? '';
              console.log(e);
              return e;
            })(),
          })
        )?.json;
        prepareDialouge(checkoutRes);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [checkId, setCheckId] = React.useState('');
  const [dialContent, setDialContent] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [projId, setProjId] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [tabI, setTabI] = React.useState(0);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, height: '100%' },
          dimensions.width
        )}
      >
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row', height: 72 },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    flexDirection: 'row',
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Primary'],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.Group}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Inactive Gray Text'],
                    flex: 1,
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Work Sans Regular'],
                      {
                        fontFamily: 'Questrial_400Regular',
                        fontSize: 24,
                        letterSpacing: 0.2,
                        lineHeight: 20,
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'city as a school'}
                </Text>
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://ark.cityasaschool.com/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['ARK'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialIcons/auto-awesome'}
                        size={18}
                        color={theme.colors['Strong']}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'AI Copilot'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover Nav */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DiscoverScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['Discover Nav'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/grid'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Discover'}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Classes */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/search'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontSize: 16,
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Experiences'}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} right={16} left={0} />
                  {/* Join */}
                  <>
                    {Constants['AUTH_UUID_CAAS'] ? null : (
                      <Link
                        onPress={() => {
                          try {
                            navigation.navigate('LoginScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              fontFamily: 'Inter_500Medium',
                              fontSize: 16,
                              lineHeight: 19,
                              marginLeft: 12,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Join'}
                      />
                    )}
                  </>
                  {/* Avatar */}
                  <>
                    {!Constants['AUTH_UUID_CAAS'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Touchable (go to profile) */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('ProfileScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {!Constants['AUTH_PROFILE_CAAS']
                              ?.profile_picture ? null : (
                              <Circle
                                size={24}
                                bgColor={theme.colors['Primary']}
                              >
                                <>
                                  {!Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                                      }}
                                    />
                                  )}
                                </>
                                <>
                                  {Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Icon size={24} name={'Ionicons/person'} />
                                  )}
                                </>
                              </Circle>
                            )}
                          </>
                        </Touchable>
                        {/* Logout */}
                        <IconButton
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (await SupabaseAuthApi.logoutPOST(Constants))
                                  ?.json;
                                setGlobalVariableValue({
                                  key: 'AUTH_HEADER_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_UUID_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_PROFILE_CAAS',
                                  value: {},
                                });
                                navigation.navigate('LoginScreen', {
                                  redirectPath: '',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 16 },
                            dimensions.width
                          )}
                          icon={'MaterialCommunityIcons/login-variant'}
                          size={24}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: 'row' },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
            )}
          </>
          {/* Body */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 1,
                borderColor: theme.colors['Background'],
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderTopWidth: 1,
                flex: 1,
                paddingBottom: 32,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 32,
              },
              dimensions.width
            )}
          >
            {/* Central Profile */}
            <>
              {isLoading ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, width: 390 },
                    dimensions.width
                  )}
                >
                  {/* Add Button */}
                  <>
                    {tabI ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            bottom: [
                              { minWidth: Breakpoints.Mobile, value: 12 },
                              { minWidth: Breakpoints.Tablet, value: '15%' },
                            ],
                            left: 15,
                            position: 'absolute',
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  'https://www.cityasaschool.com/learning-record'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <Image
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 60, width: 60 }
                              ),
                              dimensions.width
                            )}
                            resizeMode={'cover'}
                            source={Images.PlusButton}
                          />
                        </Touchable>
                      </View>
                    )}
                  </>
                  {/* Picture & Name */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Hero */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 10,
                          paddingLeft: 14,
                          paddingRight: 14,
                          paddingTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* SpacerName&handel */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Image
                          style={StyleSheet.applyWidth(
                            { borderRadius: 200, height: 100, width: 100 },
                            dimensions.width
                          )}
                          resizeMode={'cover'}
                          source={{
                            uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                          }}
                        />
                        <Spacer top={8} bottom={8} left={8} right={8} />
                        {/* Name & Handel */}
                        <View>
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong, fontSize: 24 },
                              dimensions.width
                            )}
                          >
                            {Constants['AUTH_PROFILE_CAAS']?.firstName}{' '}
                            {Constants['AUTH_PROFILE_CAAS']?.lastName}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.strong },
                              dimensions.width
                            )}
                          >
                            {'@'}
                            {Constants['AUTH_PROFILE_CAAS']?.username}
                          </Text>
                          {/* Discord */}
                          <Touchable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    'https://discord.gg/h3FFdSGFQm'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              { marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                size={24}
                                name={'MaterialCommunityIcons/discord'}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    { paddingLeft: 4 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Join Discord\n'}
                              </Text>
                            </View>
                          </Touchable>
                        </View>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { height: '100%', justifyContent: 'space-between' },
                          dimensions.width
                        )}
                      >
                        {/* Settings Icon */}
                        <IconButton
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'LearnerSettingsScreen_B3fRXmVl'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          icon={'AntDesign/setting'}
                          color={theme.colors['Strong']}
                          size={24}
                        />
                        {/* Mail Icon */}
                        <IconButton
                          onPress={() => {
                            try {
                              navigation.navigate('InboxScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { marginBottom: 20 },
                            dimensions.width
                          )}
                          icon={'MaterialIcons/mail-outline'}
                          color={theme.colors['Strong']}
                          size={24}
                        />
                      </View>
                    </View>
                  </View>
                  {/* tabsFrame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* historyTabButtons */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '50%' },
                        dimensions.width
                      )}
                    >
                      {/* HistoryActive */}
                      <>
                        {tabI ? null : (
                          <Button
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Primary'],
                                borderRadius: 0,
                                color: theme.colors['Primary'],
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                            title={'History'}
                          />
                        )}
                      </>
                      {/* HistoryInActive */}
                      <>
                        {!tabI ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setTabI(0);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Inactive Gray Text'],
                                borderRadius: 0,
                                color: theme.colors['Inactive Gray Text'],
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                            title={'History'}
                          />
                        )}
                      </>
                    </View>
                    {/* scheduleTabBUttons */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '50%' },
                        dimensions.width
                      )}
                    >
                      {/* ScheduleActive */}
                      <>
                        {!tabI ? null : (
                          <Button
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Primary'],
                                borderRadius: 0,
                                color: theme.colors['Primary'],
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                            title={'Schedule'}
                          />
                        )}
                      </>
                      {/* ScheduleInActive */}
                      <>
                        {tabI ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setTabI(1);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Background'],
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Inactive Gray Text'],
                                borderRadius: 0,
                                color: theme.colors['Inactive Gray Text'],
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                            title={'Schedule'}
                          />
                        )}
                      </>
                    </View>
                  </View>
                  {/* schdeuleFetchFrame */}
                  <>
                    {!tabI ? null : (
                      <View>
                        {/* Sessions */}
                        <PublicSchemaApi.FetchGetAuthLearnerScheduledSessionsGET
                          handlers={{
                            onData: sessionsData => {
                              try {
                                console.log(sessionsData);
                              } catch (err) {
                                console.error(err);
                              }
                            },
                          }}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchGetAuthLearnerScheduledSessions,
                          }) => {
                            const sessionsData = data;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (error) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* emptyStateFrame Fr Schedule */}
                                <>
                                  {sessionsData?.length ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          alignSelf: 'center',
                                          marginTop: 40,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Icon */}
                                      <View>
                                        <Icon
                                          name={'AntDesign/calendar'}
                                          color={theme.colors['dark gray text']}
                                          size={70}
                                        />
                                      </View>
                                      {/* Title */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 32 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Strong'],
                                              fontFamily: 'System',
                                              fontSize: 20,
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Your schedule is empty'}
                                        </Text>
                                      </View>
                                      {/* Message */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 32 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              textAlign: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            'Build your own learning schedule by searching for experiences in the class browser.'
                                          }
                                        </Text>
                                      </View>
                                      {/* CTA */}
                                      <View />
                                    </View>
                                  )}
                                </>
                                {/* Sessions */}
                                <FlatList
                                  renderItem={({ item }) => {
                                    const sessionsData = item;
                                    return (
                                      <>
                                        {/* scheduledContentFrame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'column' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* cardFrame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'row',
                                                marginBottom: 12,
                                                marginLeft: 12,
                                                marginRight: 12,
                                                marginTop: 12,
                                                padding: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* leftFrame */}
                                            <View>
                                              {/* imageFrame */}
                                              <View>
                                                <Image
                                                  style={StyleSheet.applyWidth(
                                                    { height: 100, width: 100 },
                                                    dimensions.width
                                                  )}
                                                  resizeMode={'cover'}
                                                  source={{
                                                    uri: `${sessionsData?.cover_image}`,
                                                  }}
                                                />
                                              </View>
                                            </View>
                                            {/* rightFrame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flex: 1,
                                                  padding: 0,
                                                  paddingBottom: 8,
                                                  paddingLeft: 12,
                                                  paddingRight: 12,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* titleFrame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginBottom: 8 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.strong,
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {sessionsData?.lxp_title}
                                                </Text>
                                              </View>
                                              {/* date Time Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    marginBottom: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* date */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        theme.colors[
                                                          'gray line'
                                                        ],
                                                      borderRadius: 20,
                                                      flexDirection: 'row',
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    name={'Entypo/calendar'}
                                                    size={12}
                                                  />
                                                  <Spacer
                                                    top={8}
                                                    bottom={8}
                                                    right={4}
                                                    left={4}
                                                  />
                                                  {/* date */}
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {dateFormatting(
                                                      sessionsData?.start_time,
                                                      'Do MMM'
                                                    )}
                                                  </Text>
                                                </View>
                                                <Spacer
                                                  top={8}
                                                  right={8}
                                                  bottom={8}
                                                  left={8}
                                                />
                                                {/* duration */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        theme.colors[
                                                          'gray line'
                                                        ],
                                                      borderRadius: 20,
                                                      flexDirection: 'row',
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    size={12}
                                                    name={
                                                      'AntDesign/clockcircleo'
                                                    }
                                                  />
                                                  <Spacer
                                                    top={8}
                                                    bottom={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  {/* duration */}
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {dateFormatting(
                                                      sessionsData?.session_start,
                                                      'HH:mm'
                                                    )}
                                                    {'-'}
                                                    {getSessionFormattedEndTime(
                                                      sessionsData,
                                                      'HH:mm'
                                                    )}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Location */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    marginBottom: 4,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* iconFrame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      justifyContent: 'center',
                                                      marginRight: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    name={
                                                      'Ionicons/location-outline'
                                                    }
                                                    size={14}
                                                    color={
                                                      theme.colors['Primary']
                                                    }
                                                  />
                                                </View>
                                                {/* Zoom Link */}
                                                <>
                                                  {!sessionsData?.join_url ? null : (
                                                    <Link
                                                      onPress={() => {
                                                        try {
                                                          openUrlUsingLinking(
                                                            sessionsData?.join_url
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.LinkStyles(
                                                          theme
                                                        )['Link'],
                                                        dimensions.width
                                                      )}
                                                      title={'Join Class'}
                                                    />
                                                  )}
                                                </>
                                                {/* Zoom Link */}
                                                <>
                                                  {!sessionsData?.lc_link ? null : (
                                                    <Link
                                                      onPress={() => {
                                                        try {
                                                          openUrlUsingLinking(
                                                            sessionsData?.lc_link
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.LinkStyles(
                                                          theme
                                                        )['Link'],
                                                        dimensions.width
                                                      )}
                                                      title={'Join Class'}
                                                    />
                                                  )}
                                                </>
                                              </View>
                                              {/* Type */}
                                              <>
                                                {!sessionsData?.is_one_time ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* iconFrame */}
                                                    <View>
                                                      <Icon
                                                        size={24}
                                                        name={'AntDesign/tags'}
                                                      />
                                                    </View>

                                                    <Text
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            theme.colors.strong,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Meets Once'}
                                                    </Text>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          </View>
                                          <Divider
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.DividerStyles(theme)[
                                                'Divider'
                                              ],
                                              dimensions.width
                                            )}
                                            color={theme.colors['Divider']}
                                          />
                                          <Spacer
                                            top={8}
                                            right={8}
                                            bottom={8}
                                            left={8}
                                          />
                                        </View>
                                      </>
                                    );
                                  }}
                                  data={sessionsData}
                                  listKey={'oyQXxKo7'}
                                  keyExtractor={sessionsData =>
                                    sessionsData?.session_id
                                  }
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                              </>
                            );
                          }}
                        </PublicSchemaApi.FetchGetAuthLearnerScheduledSessionsGET>
                      </View>
                    )}
                  </>
                  {/* historyFetchFrame */}
                  <>
                    {tabI ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            maxHeight: {
                              minWidth: Breakpoints.Laptop,
                              value: 630,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <PublicSchemaApi.FetchGetEvidencesForPersonalFeedGET
                          id={Constants['AUTH_UUID_CAAS']}
                          handlers={{
                            onData: fetchData => {
                              try {
                                const resErr =
                                  responseErrorExtractor(fetchData);
                                checkResponseErr(resErr);
                              } catch (err) {
                                console.error(err);
                              }
                            },
                          }}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchGetEvidencesForPersonalFeed,
                          }) => {
                            const fetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* Post */}
                                <FlatList
                                  renderItem={({ item }) => {
                                    const postData = item;
                                    return (
                                      <>
                                        {/* PostFrame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingBottom: 16,
                                              paddingLeft: 12,
                                              paddingRight: 12,
                                              paddingTop: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Date Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginBottom: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Date From */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Inactive Gray Text'
                                                      ],
                                                    fontFamily: 'System',
                                                    fontWeight: '400',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {postData?.from_date}
                                            </Text>
                                            {/* Date To */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Inactive Gray Text'
                                                      ],
                                                    fontFamily: 'System',
                                                    fontWeight: '400',
                                                    marginRight: 12,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {' - '}
                                              {postData?.to_date}
                                            </Text>
                                            {/* Public */}
                                            <>
                                              {!postData?.is_public ? null : (
                                                <Icon
                                                  name={
                                                    'MaterialIcons/visibility'
                                                  }
                                                  color={
                                                    theme.colors[
                                                      'Custom Color_2'
                                                    ]
                                                  }
                                                  size={20}
                                                />
                                              )}
                                            </>
                                            {/* Not-public */}
                                            <>
                                              {postData?.is_public ? null : (
                                                <Icon
                                                  name={
                                                    'MaterialIcons/visibility'
                                                  }
                                                  color={
                                                    theme.colors[
                                                      'dark gray text'
                                                    ]
                                                  }
                                                  size={20}
                                                />
                                              )}
                                            </>
                                          </View>
                                          {/* Title Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { flexDirection: 'row' },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Title */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily: 'System',
                                                      fontWeight: '600',
                                                      marginRight: 4,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                numberOfLines={2}
                                              >
                                                {postData?.title}
                                              </Text>
                                              <>
                                                {!postData?.external_url ? null : (
                                                  <IconButton
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            await WebBrowser.openBrowserAsync(
                                                              `${postData?.external_url}`
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                    icon={
                                                      'Feather/arrow-up-right'
                                                    }
                                                    color={
                                                      theme.colors['Strong']
                                                    }
                                                    size={18}
                                                  />
                                                )}
                                              </>
                                            </View>
                                            <IconButton
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    await WebBrowser.openBrowserAsync(
                                                      'https://www.cityasaschool.com/learning-record'
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              icon={'Feather/edit'}
                                              color={theme.colors['Strong']}
                                              size={24}
                                            />
                                          </View>
                                          {/* Location Row */}
                                          <>
                                            {!postData?.location ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  name={
                                                    'Ionicons/ios-location-outline'
                                                  }
                                                  color={
                                                    theme.colors[
                                                      'Inactive Gray Text'
                                                    ]
                                                  }
                                                  size={10}
                                                />
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Inactive Gray Text'
                                                          ],
                                                        fontFamily: 'System',
                                                        fontSize: 10,
                                                        fontWeight: '400',
                                                        marginLeft: 8,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {postData?.location}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          {/* Description */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { width: '90%' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Description */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    alignSelf: 'stretch',
                                                    marginBottom: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {postData?.description}
                                            </Text>
                                          </View>
                                          {/* Images Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row' },
                                              dimensions.width
                                            )}
                                          >
                                            <FlatList
                                              renderItem={({ item }) => {
                                                const listData = item;
                                                return (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { marginRight: 10 },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Image
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.ImageStyles(
                                                            theme
                                                          )['Image'],
                                                          {
                                                            height: 69,
                                                            marginRight: 10,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                      resizeMode={'cover'}
                                                      source={{
                                                        uri: `${listData}`,
                                                      }}
                                                    />
                                                  </View>
                                                );
                                              }}
                                              data={postData?.imagesArray}
                                              listKey={JSON.stringify(
                                                postData?.imagesArray
                                              )}
                                              keyExtractor={listData =>
                                                listData
                                              }
                                              onEndReachedThreshold={0.5}
                                              showsHorizontalScrollIndicator={
                                                true
                                              }
                                              numColumns={1}
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                              horizontal={true}
                                            />
                                          </View>
                                          <Divider
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.DividerStyles(
                                                  theme
                                                )['Divider'],
                                                { marginTop: 16 }
                                              ),
                                              dimensions.width
                                            )}
                                            color={theme.colors.divider}
                                          />
                                        </View>
                                      </>
                                    );
                                  }}
                                  data={fetchData}
                                  listKey={'SAAGWJRg'}
                                  keyExtractor={postData => postData?.id}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  showsHorizontalScrollIndicator={false}
                                  showsVerticalScrollIndicator={false}
                                  inverted={true}
                                />
                                {/* emptyStateFrame Fr Schedule */}
                                <>
                                  {!!fetchData?.length ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          alignSelf: 'center',
                                          marginTop: 40,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Icon */}
                                      <View>
                                        <Icon
                                          name={'AntDesign/calendar'}
                                          color={theme.colors['dark gray text']}
                                          size={70}
                                        />
                                      </View>
                                      {/* Title */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 32 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Strong'],
                                              fontFamily: 'System',
                                              fontSize: 20,
                                              fontWeight: '600',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Your Portfolio is Empty'}
                                        </Text>
                                      </View>
                                      {/* Message */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 32 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              textAlign: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Add a new post from the mobile app'}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </>
                            );
                          }}
                        </PublicSchemaApi.FetchGetEvidencesForPersonalFeedGET>
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </View>
        </View>
      </View>
      {/* Feedback Container */}
      <>
        {!dialContent ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'absolute',
                top: 15,
                width: '100%',
                zIndex: 12,
              },
              dimensions.width
            )}
          >
            {/* Success */}
            <>
              {!isSuccessful ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Custom Color_2'],
                      borderRadius: 12,
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    style={StyleSheet.applyWidth(
                      { marginRight: 15 },
                      dimensions.width
                    )}
                    size={16}
                    color={theme.colors['Background']}
                    name={'Entypo/check'}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Inter Regular'],
                        {
                          color: theme.colors['Background'],
                          flex: 1,
                          fontFamily: 'Inter_700Bold',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {dialContent}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setDialContent('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 15 },
                      dimensions.width
                    )}
                    icon={'MaterialIcons/cancel'}
                    size={16}
                    color={theme.colors['Background']}
                  />
                </View>
              )}
            </>
            {/* Failed */}
            <>
              {isSuccessful ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Error'],
                      borderRadius: 12,
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    style={StyleSheet.applyWidth(
                      { marginRight: 15 },
                      dimensions.width
                    )}
                    size={16}
                    color={theme.colors['Background']}
                    name={'Foundation/alert'}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Inter Regular'],
                        {
                          color: theme.colors['Background'],
                          flex: 1,
                          fontFamily: 'Inter_700Bold',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {null}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setDialContent('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 15 },
                      dimensions.width
                    )}
                    icon={'MaterialIcons/cancel'}
                    size={16}
                    color={theme.colors['Background']}
                  />
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* bottomTab */}
      <>
        {dimensions.width >= Breakpoints.Tablet ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['bottomTab'],
              dimensions.width
            )}
          >
            {/* Discover */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('DiscoverScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    source={Images.IconDiscover}
                    resizeMode={'center'}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Discover'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Locations */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    source={Images.IconVenues}
                    resizeMode={'contain'}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Locations'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Experiences */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('ExploreScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'cover'}
                    source={Images.IconActivities}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Experiences'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Checkin */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'cover'}
                    source={Images.IconCheckin}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Check-in'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Profile */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'cover'}
                    source={Images.IconProfile1}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['CAS Blue'],
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Profile'}
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        )}
      </>
      <>
        {!isLoading ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={'large'}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ProfileScreen);
