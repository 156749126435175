// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text, StyleSheet, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';

import { Spacer } from '@draftbit/ui';

export const Index = ({
  fetchData,
  Variables,
  isMyMessage,
  getFormattedTime,
  theme,
  senderFullName,
}) => {
  // console.log(fetchData, senderFullName)
  return (
    <FlashList
      // <FlatList
      inverted
      //  ref={yourRef}
      //   onContentSizeChange={() => yourRef.current.scrollToEnd() }
      //   onLayout={() => yourRef.current.scrollToEnd() }
      data={fetchData}
      listKey={'di7ElZB4'}
      keyExtractor={item => item?.id || item?.uuid || item}
      renderItem={({ item }) => {
        const listData = item;
        console.log('rendering', item);
        return (
          <>
            {/* From Sender */}
            <>
              {isMyMessage(Variables, listData?.sender_id) ? null : (
                <View style={styles.View9c5d5e9d}>
                  {/* Container */}
                  <View
                    style={[
                      styles.Viewed5a1ff1,
                      {
                        borderBottomLeftRadius: 7,
                        borderTopRightRadius: 7,
                        borderTopLeftRadius: 7,
                        borderBottomRightRadius: 7,
                        backgroundColor: 'rgb(247,247,247)',
                      },
                    ]}
                  >
                    {/* Name */}
                    <Text
                      style={[
                        styles.Text9c40bf51,
                        {
                          color: theme.colors.strong,
                          color: 'rgb(255,165,0)',
                        },
                      ]}
                    >
                      {senderFullName}
                    </Text>
                    <Spacer right={8} left={8} top={3} bottom={3} />
                    {/* message */}
                    <Text
                      style={[
                        styles.Textd1cb507d,
                        { color: theme.colors.strong },
                      ]}
                    >
                      {listData?.body}
                    </Text>
                    <Spacer right={8} left={8} top={2} bottom={2} />
                    {/* time */}
                    <Text
                      style={[
                        styles.Text8eef4388,
                        {
                          color: theme.colors.strong,
                        },
                      ]}
                    >
                      {getFormattedTime(listData?.created_at)}
                    </Text>
                  </View>
                  {/* Blank */}
                  <View style={styles.View6bece163} />
                </View>
              )}
            </>
            {/* From Me */}
            <>
              {!isMyMessage(Variables, listData?.sender_id) ? null : (
                <View style={styles.View1c15fa58}>
                  {/* Blank */}
                  <View style={styles.View22018af5} />
                  {/* Container */}
                  <View
                    style={[
                      styles.View20be8e42,
                      {
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 7,
                        borderTopRightRadius: 7,
                        borderBottomLeftRadius: 7,
                        backgroundColor: 'rgb(47,128,237)',
                      },
                    ]}
                  >
                    {/* message */}
                    <Text style={{ color: theme.colors.surface }}>
                      {listData?.body}
                    </Text>
                    <Spacer right={8} left={8} top={2} bottom={2} />
                    {/* time */}
                    <Text
                      style={[
                        styles.Textf79f17bb,
                        { color: theme.colors.strongInverse },
                      ]}
                    >
                      {getFormattedTime(listData?.created_at)}
                    </Text>
                  </View>
                </View>
              )}
            </>
            <Spacer right={8} bottom={8} left={8} top={8} />
          </>
        );
      }}
      contentContainerStyle={styles.FlatListc992f941Content}
      numColumns={1}
      estimatedItemSize={200}
    />
  );
};

const styles = StyleSheet.create({
  FlatListc992f941Content: {
    flex: 1,
  },

  Text8eef4388: {
    alignSelf: 'flex-end',
    fontFamily: 'Inter_400Regular',
    fontSize: 10,
  },
  Text9c40bf51: {
    fontFamily: 'Inter_500Medium',
  },

  Textd1cb507d: {
    fontFamily: 'Inter_400Regular',
  },
  Textf79f17bb: {
    alignSelf: 'flex-end',
    fontFamily: 'Inter_400Regular',
    fontSize: 10,
  },
  View1c15fa58: {
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },

  View20be8e42: {
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    width: '65%',
  },
  View22018af5: {
    flex: 1,
    paddingLeft: 2,
    paddingRight: 1,
    width: '35%',
  },

  View6bece163: {
    flex: 1,
    paddingLeft: 2,
    paddingRight: 1,
    width: '20%',
  },
  View9c5d5e9d: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  Viewed5a1ff1: {
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    width: '80%',
  },
});
