import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ChatList from '../custom-files/ChatList';
import getRelativeTime from '../global-functions/getRelativeTime';
import getTimezonOffset from '../global-functions/getTimezonOffset';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Circle,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const InboxScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const checkResponseErr = err => {
    console.log('profile response error', err);
    if (err) {
      // the user is not logged
      props.navigation.navigate('LoginScreen');
      // stop
      return true;
    }
    return false;
  };

  const getSenderFullName = sender => {
    return `${sender.firstName} ${sender.lastName}`;
  };

  const getFormattedTime = dateStr => {
    const dateObj = new Date(dateStr);

    return `${dateObj.getHours()}:${dateObj.getMinutes()}`;
  };

  const isMyMessage = (Variables, sender_id) => {
    console.log(Variables.AUTH_UUID_CAAS, sender_id);
    return Variables.AUTH_UUID_CAAS === sender_id;
  };

  const sendingDisabled = () => {
    return !newMessage.length || sending;
  };

  const { theme } = props;
  const { navigation } = props;

  const publicSchemaDirectMessageATeacherPOST =
    PublicSchemaApi.useDirectMessageATeacherPOST();

  const [chattingFullName, setChattingFullName] = React.useState('');
  const [chattingUsername, setChattingUsername] = React.useState('');
  const [chatting_teacher_id, setChatting_teacher_id] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const [viewingChannelId, setViewingChannelId] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row', height: 72 },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    flexDirection: 'row',
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Primary'],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.Group}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Inactive Gray Text'],
                    flex: 1,
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Work Sans Regular'],
                      {
                        fontFamily: 'Questrial_400Regular',
                        fontSize: 24,
                        letterSpacing: 0.2,
                        lineHeight: 20,
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'city as a school'}
                </Text>
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://ark.cityasaschool.com/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['ARK 7'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialIcons/auto-awesome'}
                        size={18}
                        color={theme.colors['Strong']}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'AI Copilot'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover Nav */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DiscoverScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['Discover Nav 6'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/grid'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Discover'}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/search'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontSize: 16,
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Experiences'}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} left={16} right={16} />
                  {/* Join */}
                  <>
                    {Constants['AUTH_UUID_CAAS'] ? null : (
                      <Link
                        onPress={() => {
                          try {
                            navigation.navigate('LoginScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              fontFamily: 'Inter_500Medium',
                              fontSize: 16,
                              lineHeight: 19,
                              marginLeft: 12,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Join'}
                      />
                    )}
                  </>
                  {/* Avatar */}
                  <>
                    {!Constants['AUTH_UUID_CAAS'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Touchable (go to setting) */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('ProfileScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {!Constants['AUTH_PROFILE_CAAS']
                              ?.profile_picture ? null : (
                              <Circle
                                size={24}
                                bgColor={theme.colors['Primary']}
                              >
                                <>
                                  {!Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                                      }}
                                    />
                                  )}
                                </>
                                <>
                                  {Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Icon size={24} name={'Ionicons/person'} />
                                  )}
                                </>
                              </Circle>
                            )}
                          </>
                        </Touchable>
                        {/* Logout */}
                        <IconButton
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (await SupabaseAuthApi.logoutPOST(Constants))
                                  ?.json;
                                setGlobalVariableValue({
                                  key: 'AUTH_HEADER_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_UUID_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_PROFILE_CAAS',
                                  value: {},
                                });
                                navigation.navigate('LoginScreen', {
                                  redirectPath: '',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 16 },
                            dimensions.width
                          )}
                          icon={'MaterialCommunityIcons/login-variant'}
                          size={24}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: 'row' },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
            )}
          </>
          {/* Body */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                paddingLeft: '12.5%',
                paddingRight: '12.5%',
                paddingTop: 40,
              },
              dimensions.width
            )}
          >
            {/* Title */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'column' },
                dimensions.width
              )}
            >
              {/* Titile */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Inter Bold 20 Title'],
                    {
                      fontFamily: 'Inter_700Bold',
                      fontSize: 30,
                      lineHeight: 36,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Inbox\n'}
              </Text>
            </View>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  height: '75%',
                  paddingTop: 20,
                },
                dimensions.width
              )}
            >
              {/* Left Panel */}
              <ScrollView
                style={StyleSheet.applyWidth(
                  {
                    height: [
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Desktop, value: '75%' },
                    ],
                    width: { minWidth: Breakpoints.Desktop, value: '50%' },
                  },
                  dimensions.width
                )}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    flex: { minWidth: Breakpoints.Tablet, value: 1 },
                    flexShrink: 1,
                    minWidth: { minWidth: Breakpoints.Laptop, value: 520 },
                  },
                  dimensions.width
                )}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                bounces={true}
              >
                {/* Summary */}
                <PublicSchemaApi.FetchGetMessageSummaryGET>
                  {({ loading, error, data, refetchGetMessageSummary }) => {
                    const summaryData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* People */}
                        <FlatList
                          renderItem={({ item }) => {
                            const peopleData = item;
                            return (
                              <Touchable
                                onPress={() => {
                                  try {
                                    setChattingFullName(
                                      getSenderFullName(peopleData?.sender)
                                    );
                                    setChattingUsername(
                                      peopleData?.sender?.handle
                                    );
                                    setViewingChannelId(
                                      peopleData?.target_channel_id
                                    );
                                    setChatting_teacher_id(
                                      peopleData?.provider_id
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    width: {
                                      minWidth: Breakpoints.Desktop,
                                      value: '100%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Container */}
                                <>
                                  {peopleData?.target_channel_id ===
                                  viewingChannelId ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 1,
                                          borderColor:
                                            theme.colors['gray line'],
                                          flexDirection: 'row',
                                          paddingBottom: 18,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                          paddingTop: 18,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Circle
                                        size={50}
                                        bgColor={theme.colors.light}
                                      >
                                        <>
                                          {!peopleData?.sender
                                            ?.profile_picture ? null : (
                                            <Image
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    height: '100%',
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              resizeMode={'cover'}
                                              source={{
                                                uri: `${peopleData?.sender?.profile_picture}`,
                                              }}
                                            />
                                          )}
                                        </>
                                        <>
                                          {peopleData?.sender
                                            ?.profile_picture ? null : (
                                            <Icon
                                              size={24}
                                              name={'Ionicons/person'}
                                            />
                                          )}
                                        </>
                                      </Circle>
                                      {/* Content */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            marginLeft: 12,
                                            marginRight: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 12,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Caption Row */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Full Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                {
                                                  fontFamily: 'Inter_500Medium',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {peopleData?.sender?.firstName}{' '}
                                            {peopleData?.sender?.lastName}
                                          </Text>
                                          {/* username ago */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                { marginLeft: 4 }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'@'}
                                            {peopleData?.sender?.handle}
                                            {' · '}
                                            {getRelativeTime(
                                              peopleData?.message_sent_at
                                            )}
                                          </Text>
                                        </View>
                                        {/* last message */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Inter Regular'
                                              ],
                                              { marginTop: 8 }
                                            ),
                                            dimensions.width
                                          )}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                        >
                                          {peopleData?.last_message}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                </>
                                {/* Container Selected */}
                                <>
                                  {!(
                                    peopleData?.target_channel_id ===
                                    viewingChannelId
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 2,
                                          borderColor: theme.colors['Primary'],
                                          flexDirection: 'row',
                                          paddingBottom: 18,
                                          paddingLeft: 16,
                                          paddingRight: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 16,
                                          },
                                          paddingTop: 18,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Circle
                                        size={50}
                                        bgColor={theme.colors.light}
                                      >
                                        <>
                                          {!peopleData?.sender
                                            ?.profile_picture ? null : (
                                            <Image
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    height: '100%',
                                                    width: '100%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              resizeMode={'cover'}
                                              source={{
                                                uri: `${peopleData?.sender?.profile_picture}`,
                                              }}
                                            />
                                          )}
                                        </>
                                        <>
                                          {peopleData?.sender
                                            ?.profile_picture ? null : (
                                            <Icon
                                              size={24}
                                              name={'Ionicons/person'}
                                            />
                                          )}
                                        </>
                                      </Circle>
                                      {/* Content */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            marginLeft: 12,
                                            marginRight: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 12,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Caption Row */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Full Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                {
                                                  fontFamily: 'Inter_500Medium',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {peopleData?.sender?.firstName}{' '}
                                            {peopleData?.sender?.lastName}
                                          </Text>
                                          {/* username ago */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                { marginLeft: 4 }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'@'}
                                            {peopleData?.sender?.handle}
                                            {' · '}
                                            {getRelativeTime(
                                              peopleData?.message_sent_at
                                            )}
                                          </Text>
                                        </View>
                                        {/* last message */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Inter Regular'
                                              ],
                                              { marginTop: 8 }
                                            ),
                                            dimensions.width
                                          )}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                        >
                                          {peopleData?.last_message}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            );
                          }}
                          data={summaryData}
                          listKey={'L8a60Ba0'}
                          keyExtractor={peopleData =>
                            peopleData?.target_channel_id
                          }
                          style={StyleSheet.applyWidth(
                            GlobalStyles.FlatListStyles(theme)['List'],
                            dimensions.width,
                            ['width', 'height', 'flexGrow']
                          )}
                          contentContainerStyle={StyleSheet.applyWidth(
                            GlobalStyles.FlatListStyles(theme)['List'],
                            dimensions.width
                          )}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                        {/* Empty Frame */}
                        <>
                          {summaryData?.length ? null : (
                            <View>
                              {/* Titile */}
                              <>
                                {summaryData?.length ? null : (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'Inter Bold 20 Title'
                                        ],
                                        {
                                          fontFamily: 'Inter_700Bold',
                                          fontSize: 30,
                                          lineHeight: 36,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'No Messages'}
                                  </Text>
                                )}
                              </>
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </PublicSchemaApi.FetchGetMessageSummaryGET>
              </ScrollView>
              {/* right Panel */}
              <>
                {!viewingChannelId ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexGrow: 2,
                        height: [
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Desktop, value: '75%' },
                        ],
                        width: { minWidth: Breakpoints.Desktop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  >
                    <KeyboardAwareScrollView
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                      showsVerticalScrollIndicator={true}
                      keyboardShouldPersistTaps={'never'}
                    >
                      {/* Messages */}
                      <PublicSchemaApi.FetchGetTeacherStudentChatsGET
                        refetchInterval={3000}
                        channel_id={viewingChannelId}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchGetTeacherStudentChats,
                        }) => {
                          const messagesData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <Utils.CustomCodeErrorBoundary>
                              <ChatList.Index
                                fetchData={messagesData}
                                Variables={Variables}
                                isMyMessage={isMyMessage}
                                getFormattedTime={getFormattedTime}
                                route={props.route}
                                theme={props.theme}
                                senderFullName={chattingFullName}
                              />
                            </Utils.CustomCodeErrorBoundary>
                          );
                        }}
                      </PublicSchemaApi.FetchGetTeacherStudentChatsGET>
                    </KeyboardAwareScrollView>
                    {/* Chat bar */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 1,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Desktop,
                            value: theme.colors['gray line'],
                          },
                          borderLeftWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 1,
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Desktop,
                            value: 12,
                          },
                          borderRightWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 1,
                          },
                          borderTopWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 1,
                          },
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <TextInput
                          onChangeText={newTextInputValue => {
                            try {
                              setNewMessage(newTextInputValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextInputStyles(theme)['Text Input'],
                              { backgroundColor: theme.colors['Divider'] }
                            ),
                            dimensions.width
                          )}
                          value={newMessage}
                          placeholder={'Reply...'}
                          autoCapitalize={'none'}
                          multiline={true}
                          scrollEnabled={true}
                          placeholderTextColor={
                            theme.colors['Inactive Gray Text']
                          }
                        />
                      </View>

                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setSending(true);
                              (
                                await publicSchemaDirectMessageATeacherPOST.mutateAsync(
                                  {
                                    content: newMessage,
                                    teacher_id: chatting_teacher_id,
                                  }
                                )
                              )?.json;
                              setNewMessage('');
                              setSending(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 16 },
                          dimensions.width
                        )}
                        disabled={sendingDisabled()}
                      >
                        <Icon
                          name={'MaterialCommunityIcons/send-circle-outline'}
                          color={theme.colors['Primary']}
                          size={40}
                        />
                      </Touchable>
                    </View>
                  </View>
                )}
              </>
              {/* Mobile Right */}
              <>
                {dimensions.width >= Breakpoints.Tablet ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* right Panel */}
                    <>
                      {!viewingChannelId ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexGrow: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: '100%' },
                                { minWidth: Breakpoints.Desktop, value: '75%' },
                              ],
                              width: [
                                { minWidth: Breakpoints.Desktop, value: '50%' },
                                { minWidth: Breakpoints.Laptop, value: '50%' },
                                { minWidth: Breakpoints.Tablet, value: '50%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <KeyboardAwareScrollView
                            contentContainerStyle={StyleSheet.applyWidth(
                              {
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                              },
                              dimensions.width
                            )}
                            showsVerticalScrollIndicator={true}
                            keyboardShouldPersistTaps={'never'}
                          >
                            {/* Messages */}
                            <PublicSchemaApi.FetchGetTeacherStudentChatsGET
                              refetchInterval={3000}
                            >
                              {({
                                loading,
                                error,
                                data,
                                refetchGetTeacherStudentChats,
                              }) => {
                                const messagesData = data?.json;
                                if (loading) {
                                  return <ActivityIndicator />;
                                }

                                if (
                                  error ||
                                  data?.status < 200 ||
                                  data?.status >= 300
                                ) {
                                  return <ActivityIndicator />;
                                }

                                return (
                                  <Utils.CustomCodeErrorBoundary>
                                    <ChatList.Index
                                      fetchData={messagesData}
                                      Variables={Variables}
                                      isMyMessage={isMyMessage}
                                      getFormattedTime={getFormattedTime}
                                      route={props.route}
                                      theme={props.theme}
                                      senderFullName={chattingFullName}
                                    />
                                  </Utils.CustomCodeErrorBoundary>
                                );
                              }}
                            </PublicSchemaApi.FetchGetTeacherStudentChatsGET>
                          </KeyboardAwareScrollView>
                          {/* Chat bar */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 1,
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors['gray line'],
                                },
                                borderLeftWidth: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 1,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 12,
                                },
                                borderRightWidth: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 1,
                                },
                                borderTopWidth: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 1,
                                },
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setNewMessage(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ],
                                    { backgroundColor: theme.colors['Divider'] }
                                  ),
                                  dimensions.width
                                )}
                                placeholder={'Reply...'}
                                autoCapitalize={'none'}
                                multiline={true}
                                scrollEnabled={true}
                                placeholderTextColor={
                                  theme.colors['Inactive Gray Text']
                                }
                              />
                            </View>

                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setSending(true);
                                    (
                                      await publicSchemaDirectMessageATeacherPOST.mutateAsync(
                                        {}
                                      )
                                    )?.json;
                                    setNewMessage('');
                                    setSending(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 16 },
                                dimensions.width
                              )}
                              disabled={sendingDisabled()}
                            >
                              <Icon
                                name={
                                  'MaterialCommunityIcons/send-circle-outline'
                                }
                                color={theme.colors['Primary']}
                                size={40}
                              />
                            </Touchable>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(InboxScreen);
