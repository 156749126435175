import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const getOneTimeSessionItem = item => {
  const formatDateStr = (dateObj, formatString) =>
    CustomPackages.moment(dateObj).format(formatString);
  const getFormatedEndTime = (str, dur, format) =>
    CustomPackages.moment(str).add(dur, 'minutes').format(format);

  const dateObj = CustomPackages.moment(item.start_time);

  return `${dateObj.format('HH:mm')} - ${getFormatedEndTime(
    item.start_time,
    item.duration,
    'HH:mm'
  )}`;
};

export default getOneTimeSessionItem;
