import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PublicSchemaApi from '../apis/PublicSchemaApi.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomPackages from '../custom-files/CustomPackages';
import getObjectEntries from '../global-functions/getObjectEntries';
import getRelativeTime from '../global-functions/getRelativeTime';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Link,
  Picker,
  ScreenContainer,
  Slider,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { MasonryFlashList } from '@shopify/flash-list';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const DiscoverScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const applyEndTimeFilter = pickerTime => {
    const getFormatedEndTime = (str, dur, format) =>
      CustomPackages.moment(str).add(dur, 'minutes').format(format);

    setSearchableLxps(
      allLxps.filter(({ upcoming_session }) =>
        upcoming_session
          ? getFormatedEndTime(
              upcoming_session.start_time,
              upcoming_session.duration,
              'HH:mm'
            ) === CustomPackages.moment(pickerTime).format('HH:mm')
          : false
      )
    );
  };

  const applyTopicFilter = topic => {
    if (topic !== 'All')
      setSearchableLxps(allLxps.filter(({ subject }) => subject === topic));
    else setSearchableLxps(allLxps);
  };

  const applySearchFilter = query => {
    // console.log(query, allLxps)
    if (!query || query.length < 3) setSearchableLxps(allLxps);
    // console.log(query)
    setSearchableLxps(() => {
      return allLxps.filter(item => {
        return (
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.description.toLowerCase().includes(query.toLowerCase()) ||
          item.author.firstName.toLowerCase().includes(query.toLowerCase()) ||
          item.author.lastName.toLowerCase().includes(query.toLowerCase())
        );
      });
    });
  };

  const applyClassTypeFilter = classType => {
    // console.log(classType)
    if (classType !== 'All')
      setSearchableLxps(
        classType === 'Online'
          ? allLxps.filter(({ isvirtual }) => isvirtual)
          : allLxps.filter(({ isvirtual }) => !isvirtual)
      );
    else setSearchableLxps(allLxps);
  };

  const applyDateFilter = newRange => {
    // console.log(newDate)
    if (newRange > -1)
      setSearchableLxps(
        allLxps.filter(({ upcoming_session }) => {
          // console.log(CustomPackages.moment(start_time), CustomPackages.moment(newDate))
          // console.log(CustomPackages.moment(start_time) < CustomPackages.moment(newDate))
          if (upcoming_session) {
            console.log(
              'diff',
              CustomPackages.moment(upcoming_session?.start_time).diff(
                CustomPackages.moment(),
                'days'
              )
            );
            return (
              CustomPackages.moment(upcoming_session?.start_time).diff(
                CustomPackages.moment(),
                'days'
              ) <= newRange
            );
          }
          return false;
          // console.log(CustomPackages.moment(start_time).isBefore(CustomPackages.moment(newDate)))
          // return upcoming_session ? CustomPackages.moment(upcoming_session?.start_time).diff(CustomPackages.moment(), 'days' ) < 7 : false
        })
      );
    // else if (newRage === 0) setSearchableLxps(allLxps.filter(({upcoming_session})=> upcoming_session))
    else setSearchableLxps(allLxps);
  };

  const applyStartTimeFilter = pickerTime => {
    setSearchableLxps(
      allLxps.filter(({ upcoming_session }) =>
        upcoming_session
          ? CustomPackages.moment(upcoming_session.start_time).format(
              'HH:mm'
            ) === CustomPackages.moment(pickerTime).format('HH:mm')
          : false
      )
    );
  };

  const createSubjectList = res => {
    return res.map(({ name }) => name);
  };

  const showShortImage = index => {
    return index % 2;
  };
  // assuming the url path https://learn.cityasaschool.com/?lxp_id=some_id&screen=LXPDetails

  // linking config:

  /**
* 
* const linking = {
 config: {
   screens:{
   ExploreScreen: "",
   LoginScreen: "login/:redirect",
   LXPDetailsScreen: "lxp_details/:lxp_id",
   UpdatePasswordScreen: "update_password/:accessToken"
 }
 },
};
*/

  let requiredAuth = false;
  let redirectPath = '';
  let redirectObj = {};
  switch (props?.route?.params?.screen) {
    case 'LXPDetails': {
      redirectPath = 'LXPDetailsScreen';
      redirectObj = { lxp_id: props?.route?.params?.lxp_id };
      break;
    }

    case 'UpdatePassword': {
      requiredAuth = false;
      redirectPath = 'UpdatePasswordScreen';
      redirectObj = { accessToken: props?.route?.params?.accessToken };
      break;
    }

    // case 'LocationListing':
    // {
    //     // redirectPath = 'LoginScreen'
    //     requiredAuth = true
    //     break;
    // }

    // case 'PublicProfile':
    // {
    //     requiredAuth = false
    //     redirectPath = 'PublicProfileScreen'
    //     redirectObj = {handel: props.route.params?.handel}
    //     break;
    // }
  }

  if (redirectPath) {
    props.navigation.navigate(redirectPath, redirectObj);
  }
  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const learning_centers = (
          await PublicSchemaApi.getLearningCentersByCityGET(Constants, {})
        )?.json;
        const res = (await PublicSchemaApi.getSubjectsGET(Constants))?.json;
        const subjects = createSubjectList(res);
        setGlobalVariableValue({
          key: 'SUBJECTS',
          value: ['All'].concat(subjects),
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [allPosts, setAllPosts] = React.useState([]);
  const [classType, setClassType] = React.useState('All');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [longImage, setLongImage] = React.useState(true);
  const [mediaOptions, setMediaOptions] = React.useState([
    'All',
    'Online',
    'In-person',
  ]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [topicOptions, setTopicOptions] = React.useState([
    'All',
    'Arts',
    'Coding & Tech',
    'English',
    'Health & Wellness',
    'Life skills',
    'Math',
    'Music',
    'Sicence & Nature',
    'Social Studies',
    'World Languages',
  ]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ height: '91%' }, dimensions.width)}>
        {/* Header */}
        <>
          {!(dimensions.width >= Breakpoints.Tablet) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderWidth: 0,
                  flexDirection: 'row',
                  height: 72,
                },
                dimensions.width
              )}
            >
              {/* With Bar */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 0,
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    flexDirection: 'row',
                    height: 72,
                    maxHeight: 72,
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Blue Bar */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Primary'],
                      height: 42,
                      width: 3,
                    },
                    dimensions.width
                  )}
                />
                {/* Logo Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 32, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.CASNavLogo}
                    />
                  </Touchable>
                </View>
              </View>
              {/* Brand & Action */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Inactive Gray Text'],
                    flex: 1,
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: 14,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Work Sans Regular'],
                      {
                        fontFamily: 'Questrial_400Regular',
                        fontSize: 24,
                        letterSpacing: 0.2,
                        lineHeight: 20,
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'city as a school'}
                </Text>
                {/* Nav */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Nav 2'],
                    dimensions.width
                  )}
                >
                  {/* ARK */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://ark.cityasaschool.com/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['ARK 6'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        name={'MaterialIcons/auto-awesome'}
                        size={18}
                        color={theme.colors['Strong']}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'AI Copilot'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* Discover Nav */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DiscoverScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['Discover Nav 5'],
                      dimensions.width
                    )}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 0,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/grid'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Discover'}
                      </Text>
                    </View>
                  </Touchable>

                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ExploreScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                  >
                    {/* Discover */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={18}
                        color={theme.colors['Strong']}
                        name={'Feather/search'}
                      />
                      <Spacer top={8} right={8} bottom={8} left={0} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Inter Regular'],
                            {
                              color: theme.colors.strong,
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '400',
                              lineHeight: 19,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Experiences'}
                      </Text>
                    </View>
                  </Touchable>
                  <Spacer top={8} bottom={8} left={16} right={16} />
                  {/* Join */}
                  <>
                    {Constants['AUTH_UUID_CAAS'] ? null : (
                      <Link
                        onPress={() => {
                          try {
                            navigation.navigate('LoginScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              fontFamily: 'Inter_500Medium',
                              fontSize: 16,
                              lineHeight: 19,
                              marginLeft: 12,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Join'}
                      />
                    )}
                  </>
                  {/* Avatar */}
                  <>
                    {!Constants['AUTH_UUID_CAAS'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Touchable (go to setting) */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('ProfileScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {!Constants['AUTH_PROFILE_CAAS']
                              ?.profile_picture ? null : (
                              <Circle
                                size={24}
                                bgColor={theme.colors['Primary']}
                              >
                                <>
                                  {!Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${Constants['AUTH_PROFILE_CAAS']?.profile_picture}`,
                                      }}
                                    />
                                  )}
                                </>
                                <>
                                  {Constants['AUTH_PROFILE_CAAS']
                                    ?.profile_picture ? null : (
                                    <Icon name={'Ionicons/person'} size={24} />
                                  )}
                                </>
                              </Circle>
                            )}
                          </>
                        </Touchable>
                        {/* Logout */}
                        <IconButton
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (await SupabaseAuthApi.logoutPOST(Constants))
                                  ?.json;
                                setGlobalVariableValue({
                                  key: 'AUTH_HEADER_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_UUID_CAAS',
                                  value: '',
                                });
                                setGlobalVariableValue({
                                  key: 'AUTH_PROFILE_CAAS',
                                  value: {},
                                });
                                navigation.navigate('LoginScreen', {
                                  redirectPath: '',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 16 },
                            dimensions.width
                          )}
                          icon={'MaterialCommunityIcons/login-variant'}
                          size={24}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, flexDirection: 'row' },
            dimensions.width
          )}
        >
          {/* Sidebar */}
          <>
            {!(dimensions.width >= Breakpoints.Tablet) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Inactive Gray Text'],
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingLeft: 12,
                    paddingRight: 0,
                    paddingTop: 15,
                    width: 72,
                  },
                  dimensions.width
                )}
              >
                {/* Brilliant */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://brilliant.org/classroom/join/2bx4jr/'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary'],
                        borderRadius: 8,
                        height: 46,
                        justifyContent: 'center',
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 32, width: 32 }
                        ),
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={Images.Image89}
                    />
                  </View>
                </Touchable>
                <Spacer top={8} right={8} bottom={8} left={8} />
                {/* Envisioning */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://techdetector.de/radar?'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.colors['Custom Color_4'],
                        borderRadius: 8,
                        height: 46,
                        justifyContent: 'center',
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 18, width: 32 },
                        dimensions.width
                      )}
                      resizeMode={'stretch'}
                      source={Images.Frame6191}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* Civics Guild */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://airtable.com/invite/l?inviteId=inveHqpcC2Ju2xK4L&inviteToken=f99686deb40d26b5fe3cb8ad9a65e600761061c35dd90c4c8d86765fe2345ffa&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: 'rgb(242, 183, 44)',
                        },
                        borderRadius: 8,
                        height: 46,
                        justifyContent: 'center',
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: 32 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                        },
                        dimensions.width
                      )}
                      resizeMode={'stretch'}
                      source={Images.AbstractShape1}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* Unity Guild */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://airtable.com/invite/l?inviteId=invxg2G3nF32VEtJv&inviteToken=92a5cbd73084deec82dd88ad7469fc2a2db894fd95f960baa743a46ecef4db55&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Strong'],
                        },
                        borderRadius: 8,
                        height: 46,
                        justifyContent: 'center',
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      style={StyleSheet.applyWidth(
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: 32 },
                            { minWidth: Breakpoints.Laptop, value: 28 },
                          ],
                        },
                        dimensions.width
                      )}
                      resizeMode={'stretch'}
                      source={Images.Unity1}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </Touchable>
                {/* More Guilds */}
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://www.cityasaschool.com/guilds'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* Guild Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderColor: theme.colors['Primary'],
                        borderRadius: 8,
                        borderWidth: 1,
                        height: 46,
                        justifyContent: 'center',
                        width: 46,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      name={'AntDesign/plus'}
                      color={theme.colors['Primary']}
                    />
                  </View>
                </Touchable>
              </View>
            )}
          </>
          {/* Body */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
              },
              dimensions.width
            )}
          >
            {/* Title & Filter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            >
              {/* Title & CTA */}
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '30%' } },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Inter Bold 20 Title'],
                    dimensions.width
                  )}
                >
                  {'Build Publish Inspire Hire'}
                </Text>
                {/* Show don't tell */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      marginTop: 8,
                    }),
                    dimensions.width
                  )}
                >
                  {
                    "City as a School is a show, don't tell professional network full of motivated and creative Gen-Z talent. See someone you can offer an project, job or internship opportunity?"
                  }
                </Text>

                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://usemotion.com/meet/serj-hunt/CAS-hire?d=30'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { marginTop: 8 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={20}
                      name={'MaterialIcons/connect-without-contact'}
                      color={theme.colors['Primary']}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { color: theme.colors['Primary'], paddingLeft: 4 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Book a Call'}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
            {/* Posts */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            >
              {/* POSTS */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* POSTS */}
                <PublicSchemaApi.FetchGetEvidencesForPublicWallGET
                  handlers={{
                    onData: pOSTSData => {
                      try {
                        setAllPosts(pOSTSData);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                >
                  {({
                    loading,
                    error,
                    data,
                    refetchGetEvidencesForPublicWall,
                  }) => {
                    const pOSTSData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <>
                          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                            <MasonryFlashList
                              renderItem={({ item }) => {
                                const masonryListData = item;
                                return (
                                  <>
                                    <Touchable
                                      style={StyleSheet.applyWidth(
                                        { marginBottom: 16, marginRight: 16 },
                                        dimensions.width
                                      )}
                                      activeOpacity={0.8}
                                      disabledOpacity={0.8}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'center',
                                            borderColor:
                                              theme.colors['gray line'],
                                            borderRadius: 8,
                                            borderWidth: 1,
                                            flex: 1,
                                            paddingBottom: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            masonryListData &&
                                            masonryListData[1]
                                          )?.imagesArray?.length ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderBottomLeftRadius: 0,
                                                  borderBottomRightRadius: 0,
                                                  borderRadius: 8,
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  flex: 1,
                                                  overflow: 'hidden',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* long Image */}
                                              <>
                                                {showShortImage(
                                                  masonryListData &&
                                                    masonryListData[0]
                                                ) ? null : (
                                                  <Image
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderBottomLeftRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                        borderTopLeftRadius: 8,
                                                        borderTopRightRadius: 8,
                                                        height: 400,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                    source={{
                                                      uri: `${
                                                        (
                                                          masonryListData &&
                                                          masonryListData[1]
                                                        )?.imagesArray &&
                                                        ((
                                                          masonryListData &&
                                                          masonryListData[1]
                                                        )?.imagesArray)[0]
                                                      }`,
                                                    }}
                                                    resizeMode={'cover'}
                                                  />
                                                )}
                                              </>
                                              {/* short Image */}
                                              <>
                                                {!showShortImage(
                                                  masonryListData &&
                                                    masonryListData[0]
                                                ) ? null : (
                                                  <Image
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderBottomLeftRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                        borderTopLeftRadius: 8,
                                                        borderTopRightRadius: 8,
                                                        height: 150,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                    source={{
                                                      uri: `${
                                                        (
                                                          masonryListData &&
                                                          masonryListData[1]
                                                        )?.imagesArray &&
                                                        ((
                                                          masonryListData &&
                                                          masonryListData[1]
                                                        )?.imagesArray)[0]
                                                      }`,
                                                    }}
                                                    resizeMode={'cover'}
                                                  />
                                                )}
                                              </>
                                              {/* Profile Picture */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    borderRadius: 8,
                                                    flexDirection: 'row',
                                                    left: 4,
                                                    padding: 8,
                                                    position: 'absolute',
                                                    top: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* author */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Circle
                                                    bgColor={theme.colors.light}
                                                    size={24}
                                                  >
                                                    <>
                                                      {!(
                                                        masonryListData &&
                                                        masonryListData[1]
                                                      )?.author
                                                        ?.profile_picture ? null : (
                                                        <Image
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: '100%',
                                                              width: '100%',
                                                            },
                                                            dimensions.width
                                                          )}
                                                          resizeMode={'contain'}
                                                          source={{
                                                            uri: `${
                                                              (
                                                                masonryListData &&
                                                                masonryListData[1]
                                                              )?.author
                                                                ?.profile_picture
                                                            }`,
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                    <>
                                                      {(
                                                        masonryListData &&
                                                        masonryListData[1]
                                                      )?.author
                                                        ?.profile_picture ? null : (
                                                        <Icon
                                                          size={24}
                                                          name={
                                                            'Ionicons/person'
                                                          }
                                                          color={
                                                            theme.colors[
                                                              'dark gray text'
                                                            ]
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  </Circle>
                                                </View>
                                              </View>
                                            </View>
                                          )}
                                        </>
                                        <Spacer
                                          right={8}
                                          left={8}
                                          bottom={4}
                                          top={4}
                                        />
                                        {/* Author */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Inter Regular'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Inactive Gray Text'
                                                  ],
                                                fontSize: 12,
                                                lineHeight: 15,
                                                marginBottom: 8,
                                                marginLeft: 8,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                        >
                                          {
                                            (
                                              masonryListData &&
                                              masonryListData[1]
                                            )?.author?.firstName
                                          }{' '}
                                          {
                                            (
                                              masonryListData &&
                                              masonryListData[1]
                                            )?.author?.lastName
                                          }
                                        </Text>
                                        {/* Details */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* title */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                {
                                                  color: theme.colors['Strong'],
                                                  fontSize: 14,
                                                  lineHeight: 15,
                                                  paddingBottom: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={1}
                                          >
                                            {
                                              (
                                                masonryListData &&
                                                masonryListData[1]
                                              )?.title
                                            }
                                          </Text>
                                          <Spacer
                                            right={8}
                                            left={8}
                                            top={0}
                                            bottom={4}
                                          />
                                          {/* description */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                marginBottom: 8,
                                                marginTop: 8,
                                                overflow: 'hidden',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* description */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'dark gray text'
                                                    ],
                                                },
                                                dimensions.width
                                              )}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={3}
                                            >
                                              {
                                                (
                                                  masonryListData &&
                                                  masonryListData[1]
                                                )?.description
                                              }
                                            </Text>
                                          </View>
                                          <Spacer
                                            right={8}
                                            left={8}
                                            top={0}
                                            bottom={14}
                                          />
                                          {/* Starting */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                marginBottom: 4,
                                                marginLeft: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Inactive Gray Text'
                                                    ],
                                                  fontSize: 10,
                                                },
                                                dimensions.width
                                              )}
                                              adjustsFontSizeToFit={true}
                                              numberOfLines={1}
                                              ellipsizeMode={'tail'}
                                            >
                                              {getRelativeTime(
                                                (
                                                  masonryListData &&
                                                  masonryListData[1]
                                                )?.created_at
                                              )}
                                            </Text>
                                          </View>
                                          {/* Skills Tag List */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <FlatList
                                              renderItem={({ item }) => {
                                                const listData = item;
                                                return (
                                                  <>
                                                    {/* skill tags */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'Work Sans Regular'
                                                            ],
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  'Inactive Gray Text'
                                                                ],
                                                              fontFamily:
                                                                'System',
                                                              fontSize: 10,
                                                              fontWeight: '400',
                                                              lineHeight: 13,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'#'}
                                                        {listData?.name}
                                                      </Text>
                                                      <Spacer
                                                        top={8}
                                                        bottom={8}
                                                        right={0}
                                                        left={4}
                                                      />
                                                    </View>
                                                  </>
                                                );
                                              }}
                                              data={
                                                (
                                                  masonryListData &&
                                                  masonryListData[1]
                                                )?.skillsList
                                              }
                                              listKey={JSON.stringify(
                                                (
                                                  masonryListData &&
                                                  masonryListData[1]
                                                )?.skillsList
                                              )}
                                              keyExtractor={listData =>
                                                listData?.id
                                              }
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.FlatListStyles(
                                                  theme
                                                )['List'],
                                                dimensions.width,
                                                ['width', 'height', 'flexGrow']
                                              )}
                                              contentContainerStyle={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.FlatListStyles(
                                                    theme
                                                  )['List'],
                                                  { alignItems: 'flex-start' }
                                                ),
                                                dimensions.width
                                              )}
                                              numColumns={1}
                                              onEndReachedThreshold={0.5}
                                              horizontal={true}
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                              showsHorizontalScrollIndicator={
                                                false
                                              }
                                            />
                                          </View>
                                        </View>
                                      </View>
                                    </Touchable>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                  </>
                                );
                              }}
                              data={getObjectEntries(allPosts)}
                              listKey={'KbwYb7aY'}
                              keyExtractor={masonryListData =>
                                masonryListData && masonryListData[0]
                              }
                              contentContainerStyle={StyleSheet.applyWidth(
                                { marginRight: 8 },
                                dimensions.width
                              )}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              numColumns={5}
                              estimatedItemSize={200}
                              showsVerticalScrollIndicator={true}
                            />
                          )}
                        </>
                        {/* VList */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <FlatList
                              renderItem={({ item }) => {
                                const vListData = item;
                                return (
                                  <>
                                    <Touchable
                                      style={StyleSheet.applyWidth(
                                        { marginBottom: 16, marginRight: 16 },
                                        dimensions.width
                                      )}
                                      activeOpacity={0.8}
                                      disabledOpacity={0.8}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'center',
                                            borderColor:
                                              theme.colors['gray line'],
                                            borderRadius: 8,
                                            borderWidth: 1,
                                            flex: 1,
                                            paddingBottom: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!vListData?.imagesArray
                                            ?.length ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderBottomLeftRadius: 0,
                                                  borderBottomRightRadius: 0,
                                                  borderRadius: 8,
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  flex: 1,
                                                  overflow: 'hidden',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* long Image */}
                                              <Image
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    height: 200,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                                source={{
                                                  uri: `${
                                                    vListData?.imagesArray &&
                                                    (vListData?.imagesArray)[0]
                                                  }`,
                                                }}
                                                resizeMode={'cover'}
                                              />
                                              {/* Profile Picture */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    borderRadius: 8,
                                                    flexDirection: 'row',
                                                    left: 4,
                                                    padding: 8,
                                                    position: 'absolute',
                                                    top: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* author */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Circle
                                                    bgColor={theme.colors.light}
                                                    size={24}
                                                  >
                                                    <>
                                                      {!(
                                                        vListData &&
                                                        vListData[1]
                                                      )?.author
                                                        ?.profile_picture ? null : (
                                                        <Image
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: '100%',
                                                              width: '100%',
                                                            },
                                                            dimensions.width
                                                          )}
                                                          resizeMode={'contain'}
                                                          source={{
                                                            uri: `${vListData?.author?.profile_picture}`,
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                    <>
                                                      {vListData?.author
                                                        ?.profile_picture ? null : (
                                                        <Icon
                                                          size={24}
                                                          name={
                                                            'Ionicons/person'
                                                          }
                                                          color={
                                                            theme.colors[
                                                              'dark gray text'
                                                            ]
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  </Circle>
                                                </View>
                                              </View>
                                            </View>
                                          )}
                                        </>
                                        <Spacer
                                          right={8}
                                          left={8}
                                          bottom={4}
                                          top={4}
                                        />
                                        {/* Author */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Inter Regular'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Inactive Gray Text'
                                                  ],
                                                fontSize: 12,
                                                lineHeight: 15,
                                                marginBottom: 8,
                                                marginLeft: 8,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                        >
                                          {vListData?.author?.firstName}{' '}
                                          {vListData?.author?.lastName}
                                        </Text>
                                        {/* Details */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* title */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Inter Regular'
                                                ],
                                                {
                                                  color: theme.colors['Strong'],
                                                  fontSize: 14,
                                                  lineHeight: 15,
                                                  paddingBottom: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={1}
                                          >
                                            {vListData?.title}
                                          </Text>
                                          <Spacer
                                            right={8}
                                            left={8}
                                            top={0}
                                            bottom={4}
                                          />
                                          {/* description */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                marginBottom: 8,
                                                marginTop: 8,
                                                overflow: 'hidden',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* description */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'dark gray text'
                                                    ],
                                                },
                                                dimensions.width
                                              )}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={3}
                                            >
                                              {vListData?.description}
                                            </Text>
                                          </View>
                                          <Spacer
                                            right={8}
                                            left={8}
                                            top={0}
                                            bottom={14}
                                          />
                                          {/* Starting */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                marginBottom: 4,
                                                marginLeft: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Inactive Gray Text'
                                                    ],
                                                  fontSize: 10,
                                                },
                                                dimensions.width
                                              )}
                                              adjustsFontSizeToFit={true}
                                              numberOfLines={1}
                                              ellipsizeMode={'tail'}
                                            >
                                              {getRelativeTime(
                                                vListData?.created_at
                                              )}
                                            </Text>
                                          </View>
                                          {/* Skills Tag List */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <FlatList
                                              renderItem={({ item }) => {
                                                const listData = item;
                                                return (
                                                  <>
                                                    {/* skill tags */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'Work Sans Regular'
                                                            ],
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  'Inactive Gray Text'
                                                                ],
                                                              fontFamily:
                                                                'System',
                                                              fontSize: 10,
                                                              fontWeight: '400',
                                                              lineHeight: 13,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'#'}
                                                        {listData?.name}
                                                      </Text>
                                                      <Spacer
                                                        top={8}
                                                        bottom={8}
                                                        right={0}
                                                        left={4}
                                                      />
                                                    </View>
                                                  </>
                                                );
                                              }}
                                              data={vListData?.skillList}
                                              listKey={JSON.stringify(
                                                vListData?.skillList
                                              )}
                                              keyExtractor={listData =>
                                                listData?.id
                                              }
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.FlatListStyles(
                                                  theme
                                                )['List'],
                                                dimensions.width,
                                                ['width', 'height', 'flexGrow']
                                              )}
                                              contentContainerStyle={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.FlatListStyles(
                                                    theme
                                                  )['List'],
                                                  { alignItems: 'flex-start' }
                                                ),
                                                dimensions.width
                                              )}
                                              numColumns={1}
                                              onEndReachedThreshold={0.5}
                                              horizontal={true}
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                              showsHorizontalScrollIndicator={
                                                false
                                              }
                                            />
                                          </View>
                                        </View>
                                      </View>
                                    </Touchable>
                                    <Spacer
                                      top={8}
                                      right={8}
                                      bottom={8}
                                      left={8}
                                    />
                                  </>
                                );
                              }}
                              data={allPosts}
                              listKey={'BiuAuJJZ'}
                              keyExtractor={vListData => vListData?.id}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          )}
                        </>
                      </>
                    );
                  }}
                </PublicSchemaApi.FetchGetEvidencesForPublicWallGET>
              </View>
            </View>
          </View>
        </View>
      </View>
      {/* bottomTab */}
      <>
        {dimensions.width >= Breakpoints.Tablet ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['bottomTab 2'],
              dimensions.width
            )}
          >
            {/* Discover */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('DiscoverScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'center'}
                    source={Images.IconDiscover1}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['CAS Blue'],
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Discover'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Locations */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Icon name={'Ionicons/location-outline'} size={26} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Locations'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Experiences */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('ExploreScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 26, width: 26 }
                      ),
                      dimensions.width
                    )}
                    source={Images.CASNavLogo}
                    resizeMode={'cover'}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong'],
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Experiences'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Checkin */}
            <View>
              <Touchable>
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'cover'}
                    source={Images.IconCheckin}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Check-in'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* Profile */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('ProfileScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 24, width: 24 }
                      ),
                      dimensions.width
                    )}
                    resizeMode={'cover'}
                    source={Images.IconProfile}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontSize: 10,
                          lineHeight: 10,
                          marginTop: 7,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Profile'}
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(DiscoverScreen);
