import moment from 'moment';
import { createClient } from '@supabase/supabase-js';
import * as Linking from 'expo-linking';
import { decode, encode } from 'base-64';
import * as momentTZ from 'moment-timezone';
const API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0aHZvdW9uaHNoa3ZiYXVncmhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTUyOTQ3OTUsImV4cCI6MTk3MDg3MDc5NX0.qlFLjO6sBHJWx_oaEiF1r4QLaNKRKrnEIwUQRvIQrpI';
const supabase = createClient(
  'https://qthvouonhshkvbaugrhc.supabase.co',
  API_KEY
);

export { moment, supabase, Linking, decode, encode, momentTZ };
