import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const getUpcomingSessions = seriesItem => {
  // console.log('from upcoming session', seriesItem)

  const isUpcomingDate = dateObj =>
    CustomPackages.moment(dateObj).diff(CustomPackages.moment(), 'seconds') > 0;

  return (
    seriesItem?.sessions?.filter(({ start_time }) =>
      isUpcomingDate(start_time)
    ) || []
  );
};

export default getUpcomingSessions;
