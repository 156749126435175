export default {
  Group85: require('../assets/images/Group85.png'),
  Group: require('../assets/images/Group.png'),
  AbstractShape1: require('../assets/images/AbstractShape(1).png'),
  Unity1: require('../assets/images/Unity(1).png'),
  PlusButton: require('../assets/images/PlusButton.png'),
  CASNavLogo: require('../assets/images/CASNavLogo.png'),
  Image89: require('../assets/images/Image89.png'),
  Envisioning: require('../assets/images/Envisioning.png'),
  Frame619: require('../assets/images/Frame619.png'),
  Frame6191: require('../assets/images/Frame619(1).png'),
  IconDiscover: require('../assets/images/IconDiscover.png'),
  IconActivities: require('../assets/images/IconActivities.png'),
  IconVenues: require('../assets/images/IconVenues.png'),
  IconCheckin1: require('../assets/images/IconCheckin(1).png'),
  IconCheckin: require('../assets/images/IconCheckin.png'),
  IconProfile: require('../assets/images/IconProfile.png'),
  IconActivities1: require('../assets/images/IconActivities(1).png'),
  IconProfile1: require('../assets/images/IconProfile(1).png'),
  IconDiscover1: require('../assets/images/IconDiscover(1).png'),
  CASLogoBlue: require('../assets/images/CASLogoBlue.png'),
};
