import React from 'react';

const enrolledForWholeSeries = (Variables, seriesItem) => {
  return (
    Variables.ENROLLED_SESSION_ID?.length &&
    seriesItem.sessions.every(({ id }) =>
      Variables.ENROLLED_SESSION_ID.includes(id)
    )
  );
};

export default enrolledForWholeSeries;
