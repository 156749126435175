import React from 'react';
import * as SupabaseEdgeFunctionApi from '../apis/SupabaseEdgeFunctionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const RequestPasswordScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setSent(false);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [email, setEmail] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Password Reset */}
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'center', width: 360 },
            dimensions.width
          )}
        >
          {/* Backbar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
              },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'MaterialIcons/chevron-left'}
              color={theme.colors['Not Black']}
            />
            <Spacer top={8} right={8} bottom={8} left={8} />
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 20,
                  fontWeight: '600',
                },
                dimensions.width
              )}
            >
              {'Reset Password'}
            </Text>
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          {/* Email Input */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              {/* First Name */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['dark gray text'],
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                    marginBottom: 8,
                  },
                  dimensions.width
                )}
              >
                {'Enter Your Registered Email Address '}
              </Text>
              <TextInput
                onChangeText={newTextInputValue => {
                  try {
                    setEmail(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Divider'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors.divider,
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: theme.colors['Strong'],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
                value={email}
                placeholder={'name@youremail.com'}
                autoCapitalize={'none'}
                placeholderTextColor={theme.colors['dark gray text']}
              />
            </View>
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          <Spacer right={8} left={8} top={16} bottom={16} />
          <View>
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    setSending(true);
                    await SupabaseEdgeFunctionApi.requestPasswordResetPOST(
                      Constants,
                      { email: email }
                    );
                    setSending(false);
                    setSent(true);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.primary,
                  borderRadius: 8,
                  fontFamily: 'System',
                  fontWeight: '700',
                  textAlign: 'center',
                },
                dimensions.width
              )}
              disabled={!email?.length}
              loading={sending}
              activeOpacity={0.8}
              disabledOpacity={0.8}
              title={'Get Email Link'}
            />
            {/* Error */}
            <>
              {!sent ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'System',
                      fontWeight: '400',
                      marginTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {'Reset link sent! Check your email'}
                </Text>
              )}
            </>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(RequestPasswordScreen);
