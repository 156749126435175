import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const getDayDiff = dateObj => {
  let diff = CustomPackages.moment(dateObj).diff(
    CustomPackages.moment(),
    'days'
  );

  return diff > 0 ? `in ${diff} days` : 'Today';
};

export default getDayDiff;
