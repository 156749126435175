import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseEdgeFunctionApi from '../apis/SupabaseEdgeFunctionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import openUrlUsingLinking from '../global-functions/openUrlUsingLinking';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, useWindowDimensions } from 'react-native';

const PurchaseProcessingScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const getProfileFullName = profileData => {
    const { firstName, lastName } = profileData || {};

    return `${firstName} ${lastName}`;
  };

  const { theme } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (!Constants['WAITING_CART']?.length) {
          return;
        }
        if (!Constants['AUTH_UUID_CAAS']) {
          return;
        }
        const intentObj = (
          await SupabaseEdgeFunctionApi.createAPaymentIntentLivePOST(
            Constants,
            {
              buyer_id: Constants['AUTH_UUID_CAAS'],
              session_ids: Constants['WAITING_CART'],
            }
          )
        )?.json;
        console.log(intentObj);
        openUrlUsingLinking(intentObj?.url);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      <>
        {!true ? null : (
          <ActivityIndicator
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
            animating={true}
            hidesWhenStopped={true}
            size={'large'}
            color={theme.colors['CAS Blue']}
          />
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(PurchaseProcessingScreen);
