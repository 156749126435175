import React from 'react';
import * as CustomPackages from '../custom-files/CustomPackages';

const getSeriesDateRange = seriesItem => {
  const formatDateStr = (dateObj, formatString) =>
    CustomPackages.moment(dateObj).format(formatString);
  const { sessions } = seriesItem;
  // get the first date and last date
  let startStartTime = sessions[0].start_time;

  let endStartTime = sessions[sessions.length - 1].start_time;
  // format them
  return `${formatDateStr(startStartTime, 'MMM Do')} - ${formatDateStr(
    endStartTime,
    'MMM Do'
  )}`;
};

export default getSeriesDateRange;
