import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const currentAuthUserGET = (Constants, _args, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
  }).then(res => handleResponse(res, handlers));

export const useCurrentAuthUserGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
  });
};

export const FetchCurrentAuthUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchCurrentAuthUser: refetch });
};

export const emailLoginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useEmailLoginPOST = ({ email, password }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );
};

export const FetchEmailLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(
    `https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/token?grant_type=password`,
    {
      body: JSON.stringify({ email: email, password: password }),
      depends: [isFocused],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apikey: Constants['ANON_KEY_CAAS'],
      },
      method: 'POST',
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchEmailLogin: refetch });
};

export const emailSignupPOST = (
  Constants,
  {
    parent_email,
    parent_id,
    signupEmail,
    signupFirstName,
    signupLastName,
    signupPassword,
    username,
  },
  handlers = {}
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      data: {
        firstName: signupFirstName,
        lastName: signupLastName,
        username: username,
        parent_id: parent_id,
        parent_email: parent_email,
        type: 'learner',
      },
      email: signupEmail,
      password: signupPassword,
      email_confirm: true,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useEmailSignupPOST = ({
  parent_email,
  parent_id,
  signupEmail,
  signupFirstName,
  signupLastName,
  signupPassword,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      data: {
        firstName: signupFirstName,
        lastName: signupLastName,
        username: username,
        parent_id: parent_id,
        parent_email: parent_email,
        type: 'learner',
      },
      email: signupEmail,
      password: signupPassword,
      email_confirm: true,
    }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  });
};

export const FetchEmailSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  parent_email,
  parent_id,
  signupEmail,
  signupFirstName,
  signupLastName,
  signupPassword,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/signup`, {
    body: JSON.stringify({
      data: {
        firstName: signupFirstName,
        lastName: signupLastName,
        username: username,
        parent_id: parent_id,
        parent_email: parent_email,
        type: 'learner',
      },
      email: signupEmail,
      password: signupPassword,
      email_confirm: true,
    }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchEmailSignup: refetch });
};

export const logoutPOST = (Constants, _args, handlers = {}) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLogoutPOST = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  });
};

export const FetchLogoutPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/logout`, {
    body: JSON.stringify({}),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    const f = handlers.onData ?? onData;
    if (data && f) {
      f(data);
    }
  }, [data, onData, handlers]);

  return children({ loading, data, error, refetchLogout: refetch });
};

export const updateAuthUserPasswordPUT = (
  Constants,
  { newPassword },
  handlers = {}
) =>
  fetch(`https://qthvouonhshkvbaugrhc.supabase.co/auth/v1/user`, {
    body: JSON.stringify({ password: newPassword }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER_CAAS'],
      'Content-Type': 'application/json',
      apikey: Constants['ANON_KEY_CAAS'],
    },
    method: 'PUT',
  }).then(res => handleResponse(res, handlers));
