import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseAuthApi from '../apis/SupabaseAuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import openUrlUsingLinking from '../global-functions/openUrlUsingLinking';
import responseErrorExtractor from '../global-functions/responseErrorExtractor';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const PasswordResetScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const validatePassword = () => {
    const passwordExpr = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!$#%_]{8,}$/;

    if (password !== confirmPassword) {
      setValidationErr('Password should match!');
      return true;
    }

    if (!passwordExpr.test(password)) {
      setValidationErr(
        'Password should be at least 8 characters in length, contain at least 1 letter, 1 number and 1 of these @!$#%_ special charecters.'
      );
      return true;
    }
    setValidationErr('');
    return false;
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setPassword('');
      setConfirmPassword('');
      if (!(props.route?.params?.accessToken ?? '')) {
        return;
      }
      setGlobalVariableValue({
        key: 'AUTH_HEADER_CAAS',
        value: 'Bearer ' + props.route?.params?.accessToken ?? '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetFailed, setResetFailed] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [validationErr, setValidationErr] = React.useState('');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      {/* Body */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Password Reset */}
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'center', width: 360 },
            dimensions.width
          )}
        >
          {/* Backbar */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
              },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'MaterialIcons/chevron-left'}
              color={theme.colors['Strong']}
            />
            <Spacer top={8} right={8} bottom={8} left={8} />
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 20,
                  fontWeight: '600',
                },
                dimensions.width
              )}
            >
              {'Reset Password'}
            </Text>
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          <>
            {!validationErr ? null : (
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontSize: 12,
                    marginBottom: 8,
                  }),
                  dimensions.width
                )}
              >
                {validationErr}
              </Text>
            )}
          </>
          {/* Handle */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              {/* First Name */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['dark gray text'],
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                    marginBottom: 8,
                  },
                  dimensions.width
                )}
              >
                {'New Password'}
              </Text>
              <>
                {checkboxValue ? null : (
                  <TextInput
                    onChangeText={newTextInputValue => {
                      try {
                        setPassword(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: theme.colors['Strong'],
                        fontFamily: 'System',
                        fontWeight: '400',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={password}
                    placeholder={' '}
                    autoCapitalize={'none'}
                    secureTextEntry={true}
                    placeholderTextColor={theme.colors['dark gray text']}
                  />
                )}
              </>
              <>
                {!checkboxValue ? null : (
                  <TextInput
                    onChangeText={newTextInputValue => {
                      try {
                        setPassword(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: theme.colors['Strong'],
                        fontFamily: 'System',
                        fontWeight: '400',
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={password}
                    autoCapitalize={'none'}
                    placeholder={'Enter a value...'}
                    secureTextEntry={false}
                  />
                )}
              </>
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors['dark gray text'] },
                    dimensions.width
                  )}
                >
                  {'Show password'}
                </Text>
                <Checkbox
                  onPress={newCheckboxValue => {
                    try {
                      setCheckboxValue(newCheckboxValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  status={checkboxValue}
                />
              </View>
            </View>
          </View>
          <Spacer top={8} right={8} bottom={8} left={8} />
          {/* Handle */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              {/* First Name */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['dark gray text'],
                    fontFamily: 'System',
                    fontSize: 16,
                    fontWeight: '400',
                    marginBottom: 8,
                  },
                  dimensions.width
                )}
              >
                {'Confirm New Password'}
              </Text>
              <TextInput
                onChangeText={newTextInputValue => {
                  try {
                    setConfirmPassword(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Divider'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors.divider,
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: theme.colors['Strong'],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
                value={confirmPassword}
                placeholder={' '}
                autoCapitalize={'none'}
                secureTextEntry={true}
              />
            </View>
          </View>
          <Spacer right={8} left={8} top={16} bottom={16} />
          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  if (validatePassword()) {
                    return;
                  }
                  setSending(true);
                  const updateRes = (
                    await SupabaseAuthApi.updateAuthUserPasswordPUT(Constants, {
                      newPassword: confirmPassword,
                    })
                  )?.json;
                  const err = responseErrorExtractor(updateRes);
                  setSending(false);
                  setResetFailed(true);
                  if (err) {
                    return;
                  }
                  setResetFailed(false);
                  openUrlUsingLinking(
                    'https://learn.cityasaschool.com/login/undefined'
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.primary,
                borderRadius: 8,
                fontFamily: 'System',
                fontWeight: '700',
                textAlign: 'center',
              },
              dimensions.width
            )}
            loading={sending}
            activeOpacity={0.8}
            disabledOpacity={0.8}
            title={'Save & Return to Settings'}
          />
          <>
            {!resetFailed ? null : (
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontSize: 12,
                  }),
                  dimensions.width
                )}
              >
                {'Reset password failed'}
              </Text>
            )}
          </>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PasswordResetScreen);
